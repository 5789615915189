<ng-container *ngIf="!(accountLoadingState$ | async) && !(selectedWalletLoadingState$ | async); else isSpinnerVisible">
	<ng-container *ngIf="account$ | async as account">
		<ng-container *ngIf="selectedWallet$ | async as selectedWallet">
			<div class="payouts">
				<div class="payouts__heading">
					<div class="text">
						<h3>My Payouts</h3>
					</div>

					<div class="actions">
            <!-- TODO: This ngIf logic should be changed before next payouts -->
            <ng-container *ngIf="selectedWallet.walletSummary.pendingVested > 0">
              <button
                type="button"
                mat-button
                (click)="openPayoutForm()"
                class="actions__request-payout button-main"
                [ngClass]="{
                  'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
                  'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async),
                  'button-padding-extra-tiny': (isBreakpointXS$ | async),
                  'button-padding-tiny': (isBreakpointSM$ | async)
                }"
              >
                <div>Request Token Payout</div>
              </button>
            </ng-container>
					</div>
				</div>

				<div class="payouts__transactions">
					<ng-container *ngIf="selectedWallet?.userPayouts?.length; else noPayouts">
						<table 
              mat-table 
              [dataSource]="dataSource.data"
              matSort
              (matSortChange)="sortData($event)"
							matSortActive="date"
							matSortDirection="desc"
							matSortDisableClear
              class="transactions-table"
            >
							<ng-container matColumnDef="date">
								<th 
                  mat-header-cell 
                  *matHeaderCellDef
                  mat-sort-header
                  [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)"
                >
									Date
								</th>

								<ng-container *matCellDef="let oneUserPayout">
									<td mat-cell [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
										{{ mockStringCheck(oneUserPayout.requestedOn | date: 'longDate') }}
									</td>
								</ng-container>

                <td mat-footer-cell *matFooterCellDef [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
									Total
								</td>
							</ng-container>

							<ng-container matColumnDef="amount">
								<th 
                  mat-header-cell 
                  *matHeaderCellDef
                  mat-sort-header
                >
									Amount
								</th>

								<ng-container *matCellDef="let oneUserPayout">
									<td mat-cell>
										{{ mockStringCheck(oneUserPayout.amount) | tagion }}
									</td>
								</ng-container>

                <td mat-footer-cell *matFooterCellDef>
									{{ getTotalPayoutAmount(selectedWallet?.userPayouts) | tagion }}
								</td>
							</ng-container>

							<ng-container matColumnDef="status">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>

								<ng-container *matCellDef="let oneUserPayout">
									<td mat-cell>
										<div class="status-wrapper">
											<div
												class="status"
												[ngClass]="getCssClassForPayoutStatus(getPayoutStatus(oneUserPayout.status))"
											>
												{{ getPayoutStatus(oneUserPayout.status) }}
											</div>
										</div>
									</td>
								</ng-container>

                <td mat-footer-cell *matFooterCellDef></td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
							<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
              <tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
						</table>
					</ng-container>

					<ng-template #noPayouts>
            <div class="payouts__noTransactions">
              <div class="card-icon">
                <img src="../../../../assets/icons/regular/payout.svg" alt="payout" class="regular-icon-image" />
              </div>

              <div class="text">
                <div>You have no payouts yet</div>
              </div>
            </div>
					</ng-template>
				</div>
			</div>
		</ng-container>
	</ng-container>
</ng-container>

<ng-template #isSpinnerVisible>
	<div class="isSpinnerVisibleAtMenuItem">
		<mat-progress-spinner [diameter]="36" [mode]="'indeterminate'"></mat-progress-spinner>
	</div>
</ng-template>
