import { Store } from '@ngxs/store';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable, of, Subscription } from 'rxjs';
import { map, takeUntil, tap, filter, switchMap, take, catchError, delay } from 'rxjs/operators';
import { NetworkService } from './network.service';
import { LinkPubkeyToWalletResponse, PayoutResponse, WalletDataResponse } from '../../models/wallet.models';
import { filterOutNullishValues } from '../auth/auth.utils';
import { DataService } from './data.service';
import { SocketIoService } from './socket-io/socket-io.service';
import { LinkWalletResponseFromPayoutService, LinkWalletPayloadToPayoutService } from '../../models/socket.model';
import { SocketEvent } from '../../enums/enums';
import { Wallet } from '../store/actions/wallet.actions';

@Injectable({
	providedIn: 'root',
})
export class WalletService implements OnDestroy {
	private _wallet = (walletId: string) => `/wallet/${walletId}`;
	private _payoutList = (walletId: string) => `/wallet/${walletId}/payouts`;
  private _publicKey = (walletId: string) => `/wallet/${walletId}/publickey`;

	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(private _networkService: NetworkService, private _dataService: DataService, private _socketIoService: SocketIoService, private _store: Store) {}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public getWalletInfo(walletId: string): Observable<WalletDataResponse> {
		return this._networkService.get(this._wallet(walletId)).pipe(
      filterOutNullishValues(), 
      tap((walletDataResponse: WalletDataResponse) => {
        if (walletDataResponse?.isSuceceded && walletDataResponse?.data?.walletSummary?.linkedPubKey) {
          this._dataService.setIsLinkingProcessSucceeded(true);
        } else {
          // TODO: show error here
        }
      }),
      take(1),
      takeUntil(this._onDestroy$)
    );
	}

  public listenToSocketDataForLinkWalletEventFromPayoutService(crmWalletId: string | undefined): Subscription | undefined {
    return of(crmWalletId)?.pipe(
      switchMap(() => this._dataService.getCrmWalletIdAndPubkeyBase64FromPayoutService$()),
      // TODO: need this filter to skip calls before this._dataService.getCrmWalletIdAndPubkeyBase64FromPayoutService$() gets real value
      filter((emittedSocketDataForLinkWalletEventFromPayoutService: LinkWalletResponseFromPayoutService | null) => {
        return !!emittedSocketDataForLinkWalletEventFromPayoutService && 
        !!emittedSocketDataForLinkWalletEventFromPayoutService?.crmWalletId && 
        !!emittedSocketDataForLinkWalletEventFromPayoutService?.pubkeyBase64 &&
        !!emittedSocketDataForLinkWalletEventFromPayoutService?.invoiceBase64
      }),
      map((emittedSocketDataForLinkWalletEventFromPayoutService: LinkWalletResponseFromPayoutService | null) => {
        // console.log("--- emittedSocketDataForLinkWalletEventFromPayoutService: ", emittedSocketDataForLinkWalletEventFromPayoutService);

        if (
          emittedSocketDataForLinkWalletEventFromPayoutService && 
          emittedSocketDataForLinkWalletEventFromPayoutService?.crmWalletId && 
          emittedSocketDataForLinkWalletEventFromPayoutService?.pubkeyBase64 &&
          emittedSocketDataForLinkWalletEventFromPayoutService?.invoiceBase64
        ) {
          if (crmWalletId === emittedSocketDataForLinkWalletEventFromPayoutService?.crmWalletId) {
            // console.log("--- Wallet IDs matched");

            this._dataService.setPayoutRequestFormSpinnerStatus(true);

            this._sendPublicKeyToBackend(emittedSocketDataForLinkWalletEventFromPayoutService).pipe(
              switchMap((response: LinkPubkeyToWalletResponse) => this._emitResponseForLinkWalletEventToPayoutService(response, emittedSocketDataForLinkWalletEventFromPayoutService?.pubkeyBase64)),
              tap((responseToPayoutService: LinkWalletPayloadToPayoutService) => {
                if (responseToPayoutService) {
                  if (responseToPayoutService.isLinkWalletDataAccepted === true && responseToPayoutService.error === '') {
                    // TODO:!! here double check that public key has been stored to backend
                  } else {
                    if (responseToPayoutService.error) {
                      console.error("--- responseToPayoutService.error: ", responseToPayoutService.error);

                      // TODO: throw error to user
                    } else {
                      console.error("Error happened at CRM side when tried to link wallet to public key");

                      // TODO: throw error to user
                    }
                  }
                } else {
                  console.error("--- responseToPayoutService is nullish");

                  // TODO: throw error to user
                }
              }),
              switchMap(() => this._store.dispatch(new Wallet.GetOne(crmWalletId))),
              tap(() => this._dataService.setPayoutRequestFormSpinnerStatus(false)),
              take(1),
              takeUntil(this._onDestroy$),
              catchError((err: any) => {
                this._dataService.setPayoutRequestFormSpinnerStatus(false);

                return of(err);
              }),
            )
            .subscribe(); 
          } else {
            console.log("--- crmWalletId: ", crmWalletId);
            console.log("--- linkWalletPayloadData.crmWalletId: ", emittedSocketDataForLinkWalletEventFromPayoutService?.crmWalletId);

            console.error("--- Wallet IDs do not match!");
          }
        } else {
          console.error("--- Data from payout service is nullish");
        }
      }),
      take(1),
      takeUntil(this._onDestroy$)
    )
    .subscribe(); 
  }

  private _emitResponseForLinkWalletEventToPayoutService(response: LinkPubkeyToWalletResponse, newLinkedPubKey: string): Observable<LinkWalletPayloadToPayoutService> {
    return of(response).pipe(
      map((response: LinkPubkeyToWalletResponse) => {
        let responseToPayoutService = {
          isLinkWalletDataAccepted: false,
          error: '',
          newLinkedPubKey: ''
        };

        // TODO: response.status codes are used here. Use them in every response to JustPay for error handling on mobile side
        if (response.isSuceceded) {
          if (response.status === 1 && response.message === 'Public key was attached to wallet') {
            // console.log('--- Public key has been successfully saved in CRM');

            responseToPayoutService = {
              isLinkWalletDataAccepted: true,
              error: '',
              newLinkedPubKey: newLinkedPubKey
            };
            // TODO: check in which case backend responds with 'WalletAlreadyHavePublicKey' and also handle another responses
          } else if (response.status === 5 && response.message === 'WalletAlreadyHavePublicKey') {
            console.error('Public key was already attached to wallet');

            responseToPayoutService = {
              isLinkWalletDataAccepted: false,
              error: 'Public key was already attached to wallet',
              newLinkedPubKey: ''
            };
          } else {
            console.error('Got error from Backend when tried attach public key to wallet');

            responseToPayoutService = {
              isLinkWalletDataAccepted: false,
              error: 'Got error from Backend when tried attach public key to wallet',
              newLinkedPubKey: ''
            };
          }
        } else {
          console.error('CRM could not save public key under wallet entity');

          responseToPayoutService = {
            isLinkWalletDataAccepted: false,
            error: 'CRM could not save public key under wallet entity',
            newLinkedPubKey: ''
          };
        }

        // console.log('--- responseToPayoutService; ', responseToPayoutService);
        return responseToPayoutService;
      }),
      // TODO:!! WS Exceptions are not delivered to Client console
      tap((responseToPayoutService: LinkWalletPayloadToPayoutService) => {
        this._socketIoService.emitEventToPayoutService(SocketEvent._2_linkWallet, responseToPayoutService);
      }),
			// filterOutNullishValues(),
    );
  }

  public getPayoutList(walletId: string): Observable<PayoutResponse> {
    return this._networkService.get(this._payoutList(walletId)).pipe(
      // filterOutNullishValues(), 
      // takeUntil(this._onDestroy$)
    );
  }

  public requestPayout(crmWalletId: string, amount: number): Observable<PayoutResponse> {
    return this._networkService.post(this._payoutList(crmWalletId), { Amount: amount });
  }

  private _sendPublicKeyToBackend(payload: LinkWalletResponseFromPayoutService): Observable<LinkPubkeyToWalletResponse> {
    return this._networkService.post(this._publicKey(payload.crmWalletId), { linkedPubKey: payload.pubkeyBase64, invoice: payload.invoiceBase64 });
  }
}
