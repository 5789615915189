import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
	title = 'tagion-investor-saft-service';

	constructor() {
		this.fixMobileViewportBug();
	}

	public fixMobileViewportBug(): void {
		this._updateVH();
		window.addEventListener('resize', () => this._updateVH);
	}

	private _updateVH(): void {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
}
