<div class="buy-tagions-form-dialog">
	<form [formGroup]="buyTagionsForm" class="dialog-form">
		<h3 class="dialog-form__heading">Request SAFT Purchase</h3>

		<div class="dialog-form__sub-heading">
			<div>Specify amount in Euros that you would like to invest in Tagions.</div>
			<div>The minimum amount is &euro;1,000.</div>
		</div>

		<mat-dialog-content class="dialog-form__content">
			<div class="first-row">
				<mat-form-field class="first-row__amount">
					<mat-label>Amount in Euro</mat-label>

					<input type="text" matInput [formControlName]="'euroAmount'" />

					<mat-error *ngIf="buyTagionsForm?.get('euroAmount')?.hasError('required')">
						Please enter the amount
					</mat-error>
					<mat-error *ngIf="buyTagionsForm?.get('euroAmount')?.hasError('isNumberIncorrect')">
						Provide a valid amount
					</mat-error>
					<mat-error *ngIf="buyTagionsForm?.get('euroAmount')?.hasError('min')">
						Minimum amount is &euro;1,000
					</mat-error>
				</mat-form-field>
			</div>
		</mat-dialog-content>

		<mat-dialog-actions class="dialog-form__actions">
			<button
				mat-button
				type="submit"
				(click)="sendPurchaseRequest()"
				[disabled]="(isSpinnerVisible$ | async) || buyTagionsForm.invalid"
				class="button-full-width button-main"
				[ngClass]="{
					'button-low': (isBreakpointHeightSmall$ | async),
					'button-high': !(isBreakpointHeightSmall$ | async)
				}"
			>
				<div *ngIf="!(isSpinnerVisible$ | async); else isSpinnerVisible">Send</div>

				<ng-template #isSpinnerVisible>
					<mat-progress-spinner
						*ngIf="isSpinnerVisible$ | async"
						[diameter]="24"
						[mode]="'indeterminate'"
						class="actions__submit-spinner"
					>
					</mat-progress-spinner>

					<div>Sending...</div>
				</ng-template>
			</button>
		</mat-dialog-actions>
	</form>
</div>
