<button
	type="button"
	mat-button
	matSuffix
	disabled
	class="button-extra-narrow button-medium-high button-padding-extra-tiny email-check-wrapper"
>
	<ng-container *ngIf="!(isSpinnerVisible$ | async); else isSpinnerVisible">
		<ng-container *ngIf="form?.controls?.[this.controlName]?.valid; else isControlInvalid">
			<!-- <ng-container *ngIf="isDefault || form?.controls?.[this.controlName].dirty">
				<mat-icon>verified_user</mat-icon>
			</ng-container> -->

			<ng-container *ngIf="isSuccess && form?.controls?.[this.controlName]?.pristine">
				<mat-icon [matTooltip]="tooltipNameForSuccess" class="success">verified_user</mat-icon>
			</ng-container>

			<ng-container *ngIf="isFailure && form?.controls?.[this.controlName]?.pristine">
				<mat-icon [matTooltip]="tooltipNameForFailure" class="failure">verified_user</mat-icon>
			</ng-container>
		</ng-container>

		<ng-template #isControlInvalid>
			<mat-icon class="invalid">verified_user</mat-icon>
		</ng-template>
	</ng-container>

	<ng-template #isSpinnerVisible>
		<div class="isSpinnerVisibleAtEmailCheck">
			<mat-progress-spinner [diameter]="18" [mode]="'indeterminate'"></mat-progress-spinner>
		</div>
	</ng-template>
</button>
