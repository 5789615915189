import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	DoCheck,
	ChangeDetectorRef,
	ViewChild,
	ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { AccountState } from '../../../core/store/states/account.states';
import { AccountInfoData } from '../../../models/account.models';
import {
	SupportInquiryTypeModel,
	SupportFormAttachmentModel,
	SupportFormBodyModel,
	SupportFormResponseModel,
} from '../../../models/other.models';
import { takeUntil, tap, catchError, switchMap, filter } from 'rxjs/operators';
import { SupportService } from '../../../core/services/support.service';
import { DataService } from '../../../core/services/data.service';
import { DialogService } from '../../../core/services/dialog.service';
import { ContactRequestSubmittedComponent } from '../contact-request-submitted/contact-request-submitted.component';
import { ContactSupportBodyArea } from '../../../enums/enums';

@Component({
	selector: 'contact-support-form',
	templateUrl: './contact-support-form.component.html',
	styleUrls: ['./contact-support-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactSupportFormComponent implements OnInit, DoCheck {
	public account$: Observable<AccountInfoData> | null = null;

	@ViewChild('fileInput') fileInput: ElementRef | null = null;

	public contactSupportForm!: FormGroup;
	public contactSupportBody: SupportFormBodyModel = {
		email: null,
		subject: null,
		body: null,
		area: null,
		type: null,
		attachments: null,
	};
	public attachedFilesByUser: File[] | null = null;
	public attachments: SupportFormAttachmentModel[] = [];

	public isSpinnerVisible$: Observable<boolean> | null = null;;

  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;
  public isBreakpointHeightSmall$: Observable<boolean> | null = null;

	private _spinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		public dataService: DataService,
		private _formBuilder: FormBuilder,
		private _dialogRef: MatDialogRef<ContactSupportFormComponent>,
		private _snackBarService: SnackBarService,
		private _store: Store,
		private _breakpointService: BreakpointService,
		private _supportService: SupportService,
		private _changeDetectorRef: ChangeDetectorRef,
		private _dialogService: DialogService,
	) {}

	ngOnInit(): void {
    this._initializeDataFromStore();

		this.isSpinnerVisible$ = this.getSpinnerStatus$();

		this._initContactSupportForm();

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();
	}

	ngDoCheck() {
		this._changeDetectorRef.markForCheck();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public setSpinnerStatus(spinnerStatus: boolean): void {
		this._spinner$.next(spinnerStatus);
	}
	public getSpinnerStatus$(): Observable<boolean> {
		return this._spinner$.asObservable();
	}

	public addBase64EncodedFileToAttachments(encodedBase64: string, fileName: string): void {
		const trimmedBase64 = encodedBase64.split(',').splice(1).join(',');

		if (this.attachments.length > 0) {
			this.attachments = [
				...this.attachments,
				{
					filename: fileName,
					base64Content: trimmedBase64,
				},
			];
		} else {
			this.attachments = [
				{
					filename: fileName,
					base64Content: trimmedBase64,
				},
			];
		}
	}

	public removeAttachment(attachmentIndex: number): void {
		this.attachments = this.attachments.filter((item, index) => index !== attachmentIndex);
	}

	public encodeFileToBase64(uploadedFile: File): void {
		const reader = new FileReader();
		reader.readAsDataURL(uploadedFile);
		reader.onload = () => this.addBase64EncodedFileToAttachments(reader.result as string, uploadedFile.name);
		reader.onerror = (error) => console.log('Error: ', error);
	}

	public onFileSelected(event: any): void {
		const file: File = event?.target?.files?.[0];

		if (file) {
			if (this.attachedFilesByUser) {
				this.attachedFilesByUser = [...this.attachedFilesByUser, file];
				this.encodeFileToBase64(file);
			} else {
				this.attachedFilesByUser = [file];
				this.encodeFileToBase64(file);
			}
		}

		this._changeDetectorRef.markForCheck();

		// clear last cached input value, so user can upload same file twice
		(this.fileInput as ElementRef).nativeElement.value = '';
	}

	public contactSupport(): void {
		this.setSpinnerStatus(true);

		(this.account$ as Observable<AccountInfoData>)
			.pipe(
				tap((accountInfo: AccountInfoData) => {
					this.contactSupportBody.email = accountInfo.email;
					this.contactSupportBody.subject = `Tagion Portal - Support inquiry by ${accountInfo.crmId}`;
				}),
				tap(() => this._getValuesFromForm()),
				// tap(() => console.log('contactSupportBody: ', this.contactSupportBody)),
				switchMap(() => this._supportService.contactSupport(this.contactSupportBody)),
				tap((ticketNumber: SupportFormResponseModel) => this.dataService.setSupportTicketNumber(ticketNumber)),
				filter((response: string | any) => typeof response === 'string'),
				tap(() => this.setSpinnerStatus(false)),
				tap(() => this._dialogRef.close()),
				tap(() => this._dialogService.open(ContactRequestSubmittedComponent)),
				catchError((err: any) => {
					this.setSpinnerStatus(false);

					return of(err);
				}),
			)
			.subscribe();
	}

	private _getValuesFromForm(): void {
		this.contactSupportBody.body = (this.contactSupportForm as FormGroup).value.inquiry;
		this.contactSupportBody.area = ContactSupportBodyArea.tagionPortal;
		const selectedObject =
			this.dataService.supportInquiryTypeList.find(
				(supportInquiryType: SupportInquiryTypeModel) => supportInquiryType.name === (this.contactSupportForm as FormGroup).value.type,
			) || null;
		this.contactSupportBody.type = selectedObject?.code || null;
		this.contactSupportBody.attachments = this.attachments;
	}

	private _initContactSupportForm(): void {
		this.contactSupportForm = this._formBuilder.group({
			type: ['', Validators.required],
			inquiry: ['', [Validators.required]],
			file: [''],
		});
	}

  private _initializeDataFromStore(): void {
    this.account$ = this._store.select(AccountState.getAccount);
  }

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
    this.isBreakpointHeightSmall$ = this._breakpointService.getHeightSmallBreakpointStatus$();
  }
}
