import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, NgZone } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BreakpointService {
	public myBreakpoints = [
		{
			name: 'width-xs',
			value: '(min-width: 240px) and (max-width: 374.5px)',
		},
		{
			name: 'width-sm',
			value: '(min-width: 375px) and (max-width: 767.5px)',
		},
		{
			name: 'width-md',
			value: '(min-width: 768px) and (max-width: 1023.5px)',
		},
		{
			name: 'width-lg',
			value: '(min-width: 1024px) and (max-width: 1439.5px)',
		},
		{
			name: 'width-xl',
			value: '(min-width: 1440px)',
		},
		{
			name: 'height-small',
			value: '(max-height: 500px)',
		},
	];

	private _isBreakpointXS$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _isBreakpointSM$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _isBreakpointMD$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _isBreakpointLG$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _isBreakpointXL$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	private _isBreakpointHeightSmall$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private _breakpointObserver: BreakpointObserver, private _ngZone: NgZone) {}

	public getBreakpointValues(): string[] {
		const arr: string[] = [];

		this.myBreakpoints.map((breakpoint: { name: string; value: string }) => arr.push(breakpoint.value));

		return arr;
	}

	public getBreakpointName(breakpointValue: string): string {
		let name = '';

		this.myBreakpoints.map((breakpoint: { name: string; value: string }) => {
			if (breakpoint.value === breakpointValue) {
				name = breakpoint.name;
			}
		});

		return name;
	}

	public activeBreakpoints: string[] = [];

	public setXSBreakpointStatus(breakpointStatus: boolean): void {
		this._isBreakpointXS$.next(breakpointStatus);
	}
	public getXSBreakpointStatus$(): Observable<boolean> {
		return this._isBreakpointXS$.asObservable();
	}

	public setSMBreakpointStatus(breakpointStatus: boolean): void {
		this._isBreakpointSM$.next(breakpointStatus);
	}
	public getSMBreakpointStatus$(): Observable<boolean> {
		return this._isBreakpointSM$.asObservable();
	}

	public setMDBreakpointStatus(breakpointStatus: boolean): void {
		this._isBreakpointMD$.next(breakpointStatus);
	}
	public getMDBreakpointStatus$(): Observable<boolean> {
		return this._isBreakpointMD$.asObservable();
	}

	public setLGBreakpointStatus(breakpointStatus: boolean): void {
		this._isBreakpointLG$.next(breakpointStatus);
	}
	public getLGBreakpointStatus$(): Observable<boolean> {
		return this._isBreakpointLG$.asObservable();
	}

	public setXLBreakpointStatus(breakpointStatus: boolean): void {
		this._isBreakpointXL$.next(breakpointStatus);
	}
	public getXLBreakpointStatus$(): Observable<boolean> {
		return this._isBreakpointXL$.asObservable();
	}

	public setHeightSmallBreakpointStatus(breakpointStatus: boolean): void {
		this._isBreakpointHeightSmall$.next(breakpointStatus);
	}
	public getHeightSmallBreakpointStatus$(): Observable<boolean> {
		return this._isBreakpointHeightSmall$.asObservable();
	}

	public listenToBreakpointObserver(): Observable<BreakpointState> {
		return this._breakpointObserver.observe(this.getBreakpointValues()).pipe(
			// alternative way - this._applicationRef.tick();
			tap((breakpointState: BreakpointState) =>
				this._ngZone.run(() => this.setBreakpointStatuses(breakpointState.breakpoints)),
			),
		);
	}

	public setBreakpointStatuses(breakpoints: Record<string, boolean>): void {
		this.activeBreakpoints = [];

		Object.keys(breakpoints).map((breakpoint: string) => {
			if (this.getBreakpointName(breakpoint) && breakpoints[breakpoint]) {
				this.activeBreakpoints.push(this.getBreakpointName(breakpoint) as string);
			}
		});

		this.activeBreakpoints.includes('width-xs') ? this.setXSBreakpointStatus(true) : this.setXSBreakpointStatus(false);

		this.activeBreakpoints.includes('width-sm') ? this.setSMBreakpointStatus(true) : this.setSMBreakpointStatus(false);

		this.activeBreakpoints.includes('width-md') ? this.setMDBreakpointStatus(true) : this.setMDBreakpointStatus(false);

		this.activeBreakpoints.includes('width-lg') ? this.setLGBreakpointStatus(true) : this.setLGBreakpointStatus(false);

		this.activeBreakpoints.includes('width-xl') ? this.setXLBreakpointStatus(true) : this.setXLBreakpointStatus(false);

		this.activeBreakpoints.includes('height-small')
			? this.setHeightSmallBreakpointStatus(true)
			: this.setHeightSmallBreakpointStatus(false);
	}
}
