import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'payout-dev',
	templateUrl: './payout-dev.component.html',
	styleUrls: ['./payout-dev.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayoutDevComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
