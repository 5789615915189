import { NgModule } from '@angular/core';
import { ContactSupportFormComponent } from './contact-support-form.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';
import { RouterModule } from '@angular/router';
import { ContactRequestSubmittedModule } from '../contact-request-submitted/contact-request-submitted.module';

const componentModules = [ContactRequestSubmittedModule];

@NgModule({
	declarations: [ContactSupportFormComponent],
	imports: [SharedModule, MaterialModule, RouterModule, ...componentModules],
	exports: [ContactSupportFormComponent],
})
export class ContactSupportFormModule {}
