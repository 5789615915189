export enum SocketEvent {
  _1_linkWallet = 'event:_1_linkWallet-PayoutServiceToTagionPortal',
  _2_linkWallet = 'event:_2_linkWallet-TagionPortalToPayoutService',

  _3_checkSum = 'event:_3_checkSum-TagionPortalToPayoutService',
  _4_checkSum = 'event:_4_checkSum-PayoutServiceToTagionPortal',

  _5_randomsMatch = 'event:_5_randomsMatch-PayoutServiceToTagionPortal',
  _6_payoutCreated = 'event:_6_payoutCreated-TagionPortalToPayoutService',
}

export enum InvitationStatusResponseCode {
  pending = 503870000,
  error = 503870001,
  success = 503870002
}

export enum InvestorType {
  investor = 503870000,
  ambassador = 503870001
}

export enum SupportInquiryType {
  general = 503870000,
  UI = 503870001,
  technical = 503870002
}

export enum ContactSupportBodyArea {
  wallet = 503870000,
  tagionPortal = 503870001,
  bountyProgram = 503870002
}

export enum ContactSupportBodyType {
  general = 503870000,
  UI = 503870001,
  technical = 503870002
}
