import { AbstractControl } from '@angular/forms';

export interface CustomValidator {
	[key: string]: boolean;
}

// Custom Validators
// 1. If the validation fails, it returns an object, which contains a key-value pair. Key is the name of the error and the value is always Boolean - true.
// 2. If the validation does not fail, it returns null.

export class CustomValidators {
	static nameValidator(control: AbstractControl): CustomValidator | null {
		const pattern = new RegExp('^[a-zA-Z\\s]+$'); // old - does not work for danish characters etc

		if (!control.value) return null;

		if (!pattern.test(control.value)) {
			return { isNameIncorrect: true };
		}

		return null;
	}

	static isNumberValidator(control: AbstractControl): CustomValidator | null {
		if (!control.value) return null;

		if (
			typeof Number(control.value) !== 'number' ||
			isNaN(Number(control.value)) ||
			isNaN(parseInt(control.value)) ||
			!Number.isInteger(Number(control.value))
		) {
			return { isNumberIncorrect: true };
		}

		return null;
	}

	// check if entered email equals email user logged in with
	// static sameEmailValidator(accountEmail: string | null, control: AbstractControl): CustomValidator | null {
	// 	if (!control.value || !accountEmail) return null;

	// 	if (control.value === accountEmail) {
	// 		return { isSameEmail: true };
	// 	}

	// 	return null;
	// }
}
