import { NgModule } from '@angular/core';
import { ReferralComponent } from './referral.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { ReferralFormModule } from '../../dumb/referral-form/referral-form.module';
import { ClipboardModule } from 'ngx-clipboard';

const componentModules = [ReferralFormModule, ClipboardModule];

@NgModule({
	declarations: [ReferralComponent],
	imports: [SharedModule, MaterialModule, PipesModule, ...componentModules],
	exports: [ReferralComponent],
})
export class ReferralModule {}
