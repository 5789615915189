import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SupportFormBodyModel, SupportFormResponseModel } from '../../models/other.models';
import { NetworkService } from './network.service';

@Injectable({
	providedIn: 'root',
})
export class SupportService implements OnDestroy {
	private _support = '/tickets';

	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(private _networkService: NetworkService) {}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public contactSupport(formData: SupportFormBodyModel): Observable<SupportFormResponseModel> {
		return this._networkService.postContactSupport(this._support, { ...formData }).pipe(
			// filterOutNullishValues(),
			takeUntil(this._onDestroy$),
		);
	}
}
