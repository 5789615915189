import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { ReferralFormComponent } from './referral-form.component';
import { DirectivesModule } from '../../../directives/directives.module';
import { EmailCheckModule } from '../email-check/email-check.module';

const componentModules = [EmailCheckModule];

@NgModule({
	declarations: [ReferralFormComponent],
	imports: [SharedModule, MaterialModule, DirectivesModule, ...componentModules],
	exports: [ReferralFormComponent],
})
export class ReferralFormModule {}
