<div class="referral-form-dialog">
	<form [formGroup]="inviteReferralForm" class="dialog-form">
		<h3 class="dialog-form__heading">Invite a friend</h3>

		<div
			*ngIf="!(isBreakpointHeightSmall$ | async) || (!(isBreakpointXS$ | async) && !(isBreakpointSM$ | async))"
			class="dialog-form__sub-heading"
		>
			A welcome email will be sent and you earn 5% for each successful referral
		</div>

		<mat-dialog-content class="dialog-form__content">
			<div class="first-row">
				<mat-form-field class="first-row__email">
					<mat-label>Email</mat-label>

					<input type="text" matInput [formControlName]="'email'" inputTrimmed />

					<!-- <email-check
						[form]="inviteReferralForm"
						[controlName]="'email'"
						[isSuccessIfEmailExists]="false"
						[tooltipNameForSuccess]="'User has not been invited yet'"
						[tooltipNameForFailure]="'User has been already invited'"
						matSuffix
					></email-check> -->

					<mat-error *ngIf="inviteReferralForm?.get('email')?.hasError('required')"> Please enter the email </mat-error>
					<mat-error *ngIf="inviteReferralForm?.get('email')?.hasError('email')"> Email is incorrect </mat-error>
					<mat-error *ngIf="inviteReferralForm?.get('email')?.hasError('maxlength')">
						Email has to be up to 75 characters long
					</mat-error>
				</mat-form-field>

				<mat-form-field class="first-row__country">
					<mat-label>Country</mat-label>
					<mat-select
						disableOptionCentering
						type="text"
						matInput
						[formControlName]="'country'"
						panelClass="referral-form-select-panel-class"
					>
						<mat-option
							*ngFor="let countryCode of countryCodesService.countryCodes"
							[value]="countryCode"
							class="referral-form-option-width mat-option-dark-bg"
						>
							{{ countryCode.country }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="inviteReferralForm?.get('country')?.hasError('required')"> Please select country </mat-error>
				</mat-form-field>
			</div>

			<div class="second-row">
				<mat-form-field class="second-row__first-name">
					<mat-label>First Name</mat-label>
					<input type="text" matInput [formControlName]="'firstName'" />
					<mat-error *ngIf="inviteReferralForm?.get('firstName')?.hasError('required')">
						Please enter your first name
					</mat-error>
					<mat-error *ngIf="inviteReferralForm?.get('firstName')?.hasError('maxlength')">
						First name has to be up to 50 characters long
					</mat-error>
					<!-- <mat-error *ngIf="inviteReferralForm?.get('firstName')?.hasError('isNameIncorrect')">
						Only letters are allowed
					</mat-error> -->
				</mat-form-field>

				<mat-form-field class="second-row__last-name">
					<mat-label>Last Name</mat-label>
					<input type="text" matInput [formControlName]="'lastName'" />
					<mat-error *ngIf="inviteReferralForm?.get('lastName')?.hasError('required')">
						Please enter your last name
					</mat-error>
					<mat-error *ngIf="inviteReferralForm?.get('lastName')?.hasError('maxlength')">
						Last name has to be up to 50 characters long
					</mat-error>
					<!-- <mat-error *ngIf="inviteReferralForm?.get('lastName')?.hasError('isNameIncorrect')">
						Only letters are allowed
					</mat-error> -->
				</mat-form-field>
			</div>
		</mat-dialog-content>

		<mat-dialog-actions class="dialog-form__actions">
			<button
				mat-button
				type="submit"
				(click)="sendInvite()"
				[disabled]="
					(isSpinnerVisible$ | async) ||
					inviteReferralForm.invalid
				"
				class="button-full-width button-main"
				[ngClass]="{
					'button-low':
						(isBreakpointXS$ | async) ||
						(isBreakpointSM$ | async) ||
						((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
					'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
				}"
			>
				<div *ngIf="!(isSpinnerVisible$ | async); else isSpinnerVisible">Send</div>

				<ng-template #isSpinnerVisible>
					<mat-progress-spinner
						*ngIf="isSpinnerVisible$ | async"
						[diameter]="24"
						[mode]="'indeterminate'"
						class="actions__submit-spinner"
					>
					</mat-progress-spinner>

					<div>Sending...</div>
				</ng-template>
			</button>

			<!-- <button
				mat-button
				type="button"
				[mat-dialog-close]="true"
				class="button-secondary"
				[ngClass]="{
					'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
					'button-narrow': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
					'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async),
					'button-wide': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async)
				}"
			>
				<div>Cancel</div>
			</button> -->
		</mat-dialog-actions>
	</form>
</div>
