import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { PurchasesComponent } from './purchases.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { ReferralFormModule } from '../../dumb/referral-form/referral-form.module';
import { BuyTagionsFormModule } from '../../dumb/buy-tagions-form/buy-tagions-form.module';

const componentModules = [BuyTagionsFormModule, ReferralFormModule];

@NgModule({
	declarations: [PurchasesComponent],
	imports: [SharedModule, MaterialModule, PipesModule, ...componentModules],
	exports: [PurchasesComponent],
})
export class PurchasesModule {}
