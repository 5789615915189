<div class="dashboard" *ngIf="account$ | async as account">
	<div *ngIf="(isBreakpointLG$ | async) || (isBreakpointXL$ | async)" class="dashboard__totals">
		<balance
			[selectedWallet]="selectedWallet$ | async"
			[selectedWalletLoadingState]="selectedWalletLoadingState$ | async"
		></balance>
	</div>

	<div class="dashboard__details">
		<div class="menu">
			<mat-card appearance="outlined" routerLink="purchases" routerLinkActive="routerLinkActive" class="menu__clickable-card">
				<div *ngIf="selectedWallet$ | async as selectedWallet" class="card-content">
					<div class="card-icon">
						<img src="../../../assets/icons/menu/purchase.svg" alt="purchase-icon" class="square-icon-image" />
					</div>

					<div *ngIf="(isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async)" class="card-info">
            <ng-container *ngIf="(isBreakpointLG$ | async) || (isBreakpointXL$ | async)">
              <ng-container *ngIf="!(selectedWalletLoadingState$ | async); else isSpinnerVisible">
                <h4 class="card-info-value">{{ getTotalTagionsAmount(selectedWallet?.userTransactions) | tagion }}</h4>
              </ng-container>

              <ng-template #isSpinnerVisible>
                <div class="isSpinnerVisibleAtDashboard">
                  <mat-progress-spinner [diameter]="18" [mode]="'indeterminate'"></mat-progress-spinner>
                </div>
              </ng-template>
            </ng-container>

						<div class="card-info-title">Purchases</div>
					</div>
				</div>
			</mat-card>

			<!-- TODO: regarding Ambassadors --------- add *ngIf="account.investorType === InvestorType.ambassador" to mat-card attributes -->
			<mat-card appearance="outlined" routerLink="referrals" routerLinkActive="routerLinkActive" class="menu__clickable-card">
				<div *ngIf="selectedWallet$ | async as selectedWallet" class="card-content">
					<div class="card-icon">
						<img src="../../../assets/icons/menu/referral.svg" alt="referral-icon" class="square-icon-image" />
					</div>

					<div *ngIf="(isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async)" class="card-info">
            <ng-container *ngIf="(isBreakpointLG$ | async) || (isBreakpointXL$ | async)">
              <ng-container *ngIf="!(selectedWalletLoadingState$ | async); else isSpinnerVisible">
                <h4 class="card-info-value">{{ getTotalReferralFeeAmount(selectedWallet?.userReferrals) | tagion }}</h4>
              </ng-container>

              <ng-template #isSpinnerVisible>
                <div class="isSpinnerVisibleAtDashboard">
                  <mat-progress-spinner [diameter]="18" [mode]="'indeterminate'"></mat-progress-spinner>
                </div>
              </ng-template>
            </ng-container>
						
						<div class="card-info-title">Referrals</div>
					</div>
				</div>
			</mat-card>

			<mat-card appearance="outlined" routerLink="invitations" routerLinkActive="routerLinkActive" class="menu__clickable-card">
				<div *ngIf="selectedWallet$ | async as selectedWallet" class="card-content">
					<div class="card-icon">
						<img src="../../../assets/icons/menu/invitation.svg" alt="invitation-icon" class="square-icon-image" />
					</div>

					<div *ngIf="(isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async)" class="card-info">
            <ng-container *ngIf="(isBreakpointLG$ | async) || (isBreakpointXL$ | async)">
              <ng-container *ngIf="!(selectedWalletLoadingState$ | async); else isSpinnerVisible">
                <div class="card-info-value-wrapper">
                  <h4 class="card-info-value">{{ getInvitationsAmount(selectedWallet?.userInvitation) }}</h4>
                  &nbsp;
                  <h4 class="card-info-value">Invites</h4>
                </div>
              </ng-container>

              <ng-template #isSpinnerVisible>
                <div class="isSpinnerVisibleAtDashboard">
                  <mat-progress-spinner [diameter]="18" [mode]="'indeterminate'"></mat-progress-spinner>
                </div>
              </ng-template>
            </ng-container>
						
						<div class="card-info-title">Invitations</div>
					</div>
				</div>
			</mat-card>

      <ng-template [ngIf]="account.isPayoutAllowed">
        <mat-card appearance="outlined" routerLink="payout" routerLinkActive="routerLinkActive" class="menu__clickable-card">
          <div *ngIf="selectedWallet$ | async as selectedWallet" class="card-content">
            <div class="card-icon">
              <img src="../../../assets/icons/menu/payout.svg" alt="payout-icon" class="square-icon-image" />
            </div>

            <div *ngIf="(isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async)" class="card-info">
              <ng-container *ngIf="(isBreakpointLG$ | async) || (isBreakpointXL$ | async)">
                <ng-container *ngIf="!(selectedWalletLoadingState$ | async); else isSpinnerVisible">
                  <div class="card-info-value-wrapper">
                    <h4 class="card-info-value">{{ getPayoutsAmount(selectedWallet) | tagion }}</h4>
                  </div>
                </ng-container>

                <ng-template #isSpinnerVisible>
                  <div class="isSpinnerVisibleAtDashboard">
                    <mat-progress-spinner [diameter]="18" [mode]="'indeterminate'"></mat-progress-spinner>
                  </div>
                </ng-template>
              </ng-container>
              
              <div class="card-info-title">Payouts</div>
            </div>
          </div>
        </mat-card>
      </ng-template>
		</div>

		<mat-card appearance="outlined" class="card-details">
			<router-outlet *ngIf="selectedWallet$ | async"></router-outlet>
		</mat-card>
	</div>
</div>
