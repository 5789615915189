import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { SharedModule } from '../../modules/shared.module';
import { MaterialModule } from '../../modules/material.module';
import { BalanceModule } from '../dashboard/balance/balance.module';
import { WalletSelectModule } from '../dumb/wallet-select/wallet-select.module';
import { WalletNameModule } from '../dumb/wallet-name/wallet-name.module';
import { MasterAccountFormModule } from '../dumb/master-account-form/master-account-form.module';
import { ContactSupportFormModule } from '../dumb/contact-support-form/contact-support-form.module';

const componentModules = [
	BalanceModule,
	WalletSelectModule,
	WalletNameModule,
	MasterAccountFormModule,
	ContactSupportFormModule,
];

@NgModule({
	declarations: [HeaderComponent],
	imports: [SharedModule, MaterialModule, ...componentModules],
	exports: [HeaderComponent],
})
export class HeaderModule {}
