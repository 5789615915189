import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	public matDialogRef: MatDialogRef<any> | null = null;

	constructor(private readonly _matDialog: MatDialog) {}

  // TODO: provide method to close dialog within this class (?)
  // TODO: now component closes dialog by importing MatDialogRef and calling matDialogRef.close()

	public open(componentName: any, disableClose: boolean = false, options?: any): void {
		this.matDialogRef = this._matDialog.open(componentName, {
      disableClose: disableClose,
			data: options,
			autoFocus: false, // to remove autofocus on open for first input
			restoreFocus: false,
      backdropClass: 'dialogBackdropBackground' // to style page background when mat-dialog is open
		});
	}

  // TODO: does class need this method?
	public confirmed(): Observable<boolean> {
		return (this.matDialogRef as MatDialogRef<any>).afterClosed().pipe(
			filter((isConfirmed) => Boolean(isConfirmed)),
			take(1),
		);
	}
}
