import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { ContactRequestSubmittedComponent } from './contact-request-submitted.component';

@NgModule({
	declarations: [ContactRequestSubmittedComponent],
	imports: [SharedModule, MaterialModule, RouterModule],
	exports: [ContactRequestSubmittedComponent],
})
export class ContactRequestSubmittedModule {}
