import { Injectable, OnDestroy } from '@angular/core';
import { NetworkService } from '../services/network.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil, tap, catchError } from 'rxjs/operators';
import { AuthUtils, filterOutNullishValues } from './auth.utils';
import { ActivateAccountResponse, DecodedAuthTokenModel } from '../../models/account.models';
import { ResponseModel } from '../../models/other.models';
import { SnackBarService } from '../services/snack-bar.service';
import { jwtDecode } from 'jwt-decode';
import { TokenResponse } from '../../models/referral.model';

/*
Authentication process:
1. User enters email -> query to api to check if user is found in CRM
2. If true - confirmation link sent to email
3. User clicks on link -> redirrect to registration component
4. User clicks 'Enter Account' button -> request to angularFireAuth.signInWithEmailLink with response: firebase.auth.UserCredential from which we extract firebaseIdToken
5. Request to api with firebaseIdToken, responds with authToken
6. Add authToken to localStorage
*/

@Injectable({
	providedIn: 'root',
})
export class AuthService implements OnDestroy {
	private _register = '/register';
	private _activate = '/activate';
	private _tokenexchange = '/tokenexchange';

	private _isSignedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(private _networkService: NetworkService, private _snackBarService: SnackBarService) {}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public enterAccount(firebaseIdToken: string): Observable<string> {
		if (this._isSignedIn$.value) {
			throw Error('User is already signed in.');
		}

		return this._networkService.post(this._activate, { token: firebaseIdToken }).pipe(
			map((response: ActivateAccountResponse) => response.data),
			filterOutNullishValues(),
			tap(() => this._isSignedIn$.next(true)),
			takeUntil(this._onDestroy$),
			catchError((error) => {
				console.error('--- Error while request to /activate: ', error);

				if (typeof error === 'string') {
					this._snackBarService.openAuthErrorSnackBar(`${error}`);
				} else {
					this._snackBarService.openAuthErrorSnackBar('Oops! Something went wrong. Can not POST');
				}

				return of(error);
			}),
		);
	}

	public signOut(): Observable<boolean> {
		localStorage.removeItem('saftAuthToken');
		localStorage.removeItem('saftAuthToken-masterAccess');

		this._isSignedIn$.next(false);

		return of(true);
	}

	public sendSignInLink(email: string): Observable<ResponseModel> {
		return this._networkService.post(this._register, { email: email }).pipe(
			filterOutNullishValues(),
			takeUntil(this._onDestroy$),
			catchError((error) => {
				this._snackBarService.openAuthErrorSnackBar(error.message);

				return of(error);
			}),
		);
	}

	public isStatusSignedIn(): Observable<boolean> {
		if (this._isSignedIn$.value) {
			return of(true);
		}

		// Check the access token availability
		if (!localStorage.getItem('saftAuthToken')) {
			return of(false);
		}

		if (AuthUtils.isTokenExpired(localStorage.getItem('saftAuthToken'))) {
			console.error('Token expired');
			return of(false);
		}

		// If the access token exists and it didn't expire, sign in using it
		// return this.signInUsingToken({ token: this.firebaseToken });
		return of(true);
	}

	public getSignInStatus$(): Observable<boolean> {
		return this._isSignedIn$.asObservable();
	}

	public setSignInStatus(): void {
		localStorage.getItem('saftAuthToken') ? this._isSignedIn$.next(true) : this._isSignedIn$.next(false);
	}

	public checkIfUserHasMasterAccess$(): Observable<boolean> {
		const authTokenMasterAccess = localStorage.getItem('saftAuthToken-masterAccess');

		if (authTokenMasterAccess) {
			return of(false);
		}

		const authToken: string | null = localStorage.getItem('saftAuthToken');

		if (authToken) {
			const decodedAuthToken: DecodedAuthTokenModel = jwtDecode(authToken);
			const isAdmin = decodedAuthToken?.isadmin ? JSON.parse(decodedAuthToken?.isadmin) : false;

			return isAdmin ? of(true) : of(false);
		} else {
			return of(false);
		}
	}

	public getAuthTokenFromPlaynet$(token: string): Observable<TokenResponse> {
		return this._networkService.post(this._tokenexchange, { token: token }).pipe(
			filterOutNullishValues(),
			takeUntil(this._onDestroy$),
			catchError((error) => {
				this._snackBarService.openAuthErrorSnackBar(error.message);

				return of(error);
			}),
		);
	}
}
