import { SharedModule } from './../../modules/shared.module';
import { NgModule } from '@angular/core';
import { ErrorNotFoundComponent } from './error-not-found.component';
import { MaterialModule } from '../../modules/material.module';

@NgModule({
	declarations: [ErrorNotFoundComponent],
	imports: [SharedModule, MaterialModule],
	exports: [ErrorNotFoundComponent],
})
export class ErrorNotFoundModule {}
