import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { Observable, Subject, map, of, takeUntil, tap } from 'rxjs';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { DataService } from '../../../core/services/data.service';
import { DialogService } from '../../../core/services/dialog.service';
import { AccountState } from '../../../core/store/states/account.states';
import { WalletState } from '../../../core/store/states/wallet.states';
import { AccountInfoData } from '../../../models/account.models';
import { PayoutStatusModel, UserPayout, WalletStateData } from '../../../models/wallet.models';
import { PayoutRequestFormComponent } from '../../dumb/payout-request-form/payout-request-form.component';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
	selector: 'payout',
	templateUrl: './payout.component.html',
	styleUrls: ['./payout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayoutComponent implements OnInit, AfterViewInit, OnDestroy {
	public account$: Observable<AccountInfoData> | null = null;
  public accountLoadingState$: Observable<boolean> | null = null;
	public selectedWallet$: Observable<WalletStateData> | null = null;
  public selectedWalletLoadingState$: Observable<boolean> | null = null;

	@ViewChild(MatTable) table!: MatTable<unknown>;
  @ViewChild(MatSort) sort: MatSort | null = null;

	public columnsToDisplay = ['date', 'amount', 'status'];
  public dataSource!: MatTableDataSource<UserPayout>;
  
	public mockData: string | null = null;

	public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;

  public isWalletLinked$: Observable<false | true> = of(false);

  private _userPayoutList: UserPayout[] = [];
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		private _dataService: DataService,
		private _breakpointService: BreakpointService,
		private _dialogService: DialogService,
    private _store: Store,
	) {}

	ngOnInit(): void {
    this._initializeDataFromStore();

    this._getInitialSortedData().subscribe();

    this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();

		this.mockData = this._dataService.mockData;

    this._dataService.setDashboardLastRoute('payout');
	}

  ngAfterViewInit(): void {
		if (this.table) {
			(this.dataSource as MatTableDataSource<UserPayout>).sort = this.sort;

			this.table.dataSource = this.dataSource as MatTableDataSource<UserPayout>;
		}
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

  public openPayoutForm() {
		this._dialogService.open(PayoutRequestFormComponent);
	}

	public mockStringCheck(property: string | undefined | null): string {
		return property ?? this.mockData as string;
	}

	public getPayoutStatus(oneUserPayoutStatusNumber: number): string {
    const payoutStatusItem = this._dataService.payoutStatus.find((payoutStatusItem: PayoutStatusModel) => {
      return payoutStatusItem.responseCode === oneUserPayoutStatusNumber;
    });

    return payoutStatusItem?.userStatus as string;
	}

	public getCssClassForPayoutStatus(oneUserPayoutStatusText: string): string {
    const payoutStatusItem = this._dataService.payoutStatus.find((payoutStatusItem: PayoutStatusModel) => {
      return payoutStatusItem.userStatus === oneUserPayoutStatusText;
    });

    return payoutStatusItem?.cssStatus as string;
	}

  public sortData(sort: Sort): UserPayout[] {
		const notSortedData = [...this._userPayoutList];

		if (!sort.active || sort.direction === '') {
			(this.dataSource as MatTableDataSource<UserPayout>).data = notSortedData;

			return notSortedData;
		}

		return ((this.dataSource as MatTableDataSource<UserPayout>).data = notSortedData.sort((a: UserPayout, b: UserPayout) => {
			const isAsc = sort.direction === 'asc';

			switch (sort.active) {
				case 'date':
					return this._compareData(a.requestedOn, b.requestedOn, isAsc);
				case 'amount':
					return this._compareData(a.amount, b.amount, isAsc);
				case 'status':
					return this._compareData(a.status, b.status, isAsc);
				default:
					return 0;
			}
		}));
	}

  public getTotalPayoutAmount(userPayoutList: UserPayout[] | undefined): number | string {
		return userPayoutList
			? userPayoutList
					.map((userPayout: UserPayout) => userPayout?.amount)
					.reduce((acc, value) => acc + value, 0) ?? this.mockData
			: this.mockData as string;
	}

  private _getInitialSortedData(): Observable<UserPayout[]> {
		return (this.selectedWallet$ as Observable<WalletStateData>).pipe(
			map((walletData: WalletStateData) => walletData.userPayouts),
			tap((userPayoutList: UserPayout[]) => {
				this.dataSource = new MatTableDataSource(userPayoutList);
				this._userPayoutList = [...userPayoutList];
				this.dataSource.data = this.sortData({ active: 'date', direction: 'desc' });
			}),
		);
	}

	private _compareData(a: string | number, b: string | number, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

  private _initializeDataFromStore(): void {
    this.account$ = this._store.select(AccountState.getAccount);
    this.accountLoadingState$ = this._store.select(AccountState.getAccountLoadingState);
    this.selectedWallet$ = this._store.select(WalletState.getSelectedWallet);
    this.selectedWalletLoadingState$ = this._store.select(WalletState.getSelectedWalletLoadingState);
  }

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
  }
}
