<div class="registration">
	<mat-card appearance="outlined" class="registration__card-wrapper">
		<div class="registration-card">
			<div class="registration-card__section-one">
				<div class="back">
					<button type="button" mat-button [routerLink]="'/sign-in'" class="back__button button-low button-back">
						<mat-icon aria-hidden="true" aria-label="Back" class="material-icons md-gray-darkest md-18">
							arrow_back
						</mat-icon>
						<div>Back</div>
					</button>
				</div>
			</div>

			<div class="registration-card__section-two">
				<h3 class="title">You have confirmed link!</h3>

				<div class="text">Press button bellow to complete sign in process</div>

				<div class="submit">
					<button
						type="submit"
						mat-button
						(click)="signIn()"
						[disabled]="isSpinnerVisible$ | async"
						class="submit__button button-main"
						[ngClass]="{
							'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
							'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async)
						}"
					>
						<div *ngIf="!(isSpinnerVisible$ | async); else isSpinnerVisible">Enter Account</div>

						<ng-template #isSpinnerVisible>
							<mat-progress-spinner [diameter]="18" [mode]="'indeterminate'" class="submit__button-spinner">
							</mat-progress-spinner>

							<div>Entering...</div>
						</ng-template>
					</button>
				</div>
			</div>
		</div>
	</mat-card>
</div>
