import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../core/auth/auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AccountState } from '../../core/store/states/account.states';
import { AccountInfoData } from '../../models/account.models';
import { WalletStateData } from '../../models/wallet.models';
import { WalletState } from '../../core/store/states/wallet.states';
import { BreakpointService } from '../../core/services/breakpoint.service';
import { DataService } from '../../core/services/data.service';
import { DialogService } from '../../core/services/dialog.service';
import { MasterAccountFormComponent } from '../dumb/master-account-form/master-account-form.component';
import { ContactSupportFormComponent } from '../dumb/contact-support-form/contact-support-form.component';
import { Store } from '@ngxs/store';

@Component({
	selector: 'header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
	public account$: Observable<AccountInfoData> | null = null;
  public accountLoadingState$: Observable<boolean> | null = null;
	public selectedWallet$: Observable<WalletStateData> | null = null;
  public selectedWalletLoadingState$: Observable<boolean> | null = null;

	public isSignedIn$: Observable<boolean> | null = null;
	public currentApplicationVersion = environment.appVersion;
	public isSpinnerVisible$: Observable<boolean> | null = null;

	public userHasMasterAccess$: Observable<boolean> = of(false);
	public isMasterAccessActive = false;

  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;

	// public mailTo: string | null = null;

	private _spinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		public authService: AuthService,
		public dataService: DataService,
		private _changeDetectorRef: ChangeDetectorRef,
		private _router: Router,
		private _breakpointService: BreakpointService,
		private _dialogService: DialogService,
    private _store: Store,
	) {}

	ngOnInit(): void {
		this.isSignedIn$ = this.authService.getSignInStatus$().pipe(tap(() => this._changeDetectorRef.markForCheck()));

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();

		this.isSpinnerVisible$ = this.getSpinnerStatus$();

		this.userHasMasterAccess$ = this.dataService.getMasterAccount$();
		this.isMasterAccessActive = Boolean(localStorage.getItem('saftAuthToken-masterAccess'));

    this._initializeDataFromStore();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public getMailToConfig(): Observable<string | null> {
		const mailToSubject = (accountId: number) =>
			`Tagion Portal - Support inquiry by ${accountId}. (Please do not change this subject)`;

		const mailToBody = 'Please describe your inquiry in this email.';

		return (this.account$ as Observable<AccountInfoData>).pipe(
			map((accountData: AccountInfoData) => accountData?.crmId),
			map((accountId: number) =>
				accountId ? `mailto:${environment.supportEmail}?Subject=${mailToSubject(accountId)}&body=${mailToBody}` : null,
			),
		);
	}

	public setSpinnerStatus(spinnerStatus: boolean): void {
		this._spinner$.next(spinnerStatus);
	}
	public getSpinnerStatus$(): Observable<boolean> {
		return this._spinner$.asObservable();
	}

	public returnToMainAccount(): void {
		const isConfirmed: boolean = confirm('Return back to main account?');

		if (isConfirmed) {
			localStorage.removeItem('saftAuthToken-masterAccess');
			window.location.reload();
		}
	}

	public contactSupport(): void {
		this._dialogService.open(ContactSupportFormComponent);
	}

	public signOut(): void {
		this.authService.signOut();
		this._router.navigate(['/sign-in']);
	}

	public openMasterAccountForm(): void {
		this._dialogService.open(MasterAccountFormComponent);
	}

  private _initializeDataFromStore(): void {
    this.account$ = this._store.select(AccountState.getAccount);
    this.accountLoadingState$ = this._store.select(AccountState.getAccountLoadingState);
    this.selectedWallet$ = this._store.select(WalletState.getSelectedWallet);
    this.selectedWalletLoadingState$ = this._store.select(WalletState.getSelectedWalletLoadingState);
  }

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
  }
}
