import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { AccountState } from '../../../core/store/states/account.states';
import { ReferralBody } from '../../../models/referral.model';
import { AccountInfoData } from '../../../models/account.models';
import { ReferralService } from '../../../core/services/referral.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { ResponseModel } from '../../../models/other.models';
import { Wallet } from '../../../core/store/actions/wallet.actions';
import { CountryCodesService } from '../../../core/services/country-codes.service';

@Component({
	selector: 'referral-form',
	templateUrl: './referral-form.component.html',
	styleUrls: ['./referral-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralFormComponent implements OnInit, OnDestroy {
	public account$: Observable<AccountInfoData> | null = null;

	// @ViewChild(EmailCheckComponent, { static: false })
	// public emailCheckComponent: EmailCheckComponent | null = null;

	public inviteReferralForm!: FormGroup;
	public referralBody: ReferralBody = {
		myWalletId: null,
		referalName: null,
		referalLastName: null,
		referalEmail: null,
		referalInvitationLanguage: null,
	};
	public isSpinnerVisible$: Observable<boolean> | null = null;

  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;
  public isBreakpointHeightSmall$: Observable<boolean> | null = null;

	private _spinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		public countryCodesService: CountryCodesService,
		private _formBuilder: FormBuilder,
		private _dialogRef: MatDialogRef<ReferralFormComponent>,
		private _referralService: ReferralService,
		private _snackBarService: SnackBarService,
		private _store: Store,
		private _breakpointService: BreakpointService,
	) {}

	ngOnInit(): void {
    this._initializeDataFromStore();

		this.isSpinnerVisible$ = this.getSpinnerStatus$();

		this._initInviteReferralForm();

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public setSpinnerStatus(spinnerStatus: boolean): void {
		this._spinner$.next(spinnerStatus);
	}
	public getSpinnerStatus$(): Observable<boolean> {
		return this._spinner$.asObservable();
	}

	public sendInvite(): void {
		this.setSpinnerStatus(true);

		(this.account$ as Observable<AccountInfoData>)
			.pipe(
				map((accountInfo: AccountInfoData) => accountInfo.mainWallet),
				tap((walletId: string) => (this.referralBody.myWalletId = walletId)),
				tap(() => this._getValuesFromForm()),
				switchMap(() => this._referralService.sendInvitationToReferral(this.referralBody)),
				filter((response: ResponseModel) => response.status === 1),
				tap((response: ResponseModel) => {
					if (response.status === 1) {
						this._snackBarService.openSuccessSnackBar('Invitation has been successfully sent');
					}
				}),
				tap(() => this.setSpinnerStatus(false)),
				tap(() => this._store.dispatch(new Wallet.GetOne(this.referralBody.myWalletId as string))),
				tap(() => this._dialogRef.close()),
				catchError((err: any) => {
					this.setSpinnerStatus(false);

					return of(err);
				}),
			)
			.subscribe();
	}

	private _getValuesFromForm(): void {
		this.referralBody.referalName = (this.inviteReferralForm as FormGroup).value.firstName;
		this.referralBody.referalLastName = (this.inviteReferralForm as FormGroup).value.lastName;
		this.referralBody.referalEmail = (this.inviteReferralForm as FormGroup).value.email;
		this.referralBody.referalInvitationLanguage = (this.inviteReferralForm as FormGroup).value.country.code;
	}

	private _initInviteReferralForm(): void {
		this.inviteReferralForm = this._formBuilder.group({
			email: ['', [Validators.required, Validators.maxLength(75), Validators.email]],
			country: ['', Validators.required],
			firstName: ['', [Validators.required, Validators.maxLength(50)]], //CustomValidators.nameValidator.bind(this)
			lastName: ['', [Validators.required, Validators.maxLength(50)]], // CustomValidators.nameValidator.bind(this)
		});
	}

  private _initializeDataFromStore(): void {
    this.account$ = this._store.select(AccountState.getAccount);
  }

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
    this.isBreakpointHeightSmall$ = this._breakpointService.getHeightSmallBreakpointStatus$();
  }
}
