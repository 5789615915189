import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Input,
	OnDestroy,
	DoCheck,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, catchError, debounceTime, map, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { AccountService } from '../../../core/services/account.service';
import { switchMap } from 'rxjs/operators';

@Component({
	selector: 'email-check',
	templateUrl: './email-check.component.html',
	styleUrls: ['./email-check.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailCheckComponent implements OnInit, DoCheck, OnDestroy {
	@Input() form!: FormGroup;
	@Input() controlName!: string;
	@Input() isSuccessIfEmailExists!: boolean;
	@Input() tooltipNameForSuccess!: string;
	@Input() tooltipNameForFailure!: string;

	public isSpinnerVisible$: Observable<boolean> = of(false);
	// public isDefault = true;
	public isSuccess = false;
	public isFailure = false;

	private _spinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(private _accountService: AccountService, private _changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.isSpinnerVisible$ = this.getSpinnerStatus$();

		this.listenToCheckIfEmailNotExistInCRM();
	}

	// to trigger changes in form control
	ngDoCheck() {
		this._changeDetectorRef.markForCheck();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public setSpinnerStatus(spinnerStatus: boolean): void {
		this._spinner$.next(spinnerStatus);
	}
	public getSpinnerStatus$(): Observable<boolean> {
		return this._spinner$.asObservable();
	}

	public listenToCheckIfEmailNotExistInCRM(): void {
		(this.form as FormGroup)
			.get(this.controlName as string)
			?.valueChanges.pipe(
				tap(() => this.setSpinnerStatus(true)),
				debounceTime(1000),
				switchMap((emailControlValue: string) => this._accountService.checkIfEmailExistsInCrm(emailControlValue)),
				map((emailExists: boolean | Error) => {
					if (emailExists) {
						// this.isDefault = false;
						this.isSuccess = this.isSuccessIfEmailExists as boolean;
						this.isFailure = !this.isSuccessIfEmailExists;
					} else if (!emailExists) {
						// this.isDefault = false;
						this.isSuccess = !this.isSuccessIfEmailExists;
						this.isFailure = this.isSuccessIfEmailExists as boolean;
					}
					(this.form as FormGroup).controls[(this.controlName as string)].markAsPristine();
				}),
				// to see if email passed validation after it was checked in CRM
				tap(() => (this.form as FormGroup).get(this.controlName as string)?.markAsTouched()),
				tap(() => this.setSpinnerStatus(false)),
				tap(() => this._changeDetectorRef.markForCheck()),
				takeUntil(this._onDestroy$),
				catchError((err: any) => {
					this.setSpinnerStatus(false);

					return of(err);
				}),
			)
			.subscribe();
	}
}
