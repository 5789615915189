import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { AuthService } from '../../../core/auth/auth.service';
import { FirebaseService } from '../../../core/services/firebase.service';
import firebase from 'firebase/compat';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { of, Observable, Subject, BehaviorSubject } from 'rxjs';
import { BreakpointService } from '../../../core/services/breakpoint.service';

@Component({
	selector: 'registration',
	templateUrl: './registration.component.html',
	styleUrls: ['./registration.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationComponent implements OnInit, OnDestroy {
	public isSpinnerVisible$: Observable<boolean> | null = null;

  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;

	private _spinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		private _authService: AuthService,
		private _firebaseService: FirebaseService,
		private _router: Router,
		private _breakpointService: BreakpointService,
	) {}

	ngOnInit(): void {
		this.isSpinnerVisible$ = this.getSpinnerStatus$();

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public setSpinnerStatus(spinnerStatus: boolean): void {
		this._spinner$.next(spinnerStatus);
	}
	public getSpinnerStatus$(): Observable<boolean> {
		return this._spinner$.asObservable();
	}

	public async signIn(): Promise<void> {
		this.setSpinnerStatus(true);

		const userCredential: firebase.auth.UserCredential = await this._firebaseService.getUserCredential();

		localStorage.removeItem('saftEmailForSignIn');
		const firebaseIdToken: string | undefined = await userCredential?.user?.getIdToken();

		if (firebaseIdToken) {
			this._authService
				.enterAccount(firebaseIdToken)
				.pipe(
					tap((authToken: string) => localStorage.setItem('saftAuthToken', authToken)),
					tap(() => this.setSpinnerStatus(false)),
					tap(() => this._router.navigate(['/dashboard'])),
					catchError((err: any) => {
						this.setSpinnerStatus(false);

						return of(err);
					}),
				)
				.subscribe();
		}
	}

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
  }
}
