<div class="buy-tagions-form-dialog">
	<form [formGroup]="becomeAmbassadorForm" class="dialog-form">
		<h3 class="dialog-form__heading">Become an Ambassador</h3>

		<div class="dialog-form__sub-heading">
			<div>Ambassadors help us spread the word about Tagion and are rewarded for their help.</div>
		</div>

		<mat-dialog-content class="dialog-form__content">
			<div class="content__item">As an ambassador you will be in closer contact with the team.</div>
			<div class="content__item">
				When you click "Apply" an email will be sent to us and we will make sure to reach out with further information.
			</div>
		</mat-dialog-content>

		<mat-dialog-actions class="dialog-form__actions">
			<a
				[href]="getMailToConfig() | async"
				target="_blank"
				mat-button
				(click)="closeDialogWindow()"
				class="header-link button-full-width button-main"
				[ngClass]="{
					'button-low': (isBreakpointHeightSmall$ | async),
					'button-high': !(isBreakpointHeightSmall$ | async)
				}"
			>
				<div>Apply</div>

				<!-- <ng-template #isSpinnerVisible>
					<mat-progress-spinner
						*ngIf="isSpinnerVisible$ | async"
						[diameter]="24"
						[mode]="'indeterminate'"
						class="actions__submit-spinner"
					>
					</mat-progress-spinner>

					<div>Applying...</div>
				</ng-template> -->
			</a>
		</mat-dialog-actions>
	</form>
</div>
