import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagionPipe } from './tagion.pipe';
import { Euro4DecimalsPipe } from './euro-4-decimals.pipe';
import { Euro2DecimalsPipe } from './euro-2-decimals.pipe';
import { ShortIdPipe } from './short-id.pipe';
import { WalletAddressPipe } from './wallet-address.pipe';

@NgModule({
	declarations: [TagionPipe, Euro4DecimalsPipe, Euro2DecimalsPipe, ShortIdPipe, WalletAddressPipe],
	imports: [CommonModule],
	exports: [TagionPipe, Euro4DecimalsPipe, Euro2DecimalsPipe, ShortIdPipe, WalletAddressPipe],
})
export class PipesModule {}
