import { NgModule } from '@angular/core';
import { BecomeAmbassadorFormComponent } from './become-ambassador-form.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';

@NgModule({
	declarations: [BecomeAmbassadorFormComponent],
	imports: [SharedModule, MaterialModule],
	exports: [BecomeAmbassadorFormComponent],
})
export class BecomeAmbassadorFormModule {}
