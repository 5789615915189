import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../core/services/data.service';

@Pipe({
	name: 'shortId',
})
export class ShortIdPipe implements PipeTransform {
	constructor(private _dataService: DataService) {}

	transform(id: string | null): string {
		return id?.slice(0, 8) || this._dataService.mockData;
	}
}
