import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class SnackBarService {
	constructor(private snackBar: MatSnackBar) {}

	openSuccessSnackBar(message: string): void {
		this.snackBar.open(message, '', {
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
			duration: 3000,
			panelClass: 'snackbar-success',
		});
	}

	openErrorSnackBar(message: string): void {
		this.snackBar.open(message, '', {
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
			duration: 6000,
			panelClass: 'snackbar-error',
		});
	}

	openAuthErrorSnackBar(message: string): void {
		this.snackBar.open(message, 'Close', {
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
			panelClass: 'snackbar-auth-error',
		});
	}

	openInfoSnackBar(message: string): void {
		this.snackBar.open(message, '', {
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
			duration: 2500,
			panelClass: 'snackbar-info',
		});
	}
}
