<div class="balance-data">
	<div class="block-container">
		<div class="block-container-left">
			<div class="card-icon">
				<img src="../../../../assets/icons/balance/total-tagions.svg" alt="total-tagions-icon" class="square-icon-image" />
			</div>

			<h4
				class="balance-data-title"
			>
				Total Tagions
			</h4>
		</div>

		<div class="block-container-right">
			<ng-container *ngIf="!selectedWalletLoadingState; else isSpinnerVisible">
				<ng-container *ngIf="selectedWallet; else noWalletSelected">
					<h4>{{ dataService.mockNumberCheck(selectedWallet.walletSummary.totalTagions) | tagion }}</h4>
				</ng-container>

				<ng-template #noWalletSelected>
					<div class="noWalletSelected">{{ this.dataService.mockData }}</div>
				</ng-template>
			</ng-container>

			<ng-template #isSpinnerVisible>
				<mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
			</ng-template>
		</div>
	</div>

	<div class="block-container">
		<div class="block-container-left">
			<div class="card-icon">
				<img src="../../../../assets/icons/balance/current-price.svg" alt="current-price-icon" class="square-icon-image" />
			</div>

			<h4
				class="balance-data-title"
			>
				Current Tagion Price
			</h4>
		</div>

		<div class="block-container-right">
			<ng-container *ngIf="!selectedWalletLoadingState; else isSpinnerVisible">
				<ng-container *ngIf="selectedWallet; else noWalletSelected">
					<h4>{{ dataService.mockNumberCheck(selectedWallet.walletSummary.currentTagionPrice) | euro4Decimals }}</h4>
				</ng-container>

				<ng-template #noWalletSelected>
					<div class="noWalletSelected">{{ this.dataService.mockData }}</div>
				</ng-template>
			</ng-container>

			<ng-template #isSpinnerVisible>
				<mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
			</ng-template>
		</div>
	</div>

	<div class="block-container">
		<div class="block-container-left">
			<div class="card-icon">
				<img src="../../../../assets/icons/balance/total-currency.svg" alt="total-euro-icon" class="square-icon-image" />
			</div>

			<h4
				class="balance-data-title"
			>
				Total in Euro
			</h4>
		</div>

		<div class="block-container-right">
			<ng-container *ngIf="!selectedWalletLoadingState; else isSpinnerVisible">
				<ng-container *ngIf="selectedWallet; else noWalletSelected">
					<h4>{{ calculateTotalCurrencyAmount(selectedWallet.walletSummary) | euro2Decimals }}</h4>
				</ng-container>

				<ng-template #noWalletSelected>
					<div class="noWalletSelected">{{ this.dataService.mockData }}</div>
				</ng-template>
			</ng-container>

			<ng-template #isSpinnerVisible>
				<mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
			</ng-template>
		</div>
	</div>

  <div class="block-container">
		<div class="block-container-left">
			<div class="card-icon">
				<img src="../../../../assets/icons/balance/pending-vested.svg" alt="pending-vested-icon" class="square-icon-image" />
			</div>

			<h4
				class="balance-data-title"
			>
				Pending Vested
			</h4>
		</div>

		<div class="block-container-right">
			<ng-container *ngIf="!selectedWalletLoadingState; else isSpinnerVisible">
				<ng-container *ngIf="selectedWallet; else noWalletSelected">
					<h4>{{ dataService.mockNumberCheck(selectedWallet.walletSummary.pendingVested) | tagion }}</h4>
				</ng-container>

				<ng-template #noWalletSelected>
					<div class="noWalletSelected">{{ this.dataService.mockData }}</div>
				</ng-template>
			</ng-container>

			<ng-template #isSpinnerVisible>
				<mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
			</ng-template>
		</div>
	</div>

  <div class="block-container">
		<div class="block-container-left">
			<div class="card-icon">
				<img src="../../../../assets/icons/balance/vested.svg" alt="vested-icon" class="square-icon-image" />
			</div>

			<h4
				class="balance-data-title"
			>
				Vested Tagions
			</h4>
		</div>

		<div class="block-container-right">
			<ng-container *ngIf="!selectedWalletLoadingState; else isSpinnerVisible">
				<ng-container *ngIf="selectedWallet; else noWalletSelected">
					<h4>{{ dataService.mockNumberCheck(selectedWallet.walletSummary.vestedTagions) | tagion }}</h4>
				</ng-container>

				<ng-template #noWalletSelected>
					<div class="noWalletSelected">{{ this.dataService.mockData }}</div>
				</ng-template>
			</ng-container>

			<ng-template #isSpinnerVisible>
				<mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
			</ng-template>
		</div>
	</div>
</div>
