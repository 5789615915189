import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, delay, filter, map, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { AccountState } from '../../../core/store/states/account.states';
import { AccountInfoData } from '../../../models/account.models';
import { PayoutRequestBodyModel } from '../../../models/payout-request.model';
import { SocketIoService } from '../../../core/services/socket-io/socket-io.service';
import { DataService } from '../../../core/services/data.service';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { CustomValidators } from '../../../validators/validators';
import { WalletService } from '../../../core/services/wallet.service';
import { PayoutResponse, WalletStateData } from '../../../models/wallet.models';
import { WalletState } from '../../../core/store/states/wallet.states';
import { MatStepper } from '@angular/material/stepper';
import { SocketEvent } from '../../../enums/enums';
import { MatDialogRef } from '@angular/material/dialog';
import { Wallet } from '../../../core/store/actions/wallet.actions';
import { environment } from '../../../../environments/environment';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { RandomsMatchResponseFromPayoutService } from '../../../models/socket.model';

@Component({
	selector: 'payout-request-form',
	templateUrl: './payout-request-form.component.html',
	styleUrls: ['./payout-request-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
  // to enable [state] directive for mat-step
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class PayoutRequestFormComponent implements OnInit, OnDestroy {
  @ViewChild("stepper", { static: false }) private stepper!: MatStepper;
  
	public account$: Observable<AccountInfoData> | null = null;
  public selectedWallet$: Observable<WalletStateData> | null = null;

	public payoutRequestForm!: FormGroup;
  public downloadAppsForm!: FormGroup;
  public linkWalletForm!: FormGroup;
  public payoutStepOneForm!: FormGroup;
  public payoutStepTwoForm!: FormGroup;

  // TODO: was used for testing
  // public mockedPayoutAmount = 3600;

	public referralBody: PayoutRequestBodyModel = {
		walletAddress: null,
		tagionAmount: null,
	};
	public isSpinnerVisible$: Observable<boolean> | null = null;

  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;
  public isBreakpointHeightSmall$: Observable<boolean> | null = null;

  public isShowSuccessfulPayoutPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isShowFailedPayoutPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public randomGuid = '';

  public qrCodeSizeXS = '85';
  public qrCodeSizeSM = '134';
  public qrCodeSizeMDLGXL = '154';

  public qrCodeDownloadIosAppValue: string = ''; // QRCodeDownloadAppModel
  public qrCodeDownloadAndroidAppValue: string = ''; // QRCodeDownloadAppModel
  public qrCodeLinkWalletValue: string = ''; // QRCodeLinkWalletModel
  public qrCodeMakePayoutValue: string = ''; // QRCodeMakePayoutModel

  public isFirstStepCompleted = false;
  public isSecondStepCompleted = false;
  public isThirdStepCompleted = false;
  public isFourthStepCompleted = false;

  public isShowDownloadIosWalletQrCodeAtXsSmBreakpoints = true;
  public isShowDownloadAndroidWalletQrCodeAtXsSmBreakpoints = false;

  public investorRelationsEmail = environment.investorRelationsEmail;

	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		public dataService: DataService,
		public socketIoService: SocketIoService,
		private _formBuilder: FormBuilder,
		private _breakpointService: BreakpointService,
    private _store: Store,
    private _socketIoService: SocketIoService,
    private _walletService: WalletService,
    private _dialogRef: MatDialogRef<PayoutRequestFormComponent>
	) {}

	ngOnInit(): void {
    this._initializeDataFromStore();

		this.isSpinnerVisible$ = this.dataService.getPayoutRequestFormSpinnerStatus$();

		this._initPayoutRequestForm();

    this._initDownloadAppsForm();
    this._initLinkWalletForm();
    this._initPayoutStepOneForm();
    this._initPayoutStepTwoForm();

    this._generateQRsDownloadApp();

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();

    this.randomGuid = this.socketIoService.guid;

    this.selectedWallet$?.pipe(
      tap((walletData: WalletStateData) => {
        this._socketIoService.initializeSocketInstance(walletData.walletSummary.id, walletData.walletSummary.walletEmail, walletData.walletSummary.linkedPubKey);

        this._socketIoService.listenToConnectEvent();
        this._socketIoService.listenToDisconnectEvent();
        this._socketIoService.listenToConnectError();

        this._socketIoService.listenToLinkWalletEventMessagesFromPayoutService();
        this._socketIoService.listenToCheckSumEventMessagesFromPayoutService();
        this._socketIoService.listenToRandomsMatchEventMessagesFromPayoutService();

        this._walletService.listenToSocketDataForLinkWalletEventFromPayoutService(walletData.walletSummary.id);

        this.listenToRandomsMatchedEventThenSubmitPayoutRequest();
      }),
      take(1),
      takeUntil(this._onDestroy$)
    ).subscribe();
	}

	ngOnDestroy(): void {
    this.dataService.setPayoutRequestFormSpinnerStatus(false);
    this.dataService.setIsLinkingProcessSucceeded(false);
    this.dataService.setRandomsMatch(null);
    this.dataService.setCheckSum(null);
    this.dataService.setCrmWalletIdAndPubkeyBase64FromPayoutService(null);

    this.isShowSuccessfulPayoutPage$.next(false);
    this.isShowFailedPayoutPage$.next(false);

    this.isFirstStepCompleted = false;
    this.isSecondStepCompleted = false;
    this.isThirdStepCompleted = false;
    this.isFourthStepCompleted = false;

    this.isShowDownloadIosWalletQrCodeAtXsSmBreakpoints = false;
    this.isShowDownloadAndroidWalletQrCodeAtXsSmBreakpoints = false;

    this._socketIoService.closeSocketConnection();

    // When modal window is closed by click outside of it -> Update payout list to show new payout in the list
    this._updatePayoutList();

		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

  public listenToRandomsMatchedEventThenSubmitPayoutRequest() {
    let crmWalletId = '';
    let payoutAmount = 0;

    let isShowSuccessfulPayoutPage = false;
    let isShowFailedPayoutPage = false;

    this.dataService.getRandomsMatch$().pipe(
      // TODO: filter() is necessary here - it prevents from Observable emit values before real value comes
      filter((randomsMatch: RandomsMatchResponseFromPayoutService | null) => !!randomsMatch),
      map((randomsMatch: RandomsMatchResponseFromPayoutService | null) => {
        // console.log('--- randomsMatch: ', randomsMatch?.isRandomsMatch);
        // console.log('--- publicKeysMatch: ', randomsMatch?.isPubKeysMatch);

        if (randomsMatch) {
          if (!randomsMatch?.isRandomsMatch) {
            throw Error('Randoms do not match');
          } else if (!randomsMatch?.isPubKeysMatch) {
            throw Error('Public keys do not match');
          } else {
            return true;
          }
        } else {
          throw Error('Can not authenticate payout request');
        }
      }),
      switchMap(() => this._store.select(WalletState.getSelectedWallet)),
      tap((walletData: WalletStateData | null) => {
        crmWalletId = walletData?.walletSummary.id as string
        payoutAmount = walletData?.walletSummary.pendingVested as number;

        // console.log('--- crmWalletId: ', crmWalletId);
        // console.log('--- payoutAmount: ', payoutAmount);
      }),
      // TODO: this.mockedPayoutAmount used to be here for testing
      switchMap(() => this._walletService.requestPayout(crmWalletId, payoutAmount)),
      map((payoutResponse: PayoutResponse) => {
        if (payoutResponse?.isSuceceded) {
          this._socketIoService.emitEventToPayoutService(SocketEvent._6_payoutCreated, { isPayoutCreatedSuccessfully: true });

          isShowSuccessfulPayoutPage = true;
          isShowFailedPayoutPage = false;
        } else {
          this._socketIoService.emitEventToPayoutService(SocketEvent._6_payoutCreated, { isPayoutCreatedSuccessfully: false });

          isShowSuccessfulPayoutPage = false;
          isShowFailedPayoutPage = true;
        }
      }),
      // TODO: Fix this. Same hack as in payout-service 
      delay(7500),
      tap(() => {
        if (isShowSuccessfulPayoutPage && !isShowFailedPayoutPage) {
          // successful payout page will open here
          this.isShowSuccessfulPayoutPage$.next(true);
          this.isShowFailedPayoutPage$.next(false);
        } else if (!isShowSuccessfulPayoutPage && isShowFailedPayoutPage) {
          // failed payout page will open here
          this.isShowSuccessfulPayoutPage$.next(false);
          this.isShowFailedPayoutPage$.next(true);
        } else {
          console.error('Something went wrong with payout');
        }
      }),
      takeUntil(this._onDestroy$),
      catchError((err: any) => {
        // this.setSpinnerStatus(false);

        return of(err);
      }),
    ).subscribe();
  }

  public closeSuccessfulPayoutPage() {
    this._dialogRef.close();

    this.isShowSuccessfulPayoutPage$.next(false);
    this.isShowFailedPayoutPage$.next(false);

    // Update payout list
    this._updatePayoutList();
  }

  public closeFailedPayoutPage() {
    this._dialogRef.close();

    this.isShowSuccessfulPayoutPage$.next(false);
    this.isShowFailedPayoutPage$.next(false);

    // Update payout list
    this._updatePayoutList();
  }

  public onMatStepperStepChange(event: any): void {
    // Download step
    if (event?.selectedIndex === 0) {}

    // Linking step
    if (event?.selectedIndex === 1) {
      this._generateQRLinkWalletStep();

      this.isFirstStepCompleted = true;
    }

    // Payout step
    if (event?.selectedIndex === 2) {
      // send event:checkSum to Payout service that Portal is ready to receive checkSum
      this._socketIoService.emitEventToPayoutService(SocketEvent._3_checkSum, { isReadyForCheckSum: true });

      this.isSecondStepCompleted = true;
    }

    // Confirmation step
    if (event?.selectedIndex === 3) {
      this._generateQrMakePayoutStep();

      this.isThirdStepCompleted = true;
    }
  }

	public skipNegativeNumber(event: any): void {
		if (event.target.value < 0) {
			event.target.value = 0;
		}
	}

  public showDownloadIosWalletQrCodeAtXsSmBreakpoints() {
    this.isShowDownloadIosWalletQrCodeAtXsSmBreakpoints = true;
    this.isShowDownloadAndroidWalletQrCodeAtXsSmBreakpoints = false;
  }

  public showDownloadAndroidWalletQrCodeAtXsSmBreakpoints() {
    this.isShowDownloadIosWalletQrCodeAtXsSmBreakpoints = false;
    this.isShowDownloadAndroidWalletQrCodeAtXsSmBreakpoints = true;
  }

  private _updatePayoutList(): void {
    this.selectedWallet$?.pipe(
      tap((walletData: WalletStateData) => this._store.dispatch(new Wallet.GetOne(walletData.walletSummary.id))),
      take(1),
      takeUntil(this._onDestroy$)
    ).subscribe();
  }

  private _generateQRsDownloadApp(): void {
    // TODO: verify link to stores here
    this.qrCodeDownloadIosAppValue = JSON.stringify({
      downloadLink: 'https://apps.apple.com/us/app/justpay-wallet/id1669456689'
		});
    // TODO: verify link to stores here
    this.qrCodeDownloadAndroidAppValue = JSON.stringify({
      downloadLink: 'https://play.google.com/store/apps/details?id=com.decardag.justpay'
		});
  }

  private _generateQrMakePayoutStep(): void {
    let crmWalletId = '';
    let amount = 0;
    let checkSum = '';

    this.selectedWallet$?.pipe(
      map((walletData: WalletStateData) => {
        crmWalletId = walletData.walletSummary.id;
        amount = walletData.walletSummary.pendingVested;
        // TODO: was used for testing
        // amount = this.mockedPayoutAmount;
      }),
      switchMap(() => this.dataService.getCheckSum$()),
      tap((checkSumValue: string | null) => checkSumValue ? checkSum = checkSumValue : null),
      tap(() => {
        this.qrCodeMakePayoutValue = JSON.stringify({
          crmWalletId: crmWalletId,
          amount: amount,
          checkSum: checkSum
        });
      }),
      take(1),
      takeUntil(this._onDestroy$)
    ).subscribe()
  }

  private _generateQRLinkWalletStep(): void {
    this.selectedWallet$?.pipe(
      tap((walletData: WalletStateData) => {
        this.qrCodeLinkWalletValue = JSON.stringify({
          crmWalletId: walletData.walletSummary.id,
        })
      }),
      takeUntil(this._onDestroy$)
    ).subscribe();
  }

	private _initPayoutRequestForm(): void {
		this.payoutRequestForm = this._formBuilder.group({
			walletAddress: [null, [Validators.required]],
			// TODO: could tagionAmount be decimal?
			tagionAmount: [null, [Validators.required, CustomValidators.isNumberValidator.bind(this)]],
		});
	}

  private _initDownloadAppsForm(): void {
		this.downloadAppsForm = this._formBuilder.group({});
	}

  private _initLinkWalletForm(): void {
		this.linkWalletForm = this._formBuilder.group({});
	}

  private _initPayoutStepOneForm(): void {
		this.payoutStepOneForm = this._formBuilder.group({
			walletAddress: [null],
			// TODO: could tagionAmount be decimal?
			// tagionAmount: [null, [Validators.required, CustomValidators.isNumberValidator.bind(this)]],
			tagionAmount: [null],
		});
	}

  private _initPayoutStepTwoForm(): void {
		this.payoutStepTwoForm = this._formBuilder.group({});
	}

  private _initializeDataFromStore(): void {
    this.account$ = this._store.select(AccountState.getAccount);
    this.selectedWallet$ = this._store.select(WalletState.getSelectedWallet);
  }

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
    this.isBreakpointHeightSmall$ = this._breakpointService.getHeightSmallBreakpointStatus$();
  }
}
