import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routing';
import { environment } from '../environments/environment';
import { RegistrationModule } from './components/no-auth/registration/registration.module';
import { ErrorNotFoundModule } from './components/error-not-found/error-not-found.module';
import { SignInModule } from './components/no-auth/sign-in/sign-in.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { AccountState } from './core/store/states/account.states';
import { WalletState } from './core/store/states/wallet.states';
import { LayoutModule } from '@angular/cdk/layout';
import { CheckInboxModule } from './components/no-auth/check-inbox/check-inbox.module';
// import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';
import { HeaderModule } from './components/header/header.module';
import { PayoutDevModule } from './components/no-auth/payout-dev/payout-dev.module';
import { AnonReferralModule } from './components/no-auth/anon-referral/anon-referral.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		// api calls
		HttpClientModule,

		// default modules
		BrowserModule,
		RouterModule.forRoot(appRoutes),
		BrowserAnimationsModule,
		LayoutModule, // to detect breakpoints

		// Firebase
    // provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		AngularFireModule.initializeApp(environment.firebaseConfig),

		// component modules

		// no-auth
		SignInModule,
		RegistrationModule,
		CheckInboxModule,
		PayoutDevModule,
		AnonReferralModule,

		// auth
		HeaderModule,
		DashboardModule,
		ErrorNotFoundModule,

		// store
		NgxsModule.forRoot([AccountState, WalletState], {
			developmentMode: !environment.production,
		}),
		NgxsReduxDevtoolsPluginModule.forRoot(),
		NgxsLoggerPluginModule.forRoot(),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		// AngularFirestore,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
