import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResponseModel } from '../../models/other.models';
import { AnonReferralBody, ReferralBody } from '../../models/referral.model';
import { filterOutNullishValues } from '../auth/auth.utils';
import { NetworkService } from './network.service';

@Injectable({
	providedIn: 'root',
})
export class ReferralService implements OnDestroy {
	private _referral = '/referral';
	private _anonReferral = (accountId: string) => `/referral/anonymous/${accountId}`;

	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(private _networkService: NetworkService) {}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public sendInvitationToReferral(formData: ReferralBody): Observable<ResponseModel> {
		return this._networkService
			.post(this._referral, { ...formData })
			.pipe(filterOutNullishValues(), takeUntil(this._onDestroy$));
	}

	public submitAnonReferral(accountId: string, formData: AnonReferralBody): Observable<ResponseModel> {
		return this._networkService
			.post(this._anonReferral(accountId), { ...formData })
			.pipe(filterOutNullishValues(), takeUntil(this._onDestroy$));
	}
}
