import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BuyTagionsBody, ResponseModel } from '../../models/other.models';
import { NetworkService } from './network.service';
import { takeUntil } from 'rxjs/operators';
import { filterOutNullishValues } from '../auth/auth.utils';

@Injectable({
	providedIn: 'root',
})
export class BuyTagionsService {
	private _buy = (walletId: string) => `/wallet/${walletId}/buy`;
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(private _networkService: NetworkService) {}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public sendBuyTagionRequest(walletId: string, formData: BuyTagionsBody): Observable<ResponseModel> {
		return this._networkService
			.post(this._buy(walletId), formData)
			.pipe(filterOutNullishValues(), takeUntil(this._onDestroy$));
	}
}
