import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '../../../environments/environment';
import { SnackBarService } from './snack-bar.service';
import firebase from 'firebase/compat';

@Injectable({
	providedIn: 'root',
})
export class FirebaseService {
	actionCodeSettings = {
		// URL to redirect back to. Must be in the authorized domains list in the Firebase Console.
		url: this._setUrl(),
		handleCodeInApp: true,
		// Had error: INVALID_DYNAMIC_LINK_DOMAIN. Solution: If use only for a web app, don't need to include the android or ios fields in the ActionCodeSettings object. This avoids the need to setup dynamicLinkDomain:
		// iOS: {
		// 	bundleId: 'com.example.ios',
		// },
		// android: {
		// 	packageName: 'com.example.android',
		// 	installApp: true,
		// 	minimumVersion: '12',
		// },
		// dynamicLinkDomain: 'example.page.link',
	};

	constructor(
		public angularFireAuth: AngularFireAuth,
		private _router: Router,
		private _snackBarService: SnackBarService,
	) {
		this.angularFireAuth.useDeviceLanguage();
	}

	public async getUserCredential(): Promise<any | null> {
		if (await this.angularFireAuth.isSignInWithEmailLink(window.location.href)) {
			let email = localStorage.getItem('saftEmailForSignIn');
			if (!email) {
				// User opened the link on a different device. To prevent session fixation
				// attacks, ask the user to provide the associated email again.
				email = window.prompt('Please provide email we send link to') as string;
			}
			// The client SDK will parse the OOB code from the link under the hood.
			return this.angularFireAuth
				.signInWithEmailLink(email, window.location.href)
				.catch((error: firebase.FirebaseError) => {
					// will handle spinners on component level
					// this._dataService.setSpinnerStatus(false);

					if (error.code === 'auth/argument-error') {
						if (error.message === 'Invalid email link!' || error.message.includes('must be a valid string')) {
							this._snackBarService.openAuthErrorSnackBar('Incorrect email');
						}
					} else if (error.code === 'auth/invalid-email') {
						this._snackBarService.openAuthErrorSnackBar('Invalid email');
					} else {
						this._snackBarService.openAuthErrorSnackBar(error.message);
					}
				});
		} else {
			console.error('Not signed with email link');
			return new Promise(() => Promise.resolve(null));
		}
	}

	private _setUrl(): string {
		if (environment.production) {
			return environment.webUri;
		}

		return environment.webUri;
	}
}
