<mat-card appearance="outlined" class="master-account-form">
	<form [formGroup]="masterAccountForm" class="dialog-form">
		<h3 class="dialog-form__heading">Re-login</h3>

		<div class="dialog-form__sub-heading">Login as another account</div>

		<mat-dialog-content class="dialog-form__content">
			<mat-form-field class="email">
				<mat-label>Login as</mat-label>

				<input
					type="text"
					[value]="masterAccountForm.get('email')?.value"
					matInput
					[formControlName]="'email'"
					inputTrimmed
				/>

				<!-- <email-check
					[form]="masterAccountForm"
					[controlName]="'email'"
					[isSuccessIfEmailExists]="true"
					[tooltipNameForSuccess]="'Account exists'"
					[tooltipNameForFailure]="'Account does not exist'"
					matSuffix
				></email-check> -->

				<mat-error *ngIf="masterAccountForm?.get('email')?.hasError('required')">
					Please provide investor's email
				</mat-error>

				<!-- <mat-error *ngIf="masterAccountForm?.get('email')?.hasError('email')"> Email is incorrect </mat-error>

				<mat-error *ngIf="masterAccountForm?.get('email')?.hasError('maxlength')">
					Email has to be up to 75 characters long
				</mat-error>

				<mat-error *ngIf="masterAccountForm?.get('email')?.hasError('isSameEmail')">
					You already logged in under this email
				</mat-error> -->
			</mat-form-field>
		</mat-dialog-content>

		<mat-dialog-actions class="dialog-form__actions">
			<button
				mat-button
				type="submit"
				(click)="loginAsAnotherAccount()"
				[disabled]="
					(isSpinnerVisible$ | async) ||
					masterAccountForm.invalid
				"
				class="button-full-width button-main"
				[ngClass]="{
					'button-low': (isBreakpointHeightSmall$ | async),
					'button-high': !(isBreakpointHeightSmall$ | async)
				}"
			>
				<div *ngIf="!(isSpinnerVisible$ | async); else isSpinnerVisible">Re-login</div>

				<ng-template #isSpinnerVisible>
					<mat-progress-spinner
						*ngIf="isSpinnerVisible$ | async"
						[diameter]="24"
						[mode]="'indeterminate'"
						class="actions__submit-spinner"
					>
					</mat-progress-spinner>

					<div>Submiting...</div>
				</ng-template>
			</button>
		</mat-dialog-actions>
	</form>
</mat-card>
