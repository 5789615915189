<div *ngIf="account && selectedWallet">
	<div class="wallet-select-container" *ngIf="!accountLoadingState && !selectedWalletLoadingState; else isSpinnerVisible">
		<mat-select
			disableOptionCentering
			placeholder="Select a wallet"
			[value]="account.wallets[0].walletName"
			class="wallet-select-container__select"
			panelClass="wallet-select-panel-class"
		>
			<mat-select-trigger class="mat-select-trigger-wallet-select mat-select-trigger-text-light-gray">
        <div class="mat-select-trigger-content">
          <div class="wallet-icon-container">
            <!-- <img src="../../../../assets/icons/other/wallet-icon.svg" alt="wallet-icon" class="square-icon-image" /> -->

            <ng-container *ngIf="selectedWallet.walletSummary.id === account.mainWallet && account.wallets.length !== 1; else isNotMainWallet">
              <img src="../../../../assets/icons/other/wallet-main-icon.svg" alt="main-wallet-icon" class="square-icon-image" />
            </ng-container>

            <ng-template #isNotMainWallet>
              <img src="../../../../assets/icons/other/wallet-icon.svg" alt="main-wallet-icon" class="square-icon-image" />
            </ng-template>
          </div>

          <div
            class="select__wallet-name"
          >
            {{ selectedWallet.walletSummary.walletName }}
          </div>
        </div>
			</mat-select-trigger>

			<mat-option
				*ngFor="let wallet of account.wallets"
				[value]="wallet.walletName"
				(click)="changeActiveWallet(wallet.id)"
				[routerLink]="['/dashboard', wallet.id]"
				routerLinkActive="routerLinkActive"
				class="select__option"
			>
        <div class="wallet-option-content">
          <div class="option-icon-container">
            <ng-container *ngIf="wallet.id === account.mainWallet && account.wallets.length !== 1; else isNotMainWallet">
              <img src="../../../../assets/icons/other/wallet-main-icon.svg" alt="main-wallet-icon" class="square-icon-image" />
            </ng-container>

            <ng-template #isNotMainWallet>
              <img src="../../../../assets/icons/other/wallet-icon.svg" alt="main-wallet-icon" class="square-icon-image" />
            </ng-template>
          </div>

          <div class="select__wallet-name">{{ wallet.walletName }}</div>
        </div>
			</mat-option>
		</mat-select>
	</div>

	<ng-template #isSpinnerVisible>
		<div>
			<mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
		</div>
	</ng-template>
</div>
