import { Component, OnInit, ChangeDetectionStrategy, ViewChild, OnDestroy } from '@angular/core';
import { WalletStateData, UserReferral } from '../../../models/wallet.models';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { DataService } from '../../../core/services/data.service';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { AccountInfoData } from '../../../models/account.models';
import { Store } from '@ngxs/store';
import { WalletState } from '../../../core/store/states/wallet.states';
import { AccountState } from '../../../core/store/states/account.states';
import { MatSort, Sort } from '@angular/material/sort';
import { ClipboardService } from 'ngx-clipboard';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'referral',
	templateUrl: './referral.component.html',
	styleUrls: ['./referral.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralComponent implements OnInit, OnDestroy {
	public account$: Observable<AccountInfoData> | null = null;
  public accountLoadingState$: Observable<boolean> | null = null;
	public selectedWallet$: Observable<WalletStateData> | null = null;
  public selectedWalletLoadingState$: Observable<boolean> | null = null;

	@ViewChild(MatTable) table: MatTable<unknown> | null = null;
	@ViewChild(MatSort) sort: MatSort | null = null;

	public columnsToDisplay = ['date', 'id', 'contractAmount', 'tagionPrice', 'tagions', 'referralFee'];
	public dataSource!: MatTableDataSource<UserReferral>;

	public mockData: string | null = null;

	public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;

	private _userReferralList: UserReferral[] = [];
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		public dataService: DataService,
		private _breakpointService: BreakpointService,
		private _clipboardService: ClipboardService,
		private _snackBarService: SnackBarService,
    private _store: Store,
	) {}

	ngOnInit(): void {
    this._initializeDataFromStore();

		this._getInitialSortedData().subscribe();

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();

		this.mockData = this.dataService.mockData;

		this.dataService.setDashboardLastRoute('referrals');
	}

	ngAfterViewInit(): void {
		if (this.table) {
			(this.dataSource as MatTableDataSource<UserReferral>).sort = this.sort;

			this.table.dataSource = this.dataSource as MatTableDataSource<UserReferral>;
		}
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	// public openReferralForm(): void {
	// 	this._dialogService.open(ReferralFormComponent);
	// }

	public getTotalContractAmount(userReferrals: UserReferral[] | undefined): number | string {
		return userReferrals
			? userReferrals
					.map((userReferral: UserReferral) => userReferral?.amountInOriginalCurrency)
					.reduce((acc, value) => acc + value, 0) ?? this.mockData
			: this.mockData as string;
	}

	public getTotalTagionsAmount(userReferrals: UserReferral[] | undefined): number | string {
		return userReferrals
			? userReferrals
					.map((userReferral: UserReferral) => userReferral?.amountInTagions)
					.reduce((acc, value) => acc + value, 0) ?? this.mockData
			: this.mockData as string;
	}

	public getTotalReferralFeeAmount(userReferrals: UserReferral[] | undefined): number | string {
		return userReferrals
			? userReferrals
					.map((userReferral: UserReferral) => userReferral?.feeReceived)
					.reduce((acc, value) => acc + value, 0) ?? this.mockData
			: this.mockData as string;
	}

	public sortData(sort: Sort): UserReferral[] {
		const notSortedData = [...this._userReferralList];

		if (!sort.active || sort.direction === '') {
			(this.dataSource as MatTableDataSource<UserReferral>).data = notSortedData;

			return notSortedData;
		}

		return ((this.dataSource as MatTableDataSource<UserReferral>).data = notSortedData.sort((a: UserReferral, b: UserReferral) => {
			const isAsc = sort.direction === 'asc';

			switch (sort.active) {
				case 'date':
					return this._compareData(a.timeInvestmentMade, b.timeInvestmentMade, isAsc);
				case 'id':
					return this._compareData(a.id, b.id, isAsc);
				case 'contractAmount':
					return this._compareData(a.amountInOriginalCurrency, b.amountInOriginalCurrency, isAsc);
				case 'tagionPrice':
					return this._compareData(a.contractualTagionPrice, b.contractualTagionPrice, isAsc);
				case 'tagions':
					return this._compareData(a.amountInTagions, b.amountInTagions, isAsc);
				case 'referralFee':
					return this._compareData(a.feeReceived, b.feeReceived, isAsc);
				default:
					return 0;
			}
		}));
	}

	public copyAnonReferralLinkToClipboard() {
		(this.account$ as Observable<AccountInfoData>)
			.pipe(
				map((account: AccountInfoData) => account.id),
				// tap((accountId: string) => console.log(`${environment.webUri}/invest/${accountId}`)),
				tap((accountId: string) => this._clipboardService.copy(`${environment.webUri}/invest/${accountId}`)),
				tap(() => this._snackBarService.openInfoSnackBar('Text has been copied to clipboard')),
				takeUntil(this._onDestroy$),
			)
			.subscribe();
	}

	private _getInitialSortedData(): Observable<UserReferral[]> {
		return (this.selectedWallet$ as Observable<WalletStateData>).pipe(
			map((walletData: WalletStateData) => walletData.userReferrals),
			tap((userReferralList: UserReferral[]) => {
				this.dataSource = new MatTableDataSource(userReferralList);
				this._userReferralList = [...userReferralList];
				this.dataSource.data = this.sortData({ active: 'date', direction: 'desc' });
			}),
		);
	}

	private _compareData(a: string | number, b: string | number, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

  private _initializeDataFromStore(): void {
    this.account$ = this._store.select(AccountState.getAccount);
    this.accountLoadingState$ = this._store.select(AccountState.getAccountLoadingState);
    this.selectedWallet$ = this._store.select(WalletState.getSelectedWallet);
    this.selectedWalletLoadingState$ = this._store.select(WalletState.getSelectedWalletLoadingState);
  }

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
  }
}
