import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { DataService } from '../../../core/services/data.service';
import { WalletStateData, WalletSummary } from '../../../models/wallet.models';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'balance',
	templateUrl: './balance.component.html',
	styleUrls: ['./balance.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceComponent implements OnInit, OnDestroy {
	@Input() selectedWallet: WalletStateData | null = null;
	@Input() selectedWalletLoadingState: boolean | null = null;

	public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;

	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(public dataService: DataService, private _breakpointService: BreakpointService) {}

	ngOnInit(): void {
    this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public calculateTotalCurrencyAmount(walletSummary: WalletSummary | undefined | null): number | string {
		if (walletSummary) {
			if (walletSummary?.totalTagions >= 0 && walletSummary?.currentTagionPrice >= 0) {
				return walletSummary?.totalTagions * walletSummary?.currentTagionPrice;
			}
		}

		return this.dataService.mockData;
	}

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
  }
}
