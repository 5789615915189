import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BehaviorSubject, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { WalletState } from '../../../core/store/states/wallet.states';
import { WalletStateData } from '../../../models/wallet.models';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from '../../../validators/validators';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { environment } from '../../../../environments/environment';
import { AccountState } from '../../../core/store/states/account.states';
import { AccountInfoData } from '../../../models/account.models';

@Component({
	selector: 'become-ambassador-form',
	templateUrl: './become-ambassador-form.component.html',
	styleUrls: ['./become-ambassador-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BecomeAmbassadorFormComponent implements OnInit {
	public account$: Observable<AccountInfoData> | null = null;
	public selectedWallet$: Observable<WalletStateData> | null = null;

	public becomeAmbassadorForm!: FormGroup;
	public isSpinnerVisible$: Observable<boolean> = of(false);

  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;
  public isBreakpointHeightSmall$: Observable<boolean> | null = null;

	private _spinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		private _formBuilder: FormBuilder,
		private _dialogRef: MatDialogRef<BecomeAmbassadorFormComponent>,
		private _breakpointService: BreakpointService,
    private _store: Store,
	) {}

	ngOnInit(): void {
    this._initializeDataFromStore();

		this.isSpinnerVisible$ = this.getSpinnerStatus$();
		this._initBecomeAmbassadorForm();

    this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public getMailToConfig(): Observable<string | null> {
		const mailToSubject = (accountId: number) =>
			`Tagion Portal - Become an Ambassador request by ${accountId}. (Please do not change this subject)`;

		const mailToBody =
			'Please send this email to finish the applying process. If you would like to add any information that may help our team please leave it below.';

		return (this.account$ as Observable<AccountInfoData>).pipe(
			map((accountData: AccountInfoData) => accountData?.crmId),
			map((accountId: number) =>
				accountId
					? `mailto:${environment.investorRelationsEmail}?Subject=${mailToSubject(accountId)}&body=${mailToBody}`
					: null,
			),
		);
	}

	public setSpinnerStatus(spinnerStatus: boolean): void {
		this._spinner$.next(spinnerStatus);
	}
	public getSpinnerStatus$(): Observable<boolean> {
		return this._spinner$.asObservable();
	}

	public closeDialogWindow(): void {
		this._dialogRef.close();
	}

	private _initBecomeAmbassadorForm(): void {
		this.becomeAmbassadorForm = this._formBuilder.group({
			euroAmount: [null, [Validators.required, CustomValidators.isNumberValidator.bind(this), Validators.min(1000)]],
		});
	}

  private _initializeDataFromStore(): void {
    this.account$ = this._store.select(AccountState.getAccount);
    this.selectedWallet$ = this._store.select(WalletState.getSelectedWallet);
  }

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
    this.isBreakpointHeightSmall$ = this._breakpointService.getHeightSmallBreakpointStatus$();
  }
}
