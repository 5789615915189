<div class="contact-request-submitted-dialog">
	<div class="dialog">
		<div class="dialog__content">
			<div class="icon">
				<img src="../../../../assets/icons/regular/tick-circle.svg" alt="tick" />
			</div>
			<h3 class="heading">Request sent successfully</h3>
			<h4 class="ticket-number">
				Your ticket number is <strong>{{ dataService.getSupportTicketNumber$() | async }}</strong>
			</h4>
			<div class="text">Our support team will review your request and respond to your email</div>
		</div>

		<div class="dialog__actions">
			<button
				mat-button
				type="button"
				(click)="closeModal()"
				class="button-full-width button-main"
				[ngClass]="{
					'button-low':
						(isBreakpointXS$ | async) ||
						(isBreakpointSM$ | async) ||
						((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
					'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
				}"
			>
				<div>Close</div>
			</button>
		</div>
	</div>
</div>
