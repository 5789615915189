import { NgModule } from '@angular/core';
import { PayoutDevComponent } from './payout-dev.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';
import { PayoutRequestFormModule } from '../../dumb/payout-request-form/payout-request-form.module';
import { RouterModule } from '@angular/router';

const componentModules = [PayoutRequestFormModule];

@NgModule({
	declarations: [PayoutDevComponent],
	imports: [SharedModule, MaterialModule, RouterModule, ...componentModules],
	exports: [PayoutDevComponent],
})
export class PayoutDevModule {}
