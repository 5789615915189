import { PayoutDevComponent } from './components/no-auth/payout-dev/payout-dev.component';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Route } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { RegistrationComponent } from './components/no-auth/registration/registration.component';
import { ErrorNotFoundComponent } from './components/error-not-found/error-not-found.component';
import { SignInComponent } from './components/no-auth/sign-in/sign-in.component';
import { CheckInboxComponent } from './components/no-auth/check-inbox/check-inbox.component';
import { PurchasesComponent } from './components/dashboard/purchases/purchases.component';
import { ReferralComponent } from './components/dashboard/referral/referral.component';
import { InvitationsComponent } from './components/dashboard/invitations/invitations.component';
import { PayoutComponent } from './components/dashboard/payout/payout.component';
import { AnonReferralComponent } from './components/no-auth/anon-referral/anon-referral.component';

export const appRoutes: Route[] = [
	// ------------------- No-auth routes (guest) --------------------
	{
		path: 'sign-in',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: SignInComponent,
		data: {
			title: 'Sign In',
		},
	},
	{
		path: 'check-inbox',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: CheckInboxComponent,
		data: {
			title: 'Check Inbox',
		},
	},
	{
		path: 'registration',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: RegistrationComponent,
		data: {
			title: 'Registration',
		},
	},
	{
		path: 'payout-dev',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: PayoutDevComponent,
		data: {
			title: 'Payout Dev',
		},
	},

	// ----------------- Auth routes (signed in) -------------------
	{
		path: '',
		pathMatch: 'full',
		redirectTo: '/dashboard',
	},
	{
		path: 'dashboard',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: DashboardComponent,
	},
	{
		path: 'dashboard/:walletId',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: DashboardComponent,
		children: [
			{
				path: 'purchases',
				component: PurchasesComponent,
			},
			{
				path: 'referrals',
				component: ReferralComponent,
			},
			{
				path: 'invitations',
				component: InvitationsComponent,
			},
			{
				path: 'payout',
				component: PayoutComponent,
			},
			{
				path: '**',
				redirectTo: localStorage.getItem('dashboardLastRoute') || 'purchases',
			},
		],
	},

	// -------------------- Both Auth and No-auth routes -----------------
	{
		path: 'invest/:id',
		component: AnonReferralComponent,
		data: {
			title: 'Invest in Tagion',
		},
	},
	// TODO: commented it out because of problems with showing 404 page on small height screens. Fix later
	// {
	// 	path: '404-not-found',
	// 	component: ErrorNotFoundComponent,
	// 	data: {
	// 		title: 'Page Not Found',
	// 	},
	// },

	// everything else redirect to 404

	{ path: '**', redirectTo: '/dashboard' },
	// { path: '**', redirectTo: '404-not-found' },
];
