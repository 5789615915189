import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AccountInfoData, AccountStateModel, AccountInfo } from '../../../models/account.models';
import { Account } from '../actions/accout.actions';
import { AccountService } from '../../services/account.service';

@State<AccountStateModel>({
	name: 'accountState',
	defaults: {
		account: null as any, // to overcome type-null-is-not-assignable-to-specific-type error
		loading: false,
	},
})
@Injectable()
export class AccountState {
	constructor(private _accountService: AccountService) {}

	@Selector()
	static getAccount(state: AccountStateModel): AccountInfoData {
		return state.account;
	}

	@Selector()
	static getAccountLoadingState(state: AccountStateModel): boolean {
		return state.loading;
	}

	@Action(Account.Get)
	getAccount(stateContext: StateContext<AccountStateModel>): Observable<AccountInfoData> {
		stateContext.patchState({ loading: true });

		return this._accountService.getAccountInfo().pipe(
			map((accountInfo: AccountInfo) => accountInfo.data),
			tap((accountInfoData: AccountInfoData) => {
				const state = stateContext.getState();

				stateContext.setState({
					...state,
					account: accountInfoData,
					loading: false,
				});
			}),
		);
	}
}
