<ng-container *ngIf="!(accountLoadingState$ | async) && !(selectedWalletLoadingState$ | async); else isSpinnerVisible">
	<ng-container *ngIf="account$ | async as account">
		<ng-container *ngIf="selectedWallet$ | async as selectedWallet">
			<div class="invitations">
				<div class="invitations__heading">
					<div class="text">
						<h3>My Invitations</h3>
					</div>

					<div class="actions">
						<ng-container *ngIf="account.mainWallet === selectedWallet.walletSummary.id">
							<button
								type="button"
								mat-button
								(click)="openReferralForm()"
								class="actions__invite button-main"
								[ngClass]="{
									'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
									'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async),
									'button-padding-extra-tiny': (isBreakpointXS$ | async),
									'button-padding-tiny': (isBreakpointSM$ | async)
								}"
							>
								<div>Invite a friend</div>
							</button>
						</ng-container>

						<!-- TODO: regarding Ambassadors -->
						<!-- <ng-container
							*ngIf="account.investorType === InvestorType.investor && !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)"
						>
							<button
								type="button"
								mat-button
								(click)="openBecomeAmbassadorForm()"
								class="actions__invite button-main"
								[ngClass]="{
									'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
									'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async),
									'button-padding-extra-tiny': (isBreakpointXS$ | async),
									'button-padding-tiny': (isBreakpointSM$ | async)
								}"
							>
								<div>Become an Ambassador</div>
							</button>
						</ng-container> -->

						<button
							*ngIf="!(isBreakpointXS$ | async) && !(isBreakpointSM$ | async) && selectedWallet?.userInvitation?.length"
							type="button"
							mat-button
							(click)="refreshInvitationList()"
							class="actions__refresh button-secondary"
							[ngClass]="{
								'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
								'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async),
								'button-padding-extra-tiny': (isBreakpointXS$ | async),
								'button-padding-tiny': (isBreakpointSM$ | async)
							}"
						>
							<div>Refresh list</div>
						</button>
					</div>
				</div>

				<div class="invitations__transactions">
					<ng-container *ngIf="selectedWallet?.userInvitation?.length; else noInvitations">
						<table
							mat-table
							[dataSource]="dataSource.data"
							matSort
							(matSortChange)="sortData($event)"
							matSortActive="date"
							matSortDirection="desc"
							matSortDisableClear
							class="transactions-table"
						>
							<ng-container matColumnDef="date">
								<th
									mat-header-cell
									*matHeaderCellDef
									mat-sort-header
									[hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)"
								>
									Date
								</th>

								<ng-container *matCellDef="let oneUserInvitation">
									<td mat-cell [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
										{{ mockStringCheck(oneUserInvitation.createDate | date: 'longDate') }}
									</td>
								</ng-container>
							</ng-container>

							<ng-container matColumnDef="name">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>

								<ng-container *matCellDef="let oneUserInvitation">
									<ng-container *ngIf="!oneUserInvitation?.anonymous; else isAnonInvitation">
										<td mat-cell>
											{{ mockStringCheck(oneUserInvitation.referredUser) }}
										</td>
									</ng-container>
									<ng-template #isAnonInvitation>
										<td mat-cell>Hidden</td>
									</ng-template>
								</ng-container>
							</ng-container>

							<ng-container matColumnDef="email">
								<th
									mat-header-cell
									*matHeaderCellDef
									mat-sort-header
									[hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)"
								>
									Email
								</th>

								<ng-container *matCellDef="let oneUserInvitation">
									<ng-container *ngIf="!oneUserInvitation?.anonymous; else isAnonInvitation">
										<td mat-cell [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
											{{ mockStringCheck(oneUserInvitation.referredEmail) }}
										</td>
									</ng-container>
									<ng-template #isAnonInvitation>
										<td mat-cell [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">Hidden</td>
									</ng-template>
								</ng-container>
							</ng-container>

							<ng-container matColumnDef="status">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>

								<ng-container *matCellDef="let oneUserInvitation">
									<td mat-cell>
										<div class="status-wrapper">
											<div
												class="status"
												[ngClass]="getCssClassForInvitationStatus(oneUserInvitation)"
											>
												{{ getInvitationStatus(oneUserInvitation) }}
											</div>
										</div>
									</td>
								</ng-container>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
							<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
						</table>
					</ng-container>

					<ng-template #noInvitations>
						<div class="invitations__noTransactions">
							<div class="card-icon">
								<img src="../../../../assets/icons/regular/invite.svg" alt="invite" class="regular-icon-image" />
							</div>

							<div class="text">
								<div>You haven't invited anyone yet</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</ng-container>
	</ng-container>
</ng-container>

<ng-template #isSpinnerVisible>
	<div class="isSpinnerVisibleAtMenuItem">
		<mat-progress-spinner [diameter]="36" [mode]="'indeterminate'"></mat-progress-spinner>
	</div>
</ng-template>
