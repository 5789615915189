import { NgModule } from '@angular/core';
import { SharedModule } from '../../../modules/shared.module';
import { WalletNameComponent } from './wallet-name.component';
import { MaterialModule } from '../../../modules/material.module';

@NgModule({
	declarations: [WalletNameComponent],
	imports: [SharedModule, MaterialModule],
	exports: [WalletNameComponent],
})
export class WalletNameModule {}
