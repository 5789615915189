import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'walletAddress',
})
export class WalletAddressPipe implements PipeTransform {
	transform(walletAddress: string | null): string {
		if (!walletAddress) {
			return '';
		} else {
			return (
				walletAddress.slice(0, 5) + '...' + walletAddress.slice(walletAddress.length - 5, walletAddress.length)
			);
		}
	}
}
