import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { DataService } from '../../../core/services/data.service';
import { AccountInfoData } from '../../../models/account.models';
import { UserInvitation, WalletStateData } from '../../../models/wallet.models';
import { DialogService } from '../../../core/services/dialog.service';
import { Store } from '@ngxs/store';
import { Wallet } from '../../../core/store/actions/wallet.actions';
import { WalletState } from '../../../core/store/states/wallet.states';
import { AccountState } from '../../../core/store/states/account.states';
import { ReferralFormComponent } from '../../dumb/referral-form/referral-form.component';
import { MatSort, Sort } from '@angular/material/sort';
import { BecomeAmbassadorFormComponent } from '../../dumb/become-ambassador-form/become-ambassador-form.component';
import { InvitationStatusModel, ErrorDetails } from '../../../models/other.models';
import { InvitationStatusResponseCode } from '../../../enums/enums';

@Component({
	selector: 'invitations',
	templateUrl: './invitations.component.html',
	styleUrls: ['./invitations.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationsComponent implements OnInit, AfterViewInit, OnDestroy {
	public account$: Observable<AccountInfoData> | null = null;
  public accountLoadingState$: Observable<boolean> | null = null;
	public selectedWallet$: Observable<WalletStateData> | null = null;
  public selectedWalletLoadingState$: Observable<boolean> | null = null;

	@ViewChild(MatTable) table: MatTable<unknown> | null = null;
	@ViewChild(MatSort) sort: MatSort | null = null;

	public columnsToDisplay = ['date', 'name', 'email', 'status'];
	public dataSource!: MatTableDataSource<UserInvitation>;

	public mockData: string | null = null;

	public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;

	private _userInvitationList: UserInvitation[] = [];
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		private _dataService: DataService,
		private _breakpointService: BreakpointService,
		private _dialogService: DialogService,
		private _store: Store,
		private _router: Router,
	) {}

	ngOnInit(): void {
    this._initializeDataFromStore();

		this._getInitialSortedData().subscribe();

    this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();

		this.mockData = this._dataService.mockData;

		this._dataService.setDashboardLastRoute('invitations');
	}

	ngAfterViewInit(): void {
		if (this.table) {
			(this.dataSource as MatTableDataSource<UserInvitation>).sort = this.sort;

			this.table.dataSource = this.dataSource as MatTableDataSource<UserInvitation>;
		}
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public openReferralForm(): void {
		this._dialogService.open(ReferralFormComponent);
	}

	public openBecomeAmbassadorForm(): void {
		this._dialogService.open(BecomeAmbassadorFormComponent);
	}

	public mockStringCheck(property: string | undefined | null): string {
		return property ?? this.mockData as string;
	}

	public getInvitationsAmount(userInvitations: UserInvitation[] | undefined): number | string {
		return userInvitations ? userInvitations.length : this.mockData as string;
	}

	public getInvitationStatus(oneUserInvitation: UserInvitation): string {
    const dataServiceInvitationStatusItem: InvitationStatusModel | undefined = this._dataService.invitationStatus.find((invitationStatusItem: InvitationStatusModel) => {
      return oneUserInvitation.importStatus === invitationStatusItem.responseCode;
    });

    let response: string | undefined | null = null;

    // for "Pending" and "Success" responses
    if (oneUserInvitation.importStatus !== InvitationStatusResponseCode.error) {
      response = dataServiceInvitationStatusItem?.statusForUser;
		} else {
      const errorDetailsItem: ErrorDetails | undefined = dataServiceInvitationStatusItem?.errorDetailsFromBackend.find((errorDetailsFromBackendItem: ErrorDetails) => {
        return errorDetailsFromBackendItem.errorFromBackend?.includes(oneUserInvitation.errorDetails);
      });

      if (errorDetailsItem) {
        response = errorDetailsItem.errorDetailsForUser;
      } else {
        response = dataServiceInvitationStatusItem?.statusForUser;
      }
    }

    return response || this.mockData as string;
	}

	public getCssClassForInvitationStatus(oneUserInvitation: UserInvitation) {
    const dataServiceInvitationStatusItem: InvitationStatusModel | undefined = this._dataService.invitationStatus.find((invitationStatusItem: InvitationStatusModel) => {
      return oneUserInvitation.importStatus === invitationStatusItem.responseCode;
    });

    return dataServiceInvitationStatusItem?.cssStatus;
	}

	public refreshInvitationList(): void {
		(this.selectedWallet$ as Observable<WalletStateData>)
			.pipe(
				tap((selectedWallet: WalletStateData) => {
					this._store.dispatch(new Wallet.GetOne(selectedWallet.walletSummary.id));
					this._router.navigateByUrl(`/dashboard/${selectedWallet.walletSummary.id}/invitations`);
				}),
				take(1),
				takeUntil(this._onDestroy$),
			)
			.subscribe();
	}

	public sortData(sort: Sort): UserInvitation[] {
		const notSortedData = [...this._userInvitationList];

		if (!sort.active || sort.direction === '') {
			(this.dataSource as MatTableDataSource<UserInvitation>).data = notSortedData;

			return notSortedData;
		}

		return ((this.dataSource as MatTableDataSource<UserInvitation>).data = notSortedData.sort((a: UserInvitation, b: UserInvitation) => {
			const isAsc = sort.direction === 'asc';

			switch (sort.active) {
				case 'date':
					return this._compareData(a.createDate, b.createDate, isAsc);
				case 'name':
					return this._compareData(a.referredUser, b.referredUser, isAsc);
				case 'email':
					return this._compareData(a.referredEmail, b.referredEmail, isAsc);
				case 'status':
					return this._compareData(a.importStatus, b.importStatus, isAsc);
				default:
					return 0;
			}
		}));
	}

	private _getInitialSortedData(): Observable<UserInvitation[]> {
		return (this.selectedWallet$ as Observable<WalletStateData>).pipe(
			map((walletData: WalletStateData) => walletData.userInvitation),
			tap((userInvitationList: UserInvitation[]) => {
				this.dataSource = new MatTableDataSource(userInvitationList);
				this._userInvitationList = [...userInvitationList];
				this.dataSource.data = this.sortData({ active: 'date', direction: 'desc' });
			}),
		);
	}

	private _compareData(a: string | number, b: string | number, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

  private _initializeDataFromStore(): void {
    this.account$ = this._store.select(AccountState.getAccount);
    this.accountLoadingState$ = this._store.select(AccountState.getAccountLoadingState);
    this.selectedWallet$ = this._store.select(WalletState.getSelectedWallet);
    this.selectedWalletLoadingState$ = this._store.select(WalletState.getSelectedWalletLoadingState);
  }

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
  }
}
