import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NetworkService } from '../services/network.service';
import { SnackBarService } from '../services/snack-bar.service';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private _networkService: NetworkService,
		private _snackBarService: SnackBarService,
		private _authService: AuthService,
	) {}

	// TODO: split on two interceptors - for user and org
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let newReq = req.clone();

		// If it's not our API - skip the interceptor
		if (!newReq.url.includes(environment.apiUri)) {
			return next.handle(newReq);
		}

		// Request
		//
		// If the access token didn't expire, add the Authorization header.
		// We won't add the Authorization header if the access token expired.
		// This will force the server to return a "401 Unauthorized" response
		// for the protected API routes which our response interceptor will
		// catch and delete the access token from the local storage while logging
		// the user out from the app.

		if (localStorage.getItem('saftAuthToken-masterAccess')) {
			const newHeaders = newReq.headers.set('Authorization', `${localStorage.getItem('saftAuthToken-masterAccess')}`);
			newReq = newReq.clone({
				headers: newHeaders,
			});
		} else if (localStorage.getItem('saftAuthToken')) {
			const newHeaders = newReq.headers.set('Authorization', `${localStorage.getItem('saftAuthToken')}`);
			newReq = newReq.clone({
				headers: newHeaders,
			});
		}

		// to set _isSignedIn$ value (subject in auth service) each time when request happens so header, error comp etc will be updated correctly
		this._authService.setSignInStatus();

		// Response
		return next
			.handle(newReq)
			.pipe
			// catchError((error: any) => {
			// 	// TODO: Parse and differentiate between invalid token and
			// 	// just unauthorized route. Do not sign out blindly
			// 	// Catch "401 Unauthorized" responses
			// 	// if (error instanceof HttpErrorResponse && error.status === 401) {
			// 	// 	// Sign out
			// 	// 	this._authService.signOut();

			// 	// 	// Reload the app
			// 	// 	location.reload();
			// 	// }

			// 	throw Error(error.error.mesage);
			// }),
			();
	}
}
