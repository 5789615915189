<div class="check-inbox">
	<mat-card appearance="outlined" class="check-inbox__card-wrapper">
		<div class="check-inbox-card">
			<div class="check-inbox-card__section-one">
				<div class="back">
					<button type="button" mat-button [routerLink]="'/sign-in'" class="back__button button-low button-back">
						<mat-icon aria-hidden="true" aria-label="Back" class="material-icons md-gray-darkest md-18">
							arrow_back
						</mat-icon>
						<div>Back</div>
					</button>
				</div>
			</div>

			<div class="check-inbox-card__section-two">
				<h3 class="title">Check your inbox</h3>

				<div class="text">
					<div class="top-text">
						<div class="top-text__text">Link has been sent to</div>
						<div class="top-text__email">
							<strong> {{ email }} </strong>
						</div>
					</div>

					<div class="bottom-text">Follow the link to sign in</div>
				</div>
			</div>

			<div class="check-inbox-card__section-three">
				<div class="footer">
					<div class="footer__no-code button-low">Haven't receive your link?</div>

					<div class="footer__send-again">
						<button
							mat-button
							type="submit"
							(click)="sendLinkAgain()"
							[disabled]="isSpinnerVisible$ | async"
							class="button-low button-underlined"
							[ngClass]="{
								'button-narrow': (isBreakpointXS$ | async),
								'button-wide': !(isBreakpointXS$ | async)
							}"
						>
							<div *ngIf="!(isSpinnerVisible$ | async); else isSpinnerVisible" class="button-text">Send again</div>

							<ng-template #isSpinnerVisible>
								<mat-progress-spinner [diameter]="18" [mode]="'indeterminate'" class="button-spinner">
								</mat-progress-spinner>

								<div>Sending...</div>
							</ng-template>
						</button>
					</div>
				</div>
			</div>
		</div>
	</mat-card>
</div>
