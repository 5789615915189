import { NgModule } from '@angular/core';
import { PayoutComponent } from './payout.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';
import { PayoutRequestFormModule } from '../../dumb/payout-request-form/payout-request-form.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { ReferralFormModule } from '../../dumb/referral-form/referral-form.module';

const componentModules = [PayoutRequestFormModule, ReferralFormModule];

@NgModule({
	declarations: [PayoutComponent],
	imports: [SharedModule, MaterialModule, PipesModule, ...componentModules],
	exports: [PayoutComponent],
})
export class PayoutModule {}
