<ng-container *ngIf="!(accountLoadingState$ | async) && !(selectedWalletLoadingState$ | async); else isSpinnerVisible">
	<ng-container *ngIf="account$ | async as account">
		<ng-container *ngIf="selectedWallet$ | async as selectedWallet">
			<div class="purchases">
				<div class="purchases__heading">
					<div class="text">
						<h3>My Purchases</h3>
					</div>

					<div class="actions">
						<button
							type="button"
							mat-button
							(click)="openBuyTagionsForm()"
							class="actions__request-purchase button-main"
							[ngClass]="{
								'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
								'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async),
								'button-padding-extra-tiny': (isBreakpointXS$ | async),
								'button-padding-tiny': (isBreakpointSM$ | async)
							}"
						>
							<div>Purchase more tokens</div>
						</button>

						<!-- <ng-container *ngIf="account.mainWallet === selectedWallet.walletSummary.id">
							<button
								*ngIf="!(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)"
								type="button"
								mat-button
								(click)="openReferralForm()"
								class="button-main"
								[ngClass]="{
									'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
									'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async),
									'button-padding-extra-tiny': (isBreakpointXS$ | async),
									'button-padding-tiny': (isBreakpointSM$ | async)
								}"
							>
								<div>Invite a friend</div>
							</button>
						</ng-container> -->
					</div>
				</div>

				<div class="purchases__transactions">
					<ng-container *ngIf="selectedWallet?.userTransactions?.length; else noTransactions">
						<table
							mat-table
							[dataSource]="dataSource.data"
							matSort
							(matSortChange)="sortData($event)"
							matSortActive="date"
							matSortDirection="desc"
							matSortDisableClear
							class="transactions-table"
						>
							<ng-container matColumnDef="date">
								<th
									mat-header-cell
									*matHeaderCellDef
									mat-sort-header
									[hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)"
								>
									Date
								</th>

								<ng-container *matCellDef="let userTransaction">
									<td mat-cell [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
										{{ dataService.mockStringCheck(userTransaction.timeInvestmentMade | date: 'longDate') }}
									</td>
								</ng-container>

								<td mat-footer-cell *matFooterCellDef [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
									Total
								</td>
							</ng-container>

							<ng-container matColumnDef="investmentName">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>Investment Name</th>

								<ng-container *matCellDef="let userTransaction">
									<td mat-cell>
										{{ dataService.mockStringCheck(userTransaction.investmentName) }}
									</td>
								</ng-container>

								<ng-container>
									<ng-container *ngIf="isBreakpointXS$ | async; else isBreakpointNotXS">
										<td mat-footer-cell *matFooterCellDef>Total</td>
									</ng-container>

									<ng-template #isBreakpointNotXS>
										<td mat-footer-cell *matFooterCellDef></td>
									</ng-template>
								</ng-container>
							</ng-container>

							<ng-container matColumnDef="contractAmount">
								<th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="isBreakpointXS$ | async">
									Contract Amount
								</th>

								<ng-container *matCellDef="let userTransaction">
									<td mat-cell [hidden]="isBreakpointXS$ | async">
										{{ dataService.mockNumberCheck(userTransaction.amountInOriginalCurrency) | euro2Decimals }}
									</td>
								</ng-container>

								<td mat-footer-cell *matFooterCellDef [hidden]="isBreakpointXS$ | async">
									{{ getTotalContractAmount(selectedWallet?.userTransactions) | euro2Decimals }}
								</td>
							</ng-container>

							<ng-container matColumnDef="tagionPrice">
								<th
									mat-header-cell
									*matHeaderCellDef
									mat-sort-header
									[hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async) || (isBreakpointMD$ | async)"
								>
									Tagion Price
								</th>

								<ng-container *matCellDef="let userTransaction">
									<td
										mat-cell
										[hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async) || (isBreakpointMD$ | async)"
									>
										{{ dataService.mockNumberCheck(userTransaction.contractualTagionPrice) | euro4Decimals }}
									</td>
								</ng-container>

								<td
									mat-footer-cell
									*matFooterCellDef
									[hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async) || (isBreakpointMD$ | async)"
								></td>
							</ng-container>

							<ng-container matColumnDef="tagions">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>Tagion Amount</th>

								<ng-container *matCellDef="let userTransaction">
									<td mat-cell>
										{{ dataService.mockNumberCheck(userTransaction.amountInTagions) | tagion }}
									</td>
								</ng-container>

								<td mat-footer-cell *matFooterCellDef>
									{{ getTotalTagionsAmount(selectedWallet?.userTransactions) | tagion }}
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
							<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
							<tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
						</table>
					</ng-container>

					<ng-template #noTransactions>
						<div class="purchases__noTransactions">
							<div class="card-icon">
								<img src="../../../../assets/icons/regular/purchase.svg" alt="purchase" class="regular-icon-image" />
							</div>

							<div class="text">
								<div>You have no purchases yet</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</ng-container>
	</ng-container>
</ng-container>

<ng-template #isSpinnerVisible>
	<div class="isSpinnerVisibleAtMenuItem">
		<mat-progress-spinner [diameter]="36" [mode]="'indeterminate'"></mat-progress-spinner>
	</div>
</ng-template>
