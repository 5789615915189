import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { AccountInfo, TagionPriceResponse } from '../../models/account.models';
import { BooleanResponse, TokenResponse } from '../../models/referral.model';
import { filterOutNullishValues } from '../auth/auth.utils';
import { NetworkService } from './network.service';
import { SnackBarService } from './snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class AccountService implements OnDestroy {
	private _account = '/account';
	private _accountExists = (accountId: string) => `/exists?accountId=${accountId}`;
	private _emailExists = (investorEmail: string) => `/emailExists?email=${investorEmail}`;
	private _adminToken = (investorEmail: string) => `/admintoken?mail=${investorEmail}`;
	private _price = '/price';

	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		private _networkService: NetworkService,
		private _router: Router,
		private _snackBarService: SnackBarService,
	) {}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public getAccountInfo(): Observable<AccountInfo> {
		return this._networkService.get(this._account).pipe(filterOutNullishValues(), takeUntil(this._onDestroy$));
	}

	public checkIfAccountIdExistInCrm(accountId: string): Observable<boolean | Error> {
		return this._networkService.get(this._accountExists(accountId)).pipe(
			map((response: BooleanResponse | null) => {
				if (!response) {
					this._router.navigate([`/sign-in`]);

					throw Error('Wrong Account ID format');
				}
				if (!response.data) {
					this._router.navigate([`/sign-in`]);

					throw Error('Wrong Account ID format');
				}
				return true;
			}),
			takeUntil(this._onDestroy$),
			catchError((error: Error) => {
				if (error.message === 'Wrong Account ID format') {
					this._snackBarService.openAuthErrorSnackBar('Page link is broken');
				} else {
					this._snackBarService.openAuthErrorSnackBar('Something went wrong');
				}

				return of(error);
			}),
		);
	}

	public checkIfEmailExistsInCrm(investorEmail: string): Observable<boolean | Error> {
		const encodedEmail = encodeURIComponent(investorEmail); // otherwise payload cuts off '+' sign

		return this._networkService.get(this._emailExists(encodedEmail)).pipe(
			map((response: BooleanResponse) => {
				if (response && response?.isSuceceded) {
					if (response.data) {
						// this._snackBarService.openErrorSnackBar('User has been invited already');

						return true;
					} else {
						return false;
					}
				} else {
					throw Error('Something went wrong');
				}
			}),
			takeUntil(this._onDestroy$),
			catchError((error: Error) => {
				if (error.message === 'Something went wrong') {
					this._snackBarService.openAuthErrorSnackBar('Something went wrong');
				}

				return of(error);
			}),
		);
	}

	public getMasterToken(investorEmail: string): Observable<string | null> {
		const encodedEmail = encodeURIComponent(investorEmail); // otherwise payload cuts off '+' sign

		return this._networkService.get(this._adminToken(encodedEmail)).pipe(
			map((response: TokenResponse) => (response && response?.data?.token ? response.data.token : null)),
			takeUntil(this._onDestroy$),
		);
	}

	public getTagionPrice(): Observable<number | null> {
		return this._networkService.get(this._price).pipe(
			filterOutNullishValues(),
			map((response: TagionPriceResponse) => {
				if (response && response?.isSuceceded) {
					return response.data;
				}

				return null;
			}),
			takeUntil(this._onDestroy$),
		);
	}
}
