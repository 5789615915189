import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { AccountState } from '../../core/store/states/account.states';
import { Observable, Subject, of } from 'rxjs';
import { AccountInfoData } from '../../models/account.models';
import { WalletState } from '../../core/store/states/wallet.states';
import { UserInvitation, UserReferral, UserTransaction, WalletStateData } from '../../models/wallet.models';
import { Wallet } from '../../core/store/actions/wallet.actions';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { filterOutNullishValues } from '../../core/auth/auth.utils';
import { Router } from '@angular/router';
import { BreakpointService } from '../../core/services/breakpoint.service';
import { DataService } from '../../core/services/data.service';
import { Account } from '../../core/store/actions/accout.actions';
import { AuthService } from '../../core/auth/auth.service';

@Component({
	selector: 'dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit, OnDestroy {
	public account$: Observable<AccountInfoData> | null = null;
  public accountLoadingState$: Observable<boolean> | null = null;
	public selectedWallet$: Observable<WalletStateData> | null = null;
  public selectedWalletLoadingState$: Observable<boolean> | null = null;

	public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;

	public mockData: string | null = null;

	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		private _store: Store,
		private _breakpointService: BreakpointService,
		private _router: Router,
		private dataService: DataService,
		private _authService: AuthService,
		private _dataService: DataService,
	) {}

	ngOnInit(): void {
		this.checkIfUserHasMasterAccess();

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();

    of(this._initializeDataFromStore()).pipe(
      switchMap(() => this._store.dispatch(new Account.Get())?.pipe(
        switchMap(() => this._getMainWalletId()),
        switchMap((mainWalletId: string) => this._store.dispatch(new Wallet.GetOne(mainWalletId))),
      ))
    ).subscribe();

		this.mockData = this.dataService.mockData;
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public checkIfUserHasMasterAccess(): void {
		this._authService
			.checkIfUserHasMasterAccess$().pipe(
				tap((isMasterAccount: boolean) => this._dataService.setMasterAccount(isMasterAccount)),
				takeUntil(this._onDestroy$),
			)
			.subscribe();
	}

	public getTotalTagionsAmount(userTransactions: UserTransaction[] | undefined): number | string {
		return userTransactions
			? userTransactions
					.map((userTransaction: UserTransaction) => userTransaction?.amountInTagions)
					.reduce((acc, value) => acc + value, 0) ?? this.mockData
			: (this.mockData as string);
	}

	public getTotalReferralFeeAmount(userReferrals: UserReferral[] | undefined): number | string {
		return userReferrals
			? userReferrals
					.map((userReferral: UserReferral) => userReferral?.feeReceived)
					.reduce((acc, value) => acc + value, 0) ?? this.mockData
			: (this.mockData as string);
	}

	public getInvitationsAmount(userInvitations: UserInvitation[] | undefined): number | string {
		return userInvitations ? userInvitations.length : (this.mockData as string);
	}

  public getPayoutsAmount(selectedWallet: WalletStateData | null | undefined): number | string {
    return selectedWallet ? selectedWallet.walletSummary.pendingRequestPayouts + selectedWallet.walletSummary.vestedTagions : (this.mockData as string);
  }

	// private _getWalletInfo(): Observable<WalletStateData> {
	// 	return this.account$.pipe(
	// 		filterOutNullishValues(),
	// 		map((accountInfoData: AccountInfoData) => accountInfoData.wallets?.[0].id),
	// 		filterOutNullishValues(),
	// 		tap((firstWalletId: string) => this._router.navigate([`/dashboard/${firstWalletId}/purchases`])),
	// 		switchMap((firstWalletId: string) => this._store.dispatch(new Wallet.GetOne(firstWalletId))),
	// 		takeUntil(this._onDestroy$),
	// 	);
	// }

  private _initializeDataFromStore(): void {
    this.account$ = this._store.select(AccountState.getAccount);
    this.accountLoadingState$ = this._store.select(AccountState.getAccountLoadingState);
    this.selectedWallet$ = this._store.select(WalletState.getSelectedWallet);
    this.selectedWalletLoadingState$ = this._store.select(WalletState.getSelectedWalletLoadingState);
  }

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
  }

	private _getMainWalletId(): Observable<string> {
		return (this.account$ as Observable<AccountInfoData>).pipe(
			filterOutNullishValues(),
			map((accountInfoData: AccountInfoData) => accountInfoData.mainWallet),
			filterOutNullishValues(),
			map((mainWalletId: string) => {
        this._router.navigate([
					`/dashboard/${mainWalletId}/${localStorage.getItem('dashboardLastRoute') || 'purchases'}`,
				]);

        return mainWalletId;
      }
			),
			takeUntil(this._onDestroy$),
		);
	}
}
