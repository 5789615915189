import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../../core/auth/auth.service';
import { ResponseModel } from '../../../models/other.models';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointService } from '../../../core/services/breakpoint.service';
// import { EmailCheckComponent } from '../../dumb/email-check/email-check.component';
import { filterOutNullishValues } from '../../../core/auth/auth.utils';
import { TokenResponse } from '../../../models/referral.model';

@Component({
	selector: 'sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent implements OnInit, OnDestroy {
	// @ViewChild(EmailCheckComponent, { static: false })
	// public emailCheckComponent: EmailCheckComponent | null = null;

	public signInForm: FormGroup = new FormGroup({});
	public isSpinnerVisible$: Observable<boolean> | null = null;

  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;

	private _spinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		private _formBuilder: FormBuilder,
		private _authService: AuthService,
		private _changeDetectorRef: ChangeDetectorRef,
		private _snackBarService: SnackBarService,
		private _router: Router,
		private _breakpointService: BreakpointService,
		private _activatedRoute: ActivatedRoute,
	) {}

	ngOnInit(): void {
		this.listenToTokenInQueryParams();

		this.isSpinnerVisible$ = this.getSpinnerStatus$();

		this._initSignInForm();

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public listenToTokenInQueryParams(): void {
		this._activatedRoute.queryParams
			.pipe(
				map((params) => (params['token'] ? params['token'] : null)),
				filterOutNullishValues(),
				switchMap((token) => this.getAuthTokenFromPlaynet(token)),
				takeUntil(this._onDestroy$),
			)
			.subscribe();
	}

	public getAuthTokenFromPlaynet(token: string): Observable<string> {
		return this._authService.getAuthTokenFromPlaynet$(token).pipe(
			map((tokenResponse: TokenResponse) => tokenResponse?.data?.token),
			filterOutNullishValues(),
			tap((token: string) => {
				// remove token if user with master priviliges was logged in as another account
				localStorage.removeItem('saftAuthToken-masterAccess');
				// update saftAuthToken with new token
				localStorage.setItem('saftAuthToken', token);
			}),
			tap(() => this._router.navigate(['/dashboard'])),
		);
	}

	public setSpinnerStatus(spinnerStatus: boolean): void {
		this._spinner$.next(spinnerStatus);
	}
	public getSpinnerStatus$(): Observable<boolean> {
		return this._spinner$.asObservable();
	}

	private _initSignInForm(): void {
		this.signInForm = this._formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	public sendLink(): void {
		this.setSpinnerStatus(true);

		this._authService
			.sendSignInLink(this.signInForm.value.email)
			.pipe(
				filter((response: ResponseModel) => response.status === 1),
				tap(() => this.setSpinnerStatus(false)),
				tap(() => this._changeDetectorRef.markForCheck()),
				tap(() => localStorage.setItem('saftEmailForSignIn', this.signInForm.value.email)),
				tap(() => this._router.navigate(['/check-inbox'])),
				tap(() => this._snackBarService.openSuccessSnackBar('Link has been successfully sent')),
				catchError((err: any) => {
					this.setSpinnerStatus(false);

					return of(err);
				}),
			)
			.subscribe();
	}

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
  }
}
