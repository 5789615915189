<div class="anon-referral">
	<mat-card appearance="outlined" class="anon-referral__card">
		<mat-stepper linear [orientation]="(stepperOrientation | async)!" class="stepper-general">
			<mat-step [completed]="isFirstStepCompleted" [stepControl]="anonReferralAmountForm" label="Fill out amount">
				<form [formGroup]="anonReferralAmountForm" class="step-1__form">
					<div class="form__price">
						<div>Current Tagion price:&nbsp;</div>

						<ng-container *ngIf="tagionPrice$ | async as tagionPrice; else isSpinnerVisible">
							<div>{{ tagionPrice | number: '.4' }}</div>
						</ng-container>

						<ng-template #isSpinnerVisible>
							<div class="isSpinnerVisibleAtDashboard">
								<mat-progress-spinner [diameter]="18" [mode]="'indeterminate'"></mat-progress-spinner>
							</div>
						</ng-template>
					</div>

					<mat-form-field class="form__amount">
						<mat-label>Amount in Euro</mat-label>

						<input matInput formControlName="amountInEuro" required (input)="onSearchChange($event)" />

						<mat-error *ngIf="anonReferralAmountForm?.get('amountInEuro')?.hasError('required')">
							Please enter the amount
						</mat-error>
						<mat-error *ngIf="anonReferralAmountForm?.get('amountInEuro')?.hasError('isNumberIncorrect')">
							Provide a valid amount
						</mat-error>
						<mat-error *ngIf="anonReferralAmountForm?.get('amountInEuro')?.hasError('min')">
							Minimum amount is &euro;1,000
						</mat-error>
					</mat-form-field>

					<div class="total">
						<div>Total tagions:&nbsp;</div>

						<ng-container *ngIf="tagionPrice$ | async; else isSpinnerVisible">
							<div>{{ totalTagions | number: '.0' }}</div>
						</ng-container>

						<ng-template #isSpinnerVisible>
							<div class="isSpinnerVisibleAtDashboard">
								<mat-progress-spinner [diameter]="18" [mode]="'indeterminate'"></mat-progress-spinner>
							</div>
						</ng-template>
					</div>

					<div class="form__button">
						<button
							mat-button
							matStepperNext
							class="button-full-width button-main"
							[disabled]="anonReferralAmountForm.invalid"
							[ngClass]="{
								'button-low':
									(isBreakpointXS$ | async) ||
									(isBreakpointSM$ | async) ||
									((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
								'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
							}"
						>
							<div>Next</div>
						</button>
					</div>
				</form>
			</mat-step>

			<mat-step [stepControl]="anonReferralCredsForm" label="Fill out your info">
				<form [formGroup]="anonReferralCredsForm" class="step-2__form">
					<div class="personal-info">
						<div class="first-row">
							<mat-form-field class="first-row__email">
								<mat-label>Email</mat-label>

								<input type="text" matInput formControlName="email" inputTrimmed />

								<email-check
									[form]="anonReferralCredsForm"
									[controlName]="'email'"
									matSuffix
								></email-check>

								<mat-error *ngIf="anonReferralCredsForm?.get('email')?.hasError('required')">
									Please enter the email
								</mat-error>
								<mat-error *ngIf="anonReferralCredsForm?.get('email')?.hasError('email')">
									Email is incorrect
								</mat-error>
								<mat-error *ngIf="anonReferralCredsForm?.get('email')?.hasError('maxlength')">
									Email has to be up to 75 characters long
								</mat-error>
							</mat-form-field>

							<mat-form-field class="first-row__country">
								<mat-label>Country</mat-label>
								<mat-select
									disableOptionCentering
									type="text"
									matInput
									formControlName="country"
									panelClass="referral-form-select-panel-class"
								>
									<mat-option
										*ngFor="let countryCode of countryCodesService.countryCodes"
										[value]="countryCode"
										class="referral-form-option-width mat-option-dark-medium-bg"
									>
										{{ countryCode.country }}
									</mat-option>
								</mat-select>
								<mat-error *ngIf="anonReferralCredsForm?.get('country')?.hasError('required')">
									Please select country
								</mat-error>
							</mat-form-field>
						</div>

						<div class="second-row">
							<mat-form-field class="second-row__first-name">
								<mat-label>First name</mat-label>
								<input type="text" matInput formControlName="firstName" />
								<mat-error *ngIf="anonReferralCredsForm?.get('firstName')?.hasError('required')">
									Please enter your first name
								</mat-error>
								<mat-error *ngIf="anonReferralCredsForm?.get('firstName')?.hasError('maxlength')">
									First name has to be up to 50 characters long
								</mat-error>
								<!-- <mat-error *ngIf="anonReferralCredsForm?.get('firstName')?.hasError('isNameIncorrect')">
                Only letters are allowed
              </mat-error> -->
							</mat-form-field>

							<mat-form-field class="second-row__last-name">
								<mat-label>Last name</mat-label>
								<input type="text" matInput formControlName="lastName" />
								<mat-error *ngIf="anonReferralCredsForm?.get('lastName')?.hasError('required')">
									Please enter your last name
								</mat-error>
								<mat-error *ngIf="anonReferralCredsForm?.get('lastName')?.hasError('maxlength')">
									Last name has to be up to 50 characters long
								</mat-error>
								<!-- <mat-error *ngIf="anonReferralCredsForm?.get('lastName')?.hasError('isNameIncorrect')">
                Only letters are allowed
              </mat-error> -->
							</mat-form-field>
						</div>
					</div>

					<div class="buttons">
						<button
							mat-button
							matStepperPrevious
							type="button"
							class="button-secondary"
							[ngClass]="{
								'button-low':
									(isBreakpointXS$ | async) ||
									(isBreakpointSM$ | async) ||
									((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
								'button-narrow':
									(isBreakpointXS$ | async) ||
									(isBreakpointSM$ | async) ||
									((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
								'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async),
								'button-wide': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
							}"
						>
							<div>Back</div>
						</button>

						<button
							mat-button
							matStepperNext
							type="submit"
							(click)="submitRequest()"
							[disabled]="
								(isSpinnerVisible$ | async) ||
								anonReferralCredsForm.invalid
							"
							class="button-main"
							[ngClass]="{
								'button-low':
									(isBreakpointXS$ | async) ||
									(isBreakpointSM$ | async) ||
									((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
								'button-narrow':
									(isBreakpointXS$ | async) ||
									(isBreakpointSM$ | async) ||
									((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
								'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async),
								'button-wide': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async) && !(isSpinnerVisible$ | async),
								'button-wide-plus-spinner':
									!(isBreakpointXS$ | async) && !(isBreakpointSM$ | async) && (isSpinnerVisible$ | async)
							}"
						>
							<div *ngIf="!(isSpinnerVisible$ | async); else isSpinnerVisible">Submit</div>

							<ng-template #isSpinnerVisible>
								<mat-progress-spinner
									*ngIf="isSpinnerVisible$ | async"
									[diameter]="18"
									[mode]="'indeterminate'"
									class="actions__submit-spinner"
								>
								</mat-progress-spinner>

								<div *ngIf="!(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)">Submitting...</div>
							</ng-template>
						</button>
					</div>
				</form>
			</mat-step>
		</mat-stepper>
	</mat-card>
</div>
