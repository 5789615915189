import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { InvitationStatusModel, SupportInquiryTypeModel } from '../../models/other.models';
import { LinkWalletResponseFromPayoutService, RandomsMatchResponseFromPayoutService } from '../../models/socket.model';
import { PayoutStatusModel } from '../../models/wallet.models';
import { InvitationStatusResponseCode, SupportInquiryType } from '../../enums/enums';

@Injectable({
	providedIn: 'root',
})
export class DataService {
	public mockData = 'N/A';

	public invitationStatus: InvitationStatusModel[] = [
		{
      responseCode: InvitationStatusResponseCode.pending,
			name: 'pending',
      errorDetailsFromBackend: [],
      statusForUser: 'Pending',
			cssStatus: 'pending',
		},
		{
			responseCode: InvitationStatusResponseCode.error,
      name: 'error',
			errorDetailsFromBackend: [
        {
          name: 'wrongCountryCode',
          errorDetailsForUser: 'Wrong Country Code',
          errorFromBackend: [
            'The country code', 
            'could not be found'
          ]
        },
        // note: userExists error comes with successfullyProcessed status
        {
          name: 'userExists',
          errorDetailsForUser: 'Already invited',
          errorFromBackend: [
            'One or more investors were found from the value of Referred to email',
            'Referrals are not valid for known investors',
            'This referral has already been registred, and the referral is the same as applied in this import. Nothing done.'
          ]
        },
        {
          name: 'noAccountsFound',
          errorDetailsForUser: 'No Account Found',
          errorFromBackend: ['No accounts were found from the value of Referer email']
        },
        {
          name: 'manyAccountsFound',
          errorDetailsForUser: 'Many Accounts Found',
          errorFromBackend: ['Multiple accounts were found from the value of Referer email']
        },
      ],
      statusForUser: 'Error',
			cssStatus: 'error',
		},
		{
      responseCode: InvitationStatusResponseCode.success,
			name: 'sent',
      errorDetailsFromBackend: [],
      statusForUser: 'Sent',
			cssStatus: 'success',
		},
	];

  public payoutStatus: PayoutStatusModel[] = [
    {
      responseCode: 1,
			userStatus: 'Pending',
			cssStatus: 'pending',
    },
    {
      responseCode: 2,
			userStatus: 'Cancelled',
			cssStatus: 'cancelled',
    },
    {
      responseCode: 3,
			userStatus: 'Failed',
			cssStatus: 'failed',
    },
    {
      responseCode: 4,
			userStatus: 'Success',
			cssStatus: 'success',
    },
	];

	public supportInquiryTypeList: SupportInquiryTypeModel[] = [
		{
			name: 'general',
			code: SupportInquiryType.general,
		},
		{
			name: 'UI',
			code: SupportInquiryType.UI,
		},
		{
			name: 'technical',
			code: SupportInquiryType.technical,
		},
	];

  private _spinnerPayoutRequestForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _isLinkingProcessSucceeded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _randomsMatch$: BehaviorSubject<RandomsMatchResponseFromPayoutService | null> = new BehaviorSubject<RandomsMatchResponseFromPayoutService | null>(null);
  private _checkSum$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private _emittedSocketDataForLinkWalletEventFromPayoutService$: BehaviorSubject<LinkWalletResponseFromPayoutService | null> = new BehaviorSubject<LinkWalletResponseFromPayoutService | null>(null);
	private _masterAccountStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _supportTicketNumber$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

	constructor() {}

  // Values for Payout process ---- START
  
  // TODO:!! handle FE when each of following values return false
  public setPayoutRequestFormSpinnerStatus(spinnerPayoutRequestFormStatus: boolean): void {
		this._spinnerPayoutRequestForm$.next(spinnerPayoutRequestFormStatus);
	}
	public getPayoutRequestFormSpinnerStatus$(): Observable<boolean> {
		return this._spinnerPayoutRequestForm$.asObservable();
	}

  public setIsLinkingProcessSucceeded(isLinkingProcessSucceeded: boolean): void {
		this._isLinkingProcessSucceeded$.next(isLinkingProcessSucceeded);
	}
	public getIsLinkingProcessSucceeded$(): Observable<boolean> {
		return this._isLinkingProcessSucceeded$.asObservable();
	}

  public setRandomsMatch(randomsMatch: RandomsMatchResponseFromPayoutService | null): void {
		this._randomsMatch$.next(randomsMatch);
	}
	public getRandomsMatch$(): Observable<RandomsMatchResponseFromPayoutService | null> {
		return this._randomsMatch$.asObservable();
	}

  public setCheckSum(checkSum: string | null): void {
		this._checkSum$.next(checkSum);
	}
	public getCheckSum$(): Observable<string | null> {
		return this._checkSum$.asObservable();
	}

  public setCrmWalletIdAndPubkeyBase64FromPayoutService(emittedSocketDataForLinkWalletEventFromPayoutService: LinkWalletResponseFromPayoutService | null): void {
		this._emittedSocketDataForLinkWalletEventFromPayoutService$.next(emittedSocketDataForLinkWalletEventFromPayoutService);
	}
	public getCrmWalletIdAndPubkeyBase64FromPayoutService$(): Observable<LinkWalletResponseFromPayoutService | null> {
		return this._emittedSocketDataForLinkWalletEventFromPayoutService$.asObservable();
	}

  // Values for Payout process ---- END

	public setMasterAccount(masterAccountStatus: boolean): void {
		this._masterAccountStatus$.next(masterAccountStatus);
	}
	public getMasterAccount$(): Observable<boolean> {
		return this._masterAccountStatus$.asObservable();
	}

	public setSupportTicketNumber(supportTicketNumber: string): void {
		this._supportTicketNumber$.next(supportTicketNumber);
	}
	public getSupportTicketNumber$(): Observable<string | null> {
		return this._supportTicketNumber$.asObservable();
	}

	public mockNumberCheck(property: number | undefined | null): number | string {
		if (typeof property === 'number') {
			return property as number;
		}

		return this.mockData;
	}
	public mockStringCheck(property: string | undefined | null): string {
		return property ?? this.mockData;
	}

	public setDashboardLastRoute(dashboardLastRoute: string): void {
		localStorage.setItem('dashboardLastRoute', dashboardLastRoute);
	}
}
