import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap, switchMap, take } from 'rxjs/operators';
import { PayoutResponse, WalletData, WalletDataResponse, WalletStateData, WalletStateModel } from '../../../models/wallet.models';
import { Wallet } from '../actions/wallet.actions';
import { WalletService } from '../../services/wallet.service';

@State<WalletStateModel>({
	name: 'walletState',
	defaults: {
		selectedWallet: null as any, // to overcome type-null-is-not-assignable-to-specific-type error
		loading: false,
	},
})
@Injectable()
export class WalletState {
	constructor(private _walletService: WalletService) {}

	@Selector()
	static getSelectedWallet(state: WalletStateModel): WalletStateData {
		return state.selectedWallet;
	}

	@Selector()
	static getSelectedWalletLoadingState(state: WalletStateModel): boolean {
		return state.loading;
	}

	@Action(Wallet.GetOne)
	getWalletById(stateContext: StateContext<WalletStateModel>, { walletId }: Wallet.GetOne): Observable<PayoutResponse> {
		stateContext.patchState({ loading: true });

    let walletStateData: WalletStateData | null = null;

		return this._walletService.getWalletInfo(walletId).pipe(
			map((response: WalletDataResponse) => response?.data),
			tap((walletData: WalletData) => {
        if (walletData && walletData?.userInvitation && walletData?.userReferrals && walletData?.userTransactions && walletData?.walletSummary) {
          walletStateData = {...walletData, userPayouts: []} ;
        }
			}),
      switchMap((walletData: WalletData) => this._walletService.getPayoutList(walletData.walletSummary.id)),
      tap((payoutResponse: PayoutResponse) => {
        const state = stateContext.getState();

        if (payoutResponse?.data?.payouts) {
          walletStateData = {...walletStateData as WalletStateData, userPayouts: payoutResponse?.data?.payouts} ;
        }

				stateContext.setState({
					...state,
					selectedWallet: walletStateData as WalletStateData,
					loading: false,
				});
      }),
      take(1)
		);
	}
}
