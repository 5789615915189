import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { v4 as generateGuid } from 'uuid';
import { environment } from '../../../../environments/environment';
import { DataService } from '../data.service';
import { SocketEvent } from '../../../enums/enums';
import { 
  CheckSumResponseFromPayoutService, 
  LinkWalletResponseFromPayoutService, 
  LinkWalletPayloadToPayoutService, 
  CheckSumPayloadToPayoutService, 
  RandomsMatchResponseFromPayoutService, 
  PayoutCreatedPayloadToPayoutService 
} from '../../../models/socket.model';

@Injectable({
	providedIn: 'root',
})
export class SocketIoService {
	public payoutServiceSocketUrl = environment.payoutServiceSocketUrl;

	public guid = generateGuid();
	// public socket: Socket<any> = io(this.apiUrl, { query: { guid: this.guid } });
	public socketInstance: Socket<any> | null = null;

	constructor(private _dataService: DataService) {}

  public initializeSocketInstance(crmWalletId: string, crmWalletEmail: string, crmLinkedPubKey: string): void {
    this.closeSocketConnection();
    
    this.socketInstance = io(
      this.payoutServiceSocketUrl, 
      {
        auth: {
          token: environment.websocketApiKey, 
          crmWalletId: crmWalletId,
          crmWalletEmail: crmWalletEmail,
          crmLinkedPubKey: crmLinkedPubKey
        }
      },
    );
  }

  public listenToConnectEvent(): void {
    // 'connect' event is fired when Socket instance connected or reconnected
    this.socketInstance?.on('connect', () => {
      // console.info('----- isSocketConnected: ', this.socketInstance?.connected); // true
      // TODO: this socketInstance.id should not be used as ID accross app
      // console.info('----- Connected socket ID: ', this.socketInstance?.id);
    });
  }

  public listenToDisconnectEvent(): void {
    // This event is fired when Socket instance disconnected
    this.socketInstance?.on('disconnect', (disconnectReason: any) => {
      // console.info('----- isSocketConnected: ', this.socketInstance?.connected); // false
      console.error('--- Socket disconnected. Reason: ', disconnectReason);
    });

    this.socketInstance?.removeAllListeners();
  }

  public listenToConnectError(): void {
    // This event is fired when:
    // - the low-level connection cannot be established
    // - the connection is denied by the server in a middleware function
    this.socketInstance?.on('connect_error', (error: any) => {
      console.error('--- Socket connection error: ', error.message);

      // TODO: Create component for error notifications for user and trigger it here

      // setTimeout(() => {
      //   this.socketInstance?.connect();
      // }, 5000);
    });
  }

  public closeSocketConnection(): void {
    this.socketInstance?.close();
  }

  public emitEventToPayoutService(
    event: SocketEvent._2_linkWallet | SocketEvent._3_checkSum | SocketEvent._6_payoutCreated, 
    payload: LinkWalletPayloadToPayoutService | CheckSumPayloadToPayoutService | PayoutCreatedPayloadToPayoutService
  ): void {
    this.socketInstance?.emit(event, payload);

    // In order to listen for messages emitted within handleEvent() from NestJs, the client has to attach third parameter as callback
    // In this case third parameter callback will console all events emitted by handleEvent() at Payout service
    // this.socketInstance.emit(SocketEvent.linkWallet, {value: 'world'}, (data: any) => console.log(data));
  }

  // listen to "event:linkWallet-PayoutServiceToTagionPortal" event
  public listenToLinkWalletEventMessagesFromPayoutService(): void {
    this.socketInstance?.on(SocketEvent._1_linkWallet, (linkWalletPayloadData: LinkWalletResponseFromPayoutService) => {
      // console.log('--- Data from Payout service for _1_linkWallet event: ', linkWalletPayloadData);

      if (linkWalletPayloadData) {
        this._dataService.setCrmWalletIdAndPubkeyBase64FromPayoutService(linkWalletPayloadData);
      } else {
        console.error('--- linkWalletPayloadData is nullish');
      }
    });
  }

  // listen to "event:checkSum" event from payout service
  public listenToCheckSumEventMessagesFromPayoutService(): void {
    this.socketInstance?.on(SocketEvent._4_checkSum, (checkSumPayloadData: CheckSumResponseFromPayoutService) => {
      // console.log('--- Data from Payout service for _4_checkSum event: ', checkSumPayloadData);

      if (checkSumPayloadData) {
        this._dataService.setCheckSum(checkSumPayloadData.checkSum);
      } else {
        console.error('--- checkSumPayloadData is nullish');
      }
    });
  }

  // listen to "event:randomsMatch" event from payout service
  public listenToRandomsMatchEventMessagesFromPayoutService(): void {
    this.socketInstance?.on(SocketEvent._5_randomsMatch, (randomsMatchloadData: RandomsMatchResponseFromPayoutService) => {
      // console.log('--- Data from Payout service for _5_randomsMatch event: ', randomsMatchloadData);

      if (randomsMatchloadData) {
        if (randomsMatchloadData?.isRandomsMatch && randomsMatchloadData?.isPubKeysMatch) {
          this._dataService.setRandomsMatch({ isRandomsMatch: randomsMatchloadData.isRandomsMatch, isPubKeysMatch: randomsMatchloadData.isPubKeysMatch });
        } else if (!randomsMatchloadData?.isRandomsMatch) {
          console.error('--- Randoms do not match');
        } else if (!randomsMatchloadData?.isPubKeysMatch) {
          console.error('--- Public keys do not match');
        } else {
          console.error('Something went wrong parsing randomsMatchloadData');
        }
      } else {
        console.error('--- randomsMatchloadData is nullish');
      }
    });
  }
}
