import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from '../../modules/shared.module';
import { MaterialModule } from '../../modules/material.module';
import { DialogService } from '../../core/services/dialog.service';
import { BalanceModule } from './balance/balance.module';
import { ReferralModule } from './referral/referral.module';
import { PurchasesModule } from './purchases/purchases.module';
import { InvitationsModule } from './invitations/invitations.module';
import { PipesModule } from '../../pipes/pipes.module';
import { PayoutModule } from './payout/payout.module';

const componentModules = [BalanceModule, PurchasesModule, ReferralModule, InvitationsModule, PayoutModule];

@NgModule({
	declarations: [DashboardComponent],
	imports: [SharedModule, MaterialModule, PipesModule, ...componentModules],
	exports: [DashboardComponent],
	providers: [DialogService],
})
export class DashboardModule {}
