import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { SignInComponent } from './sign-in.component';
import { DirectivesModule } from '../../../directives/directives.module';
import { EmailCheckModule } from '../../dumb/email-check/email-check.module';

const componentModules = [EmailCheckModule];

@NgModule({
	declarations: [SignInComponent],
	imports: [SharedModule, MaterialModule, DirectivesModule, ...componentModules],
	exports: [SignInComponent],
})
export class SignInModule {}
