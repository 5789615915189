<div class="sign-in">
	<mat-card appearance="raised" class="sign-in__card">
		<form [formGroup]="signInForm" class="form">
			<h2 class="form__header">Login</h2>

			<!-- TODO: заменить mat-dialog на mat-card и пофиксить стили -->
			<mat-dialog-content class="form__content">
				<mat-form-field class="content-card">
					<mat-label>Email</mat-label>

					<input type="text" matInput [formControlName]="'email'" inputTrimmed />

					<!-- <email-check
						[form]="signInForm"
						[controlName]="'email'"
						[isSuccessIfEmailExists]="true"
						[tooltipNameForSuccess]="'Account exists'"
						[tooltipNameForFailure]="'Account does not exist'"
						matSuffix
					></email-check> -->

					<mat-error *ngIf="signInForm.get('email')?.hasError('required')"> Please enter the email </mat-error>
					<mat-error *ngIf="signInForm.get('email')?.hasError('email')"> Email is incorrect </mat-error>
				</mat-form-field>
			</mat-dialog-content>

			<mat-dialog-actions class="form__actions">
				<button
					mat-button
					type="submit"
					(click)="sendLink()"
					[disabled]="
						(isSpinnerVisible$ | async) ||
						signInForm.invalid
					"
					class="actions-button button-main"
					[ngClass]="{
						'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
						'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async)
					}"
				>
					<div *ngIf="!(isSpinnerVisible$ | async); else isSpinnerVisible">Send Link</div>

					<ng-template #isSpinnerVisible>
						<mat-progress-spinner
							*ngIf="isSpinnerVisible$ | async"
							[diameter]="18"
							[mode]="'indeterminate'"
							class="actions-button-spinner"
						>
						</mat-progress-spinner>

						<div>Sending...</div>
					</ng-template>
				</button>
			</mat-dialog-actions>
		</form>
	</mat-card>
</div>
