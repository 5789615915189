<div class="contact-support-form-dialog">
	<form [formGroup]="contactSupportForm" class="dialog-form">
		<h3 class="dialog-form__heading">Contact support</h3>

		<!-- <div
			*ngIf="!(isBreakpointHeightSmall$ | async) || (!(isBreakpointXS$ | async) && !(isBreakpointSM$ | async))"
			class="dialog-form__sub-heading"
		>
			A welcome email will be sent and you earn 5% for each successful referral
		</div> -->

		<mat-dialog-content class="dialog-form__content">
			<mat-form-field>
				<mat-label>Type</mat-label>
				<mat-select
					disableOptionCentering
					type="text"
					matInput
					[formControlName]="'type'"
					panelClass="contact-support-form-select-panel-class"
					class="type-select"
				>
					<mat-option
						*ngFor="let supportInquiryType of dataService.supportInquiryTypeList"
						[value]="supportInquiryType.name"
						class="contact-support-form-option-width mat-option-dark-bg"
					>
						{{ supportInquiryType.name }}
					</mat-option>
				</mat-select>
				<mat-error *ngIf="contactSupportForm?.get('type')?.hasError('required')"> Please select inquiry type </mat-error>
			</mat-form-field>

			<mat-form-field>
				<mat-label>Inquiry</mat-label>

				<!-- display differnet amount of rows for different breakpoints -->
				<ng-container
					*ngIf="
						(isBreakpointHeightSmall$ | async) || (isBreakpointXS$ | async) || (isBreakpointSM$ | async);
						else bigBreakpoint
					"
				>
					<textarea
						class="inquiry-textarea"
						placeholder="Describe what went wrong"
						matInput
						[formControlName]="'inquiry'"
						cdkTextareaAutosize
						cdkAutosizeMinRows="3"
					></textarea>
				</ng-container>

				<ng-template #bigBreakpoint>
					<textarea
						class="inquiry-textarea"
						placeholder="Describe what went wrong"
						matInput
						[formControlName]="'inquiry'"
						cdkTextareaAutosize
						cdkAutosizeMinRows="6"
					></textarea>
				</ng-template>

				<mat-error *ngIf="contactSupportForm?.get('inquiry')?.hasError('required')">
					Please describe what went wrong
				</mat-error>
			</mat-form-field>

			<div>
				<input type="file" class="file-input" (change)="onFileSelected($event)" #fileInput />

				<button
					type="button"
					mat-button
					class="upload-button"
					(click)="fileInput.click()"
					[ngClass]="{
						'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
						'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async),
						'button-padding-extra-tiny': (isBreakpointXS$ | async),
						'button-padding-tiny': (isBreakpointSM$ | async)
					}"
				>
					<mat-icon>attach_file</mat-icon>
					<div>Attach file</div>
				</button>

				<div *ngFor="let attachment of attachments; let index = index" class="file-attachment">
					<div class="file-attachment__file-icon">
						<img src="../../../../assets/icons/regular/file-icon.svg" alt="file-icon" class="file-icon-child" />
					</div>

					<div class="file-attachment__file">
						{{ attachment.filename || 'No file uploaded yet.' }}
					</div>

					<button
						type="button"
						mat-button
						class="file-attachment__delete-button button-icon"
						(click)="removeAttachment(index)"
					>
						<mat-icon>delete_outline</mat-icon>
					</button>
				</div>
			</div>
		</mat-dialog-content>

		<mat-dialog-actions class="dialog-form__actions">
			<button
				mat-button
				type="submit"
				[disabled]="(isSpinnerVisible$ | async) || contactSupportForm.invalid"
				(click)="contactSupport()"
				class="button-full-width button-main"
				[ngClass]="{
					'button-low':
						(isBreakpointXS$ | async) ||
						(isBreakpointSM$ | async) ||
						((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
					'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
				}"
			>
				<div *ngIf="!(isSpinnerVisible$ | async); else isSpinnerVisible">Send</div>

				<ng-template #isSpinnerVisible>
					<mat-progress-spinner
						*ngIf="isSpinnerVisible$ | async"
						[diameter]="24"
						[mode]="'indeterminate'"
						class="actions__submit-spinner"
					>
					</mat-progress-spinner>

					<div>Sending...</div>
				</ng-template>
			</button>
		</mat-dialog-actions>
	</form>
</div>
