<div class="payout-request-form">
  <mat-card appearance="outlined" class="payout-request__card">
    <ng-template [ngIf]="!(isShowSuccessfulPayoutPage$ | async) && !(isShowFailedPayoutPage$ | async)">
      <div class="payout-steps">
        <div class="payout-request-form__heading">
          <h3>Payout process</h3>
        </div>

        <mat-stepper labelPosition="bottom" #stepper [linear]="true" orientation="horizontal" class="stepper-general" (selectionChange)="onMatStepperStepChange($event)">
          <div *ngIf="selectedWallet$ | async as selectedWallet">
            <!-- substitute default 'edit' icon for active step with step number -->
            <!-- <ng-template matStepperIcon="edit">
              <div> {{ stepperActiveStepNumber }} </div>
            </ng-template> -->

            <!-- handle other step icons -->
            <ng-template matStepperIcon="check">
              <mat-icon>check</mat-icon>
            </ng-template>

            <!-- TODO: Add spinner or animate instead matStepperIcon "refresh" on load -->
            <!-- <ng-template matStepperIcon="refresh">
              <mat-progress-spinner
                [diameter]="24"
                [mode]="'indeterminate'"
                class="actions__submit-spinner"
              >
                <mat-icon>refresh</mat-icon>
              </mat-progress-spinner>
            </ng-template> -->

            <mat-step [completed]="isFirstStepCompleted" [stepControl]="downloadAppsForm" label="Download" [state]="isFirstStepCompleted ? 'check' : 'edit'">
              <div class="step-1">
                <div class="step-1__text-container">
                  <div class="step-1__text">Open the camera on your mobile device and scan one of the following QR codes. Then install JustPay wallet application and click "Next".</div>
                </div>

                <ng-template [ngIf]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
                  <div class="step-1__qr-codes-xs-sm">
                    <div class="step-1__download-buttons">
                      <div class="first-button-wrapper">
                        <ng-template [ngIf]="(isBreakpointXS$ | async)">
                          <button
                            mat-button
                            class="first-button button-white-green button-download"
                            [ngClass]="{
                              'button-low':
                                (isBreakpointXS$ | async) ||
                                (isBreakpointSM$ | async) ||
                                ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
                              'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
                            }"
                            (click)="showDownloadIosWalletQrCodeAtXsSmBreakpoints()"
                          >
                            <div class="button-download-text-wrapper">
                              <div class="button-download-text" [ngClass]="{ 'active': isShowDownloadIosWalletQrCodeAtXsSmBreakpoints }">
                                iOS
                              </div>
                            </div>
                          </button>
                        </ng-template>

                        <ng-template [ngIf]="(isBreakpointSM$ | async)">
                          <button
                            mat-button
                            class="first-button button-white-green button-download"
                            [ngClass]="{
                              'button-low':
                                (isBreakpointXS$ | async) ||
                                (isBreakpointSM$ | async) ||
                                ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
                              'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
                            }"
                            (click)="showDownloadIosWalletQrCodeAtXsSmBreakpoints()"
                          >
                            <div class="button-download-text-wrapper">
                              <div class="button-download-text" [ngClass]="{ 'active': isShowDownloadIosWalletQrCodeAtXsSmBreakpoints }">
                                Download for iOS
                              </div>
                              
                              <div class="button-download-icon">
                                <ng-template [ngIf]="isShowDownloadIosWalletQrCodeAtXsSmBreakpoints">
                                  <mat-icon class="material-icons" [ngClass]="{ 'md-green-dark': isShowDownloadIosWalletQrCodeAtXsSmBreakpoints }">
                                    radio_button_checked
                                  </mat-icon>
                                </ng-template>

                                <ng-template [ngIf]="!isShowDownloadIosWalletQrCodeAtXsSmBreakpoints">
                                  <mat-icon class="material-icons">radio_button_unchecked</mat-icon>
                                </ng-template>
                              </div>
                            </div>
                          </button>
                        </ng-template>
                      </div>
                      
                      <div class="second-button-wrapper">
                        <ng-template [ngIf]="(isBreakpointXS$ | async)">
                          <button
                            mat-button
                            class="second-button button-white-green button-download"
                            [ngClass]="{
                              'button-low':
                                (isBreakpointXS$ | async) ||
                                (isBreakpointSM$ | async) ||
                                ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
                              'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
                            }"
                            (click)="showDownloadAndroidWalletQrCodeAtXsSmBreakpoints()"
                          >
                            <div class="button-download-text-wrapper">
                              <div class="button-download-text" [ngClass]="{ 'active': isShowDownloadAndroidWalletQrCodeAtXsSmBreakpoints }">
                                Android
                              </div>
                            </div>
                          </button>
                        </ng-template>
                        
                        <ng-template [ngIf]="(isBreakpointSM$ | async)">
                          <button
                            mat-button
                            class="second-button button-white-green button-download"
                            [ngClass]="{
                              'button-low':
                                (isBreakpointXS$ | async) ||
                                (isBreakpointSM$ | async) ||
                                ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
                              'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
                            }"
                            (click)="showDownloadAndroidWalletQrCodeAtXsSmBreakpoints()"
                          >
                            <div class="button-download-text-wrapper">
                              <div class="button-download-text" [ngClass]="{ 'active': isShowDownloadAndroidWalletQrCodeAtXsSmBreakpoints }">
                                Download for Android
                              </div>
                              
                              <div class="button-download-icon">
                                <ng-template [ngIf]="isShowDownloadAndroidWalletQrCodeAtXsSmBreakpoints">
                                  <mat-icon class="material-icons" [ngClass]="{ 'md-green-dark': isShowDownloadAndroidWalletQrCodeAtXsSmBreakpoints }">radio_button_checked</mat-icon>
                                </ng-template>

                                <ng-template [ngIf]="!isShowDownloadAndroidWalletQrCodeAtXsSmBreakpoints">
                                  <mat-icon class="material-icons">radio_button_unchecked</mat-icon>
                                </ng-template>
                              </div>
                            </div>
                          </button>
                        </ng-template>
                      </div>
                    </div>

                    <div class="step-1__qr-code">
                      <div
                        class="step-1__ios__qr-code"
                        [ngClass]="{
                          'show-qr-code': isShowDownloadIosWalletQrCodeAtXsSmBreakpoints,
                          'hide-qr-code': !isShowDownloadIosWalletQrCodeAtXsSmBreakpoints
                        }"
                      >
                        <div class="qr-code-component">
                          <qr-code
                            id="downloadIosAppQrCodeID"
                            [value]="qrCodeDownloadIosAppValue"
                            [size]="(isBreakpointXS$ | async) ? qrCodeSizeXS : (isBreakpointSM$ | async) ? qrCodeSizeSM : qrCodeSizeMDLGXL"
                            [margin]="0"
                            errorCorrectionLevel="L"
                          ></qr-code>
                        </div>
                      </div>

                      <div
                        class="step-1__android__qr-code"
                        [ngClass]="{
                          'show-qr-code': isShowDownloadAndroidWalletQrCodeAtXsSmBreakpoints,
                          'hide-qr-code': !isShowDownloadAndroidWalletQrCodeAtXsSmBreakpoints
                        }"
                      >
                        <div class="qr-code-component">
                          <qr-code
                            id="downloadAndroidAppQrCodeID"
                            [value]="qrCodeDownloadAndroidAppValue"
                            [size]="(isBreakpointXS$ | async) ? qrCodeSizeXS : (isBreakpointSM$ | async) ? qrCodeSizeSM : qrCodeSizeMDLGXL"
                            [margin]='0'
                            errorCorrectionLevel="L"
                          ></qr-code>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <ng-template [ngIf]="(isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async)">
                  <div class="step-1__qr-codes-md-lg-xl">
                    <div class="step-1__ios">
                      <div class="step-1__ios__text">
                        <div class="status success">Download for iOS</div>
                      </div>
  
                      <div class="step-1__ios__qr-code">
                        <div class="qr-code-component">
                          <qr-code
                            id="downloadIosAppQrCodeID"
                            [value]="qrCodeDownloadIosAppValue"
                            [size]="(isBreakpointXS$ | async) ? qrCodeSizeXS : (isBreakpointSM$ | async) ? qrCodeSizeSM : qrCodeSizeMDLGXL"
                            [margin]="0"
                            errorCorrectionLevel="L"
                          ></qr-code>
                        </div>
                      </div>
                    </div>
  
                    <div class="step-1__android">
                      <div class="step-1__android__text">
                        <div class="status success">Download for Android</div>
                      </div>
                      
                      <div class="step-1__android__qr-code">
                        <div class="qr-code-component">
                          <qr-code
                            id="downloadAndroidAppQrCodeID"
                            [value]="qrCodeDownloadAndroidAppValue"
                            [size]="(isBreakpointXS$ | async) ? qrCodeSizeXS : (isBreakpointSM$ | async) ? qrCodeSizeSM : qrCodeSizeMDLGXL"
                            [margin]='0'
                            errorCorrectionLevel="L"
                          ></qr-code>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <div class="step-1__buttons">
                  <!-- TODO: substitute classes in [ng-class] for different ready-made buttons? -->
                  <button
                    mat-button
                    matStepperNext
                    class="button-submit button-green"
                    [disabled]="downloadAppsForm.invalid"
                    [ngClass]="{
                      'button-low':
                        (isBreakpointXS$ | async) ||
                        (isBreakpointSM$ | async) ||
                        ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
                      'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
                    }"
                  >
                    <div>Next</div>
                  </button>
                </div>
              </div>
            </mat-step>

            <mat-step [completed]="isSecondStepCompleted" [stepControl]="linkWalletForm" label="Linking" [state]="isSecondStepCompleted ? 'check' : 'edit'">
              <div class="step-2">
                <div class="step-2__text-container">
                  <div class="step-2__text">
                    Click the "Scan" button at the bottom menu of JustPay wallet application and scan this QR code to link your mobile wallet to your Tagion Portal account. If you have already linked your wallet, simply click "Next".
                  </div>
                </div>

                <div class="step-2__qr-code">
                  <div class="qr-code-component">
                    <qr-code
                      id="linkWalletQrCodeID"
                      [value]="qrCodeLinkWalletValue"
                      [size]="(isBreakpointXS$ | async) ? qrCodeSizeXS : (isBreakpointSM$ | async) ? qrCodeSizeSM : qrCodeSizeMDLGXL"
                      [margin]='0'
                      errorCorrectionLevel="L"
                    ></qr-code>
                  </div>
                </div>

                <div class="step-2__buttons">
                  <button
                    mat-button
                    matStepperPrevious
                    class="button-back button-white"
                    [ngClass]="{
                      'button-low':
                        (isBreakpointXS$ | async) ||
                        (isBreakpointSM$ | async) ||
                        ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
                      'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
                    }"
                  >
                    <div>Back</div>
                  </button>

                  <button
                    mat-button
                    matStepperNext
                    class="button-submit button-green"
                    [disabled]="(!(dataService.getIsLinkingProcessSucceeded$() | async) && !selectedWallet.walletSummary.linkedPubKey) || (isSpinnerVisible$ | async)"
                    [ngClass]="{
                      'button-low':
                        (isBreakpointXS$ | async) ||
                        (isBreakpointSM$ | async) ||
                        ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
                      'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
                    }"
                  >
                    <div *ngIf="!(isSpinnerVisible$ | async); else isSpinnerVisible">Next</div>

                    <ng-template #isSpinnerVisible>
                      <mat-progress-spinner
                        *ngIf="isSpinnerVisible$ | async"
                        [diameter]="24"
                        [mode]="'indeterminate'"
                        class="actions__submit-spinner"
                      >
                      </mat-progress-spinner>
                    </ng-template>
                  </button>
                </div>
              </div>      
            </mat-step>

            <mat-step [completed]="isThirdStepCompleted" [stepControl]="payoutStepOneForm" label="Payout" [state]="isThirdStepCompleted ? 'check' : 'edit'">
              <div class="step-3">
                <div class="step-3__text-container">
                  <div class="step-3__text">
                    Confirm the payout amount and ensure the displayed Wallet ID matches the one in your JustPay wallet (check under Settings > 'Wallet ID').
                  </div>
                </div>
                
                <div class="step-3__data">
                  <mat-card appearance="outlined" class="step-3__data__card">
                    <div class="step-3__data__card__recipient">
                      <div class="step-3__data__card__recipient__text">Wallet ID:</div>
                      <div class="step-3__data__card__recipient__value"> {{ selectedWallet.walletSummary.linkedPubKey | walletAddress }} </div>
                    </div>

                    <div class="step-3__data__card__amount">
                      <div class="step-3__data__card__amount__text">Amount:</div>
                      <div class="step-3__data__card__amount__value">
                        <div>
                          {{ selectedWallet.walletSummary.pendingVested | number:'1.0-0' }}
                        </div>
                        <!-- TODO: was used for testing here -->
                        <!-- <div>
                          {{ mockedPayoutAmount | number:'1.0-0' }}
                        </div> -->
                        <ng-template [ngIf]="!(isBreakpointXS$ | async)">
                          <div>&nbsp;TGN</div>
                        </ng-template>
                      </div>
                    </div>
                  </mat-card>
                </div>

                <div class="step-3__buttons">
                  <button
                    mat-button
                    matStepperPrevious
                    class="button-back button-white"
                    [ngClass]="{
                      'button-low':
                        (isBreakpointXS$ | async) ||
                        (isBreakpointSM$ | async) ||
                        ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
                      'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
                    }"
                  >
                    <div>Back</div>
                  </button>

                  <button
                    mat-button
                    matStepperNext
                    class="button-submit button-green"
                    [ngClass]="{
                      'button-low':
                        (isBreakpointXS$ | async) ||
                        (isBreakpointSM$ | async) ||
                        ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
                      'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
                    }"
                  >
                    <div>Next</div>
                  </button>
                </div>
              </div>      
            </mat-step>

            <mat-step [completed]="isFourthStepCompleted" [stepControl]="payoutStepTwoForm" label="Confirmation" [state]="isFourthStepCompleted ? 'check' : 'edit'">
              <div class="step-4">
                <div class="step-4__text-container">
                  <div class="step-4__text">
                    Please scan the following QR code to complete payout process
                  </div>
                </div>
                
                <div class="step-4__qr-code">
                  <div class="qr-code-component">
                    <qr-code
                      id="payoutQrCodeID"
                      [value]="qrCodeMakePayoutValue"
                      [size]="(isBreakpointXS$ | async) ? qrCodeSizeXS : (isBreakpointSM$ | async) ? qrCodeSizeSM : qrCodeSizeMDLGXL"
                      [margin]='0'
                      errorCorrectionLevel="L"
                    ></qr-code>
                  </div>
                </div>

                <div class="step-4__buttons">
                  <button
                    mat-button
                    matStepperPrevious
                    class="button-back button-white"
                    [ngClass]="{
                      'button-low':
                        (isBreakpointXS$ | async) ||
                        (isBreakpointSM$ | async) ||
                        ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
                      'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
                    }"
                  >
                    <div>Back</div>
                  </button>
                </div>
              </div>      
            </mat-step>
          </div>
        </mat-stepper>
      </div>
    </ng-template>

    <ng-template [ngIf]="(isShowSuccessfulPayoutPage$ | async) && !(isShowFailedPayoutPage$ | async)">
      <ng-container *ngIf="selectedWallet$ | async as selectedWallet">
        <div class="payout-success">
          <div class="payout-success-heading">
            <h3>Payout process</h3>
          </div>

          <div class="payout-success-body">
            <div class="payout-success-icon">
              <img src="../../../assets/icons/other/success-icon.svg" alt="success" class="payout-success-icon-img" />
            </div>

            <div class="payout-success-main-text">
              <h3>Request has been successfully sent</h3>
            </div>

            <div class="payout-success-secondary-text">
              <div class="secondary-text-first-row">Approval may take several days.</div>

              <div class="secondary-text-second-row">
                <div class="secondary-text-regular">Once payout completed, you will receive transaction in JustPay wallet and will get a notification to&nbsp;</div>

                <div class="secondary-text-highlited">
                  {{ selectedWallet.walletSummary.walletEmail }}
                </div>
              </div>
            </div>
          </div>

          <div class="payout-success-buttons">
            <button
              mat-button
              class="button-close button-green"
              [ngClass]="{
                'button-low':
                  (isBreakpointXS$ | async) ||
                  (isBreakpointSM$ | async) ||
                  ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
                'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
              }"
              (click)="closeSuccessfulPayoutPage()"
            >
              <div>Close</div>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-template>

    <ng-template [ngIf]="!(isShowSuccessfulPayoutPage$ | async) && (isShowFailedPayoutPage$ | async)">
      <div class="failed-payout">
        <div class="payout-failed-heading">
          <h3>Payout process</h3>
        </div>

        <div class="payout-failed-body">
          <div class="payout-failed-icon">
            <img src="../../../assets/icons/other/failure-icon.svg" alt="failed" class="payout-failed-icon-img" />
          </div>

          <div class="payout-failed-main-text">
            <h3>Oops!</h3>
          </div>

          <div class="payout-failed-secondary-text">
            <div class="secondary-text-first-row">
              Your payout request has not been submitted.
            </div>

            <div class="secondary-text-second-row">
              Please contact us via&nbsp;
                <div class="secondary-text-highlited"> {{ investorRelationsEmail }} </div>
            </div>
          </div>
        </div>

        <div class="payout-failed-buttons">
          <button
            mat-button
            class="button-close button-green"
            [ngClass]="{
              'button-low':
                (isBreakpointXS$ | async) ||
                (isBreakpointSM$ | async) ||
                ((isBreakpointHeightSmall$ | async) && ((isBreakpointXS$ | async) || (isBreakpointSM$ | async))),
              'button-high': !(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)
            }"
            (click)="closeFailedPayoutPage()"
          >
            <div>Close</div>
          </button>
        </div>
      </div>
    </ng-template>
	</mat-card>
</div>
