import { NgModule } from '@angular/core';
import { EmailCheckComponent } from './email-check.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';

@NgModule({
	declarations: [EmailCheckComponent],
	imports: [SharedModule, MaterialModule],
	exports: [EmailCheckComponent],
})
export class EmailCheckModule {}
