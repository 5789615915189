import { NgModule } from '@angular/core';
import { InvitationsComponent } from './invitations.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { ReferralFormModule } from '../../dumb/referral-form/referral-form.module';
import { BecomeAmbassadorFormModule } from '../../dumb/become-ambassador-form/become-ambassador-form.module';

const componentModules = [ReferralFormModule, BecomeAmbassadorFormModule];

@NgModule({
	declarations: [InvitationsComponent],
	imports: [SharedModule, MaterialModule, PipesModule, ...componentModules],
	exports: [InvitationsComponent],
})
export class InvitationsModule {}
