import { NgModule } from '@angular/core';
import { CheckInboxComponent } from './check-inbox.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';

@NgModule({
	declarations: [CheckInboxComponent],
	imports: [SharedModule, MaterialModule],
	exports: [CheckInboxComponent],
})
export class CheckInboxModule {}
