import { Directive } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive({
	selector: '[inputTrimmed]',
})
export class InputTrimmedDirective {
	constructor(private _ngControl: NgControl) {
		trimValueAccessor(this._ngControl.valueAccessor);
	}
}

function trimValueAccessor(valueAccessor: ControlValueAccessor | null): void | undefined {
	if (valueAccessor) {
		const original = valueAccessor.registerOnChange;

		valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
			return original.call(valueAccessor, (value: unknown) => {
				return fn(typeof value === 'string' ? value.trim() : value);
			});
		};
	}
}
