import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { BalanceComponent } from './balance.component';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
	declarations: [BalanceComponent],
	imports: [SharedModule, MaterialModule, PipesModule],
	exports: [BalanceComponent],
})
export class BalanceModule {}
