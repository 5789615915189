/* eslint-disable @typescript-eslint/no-namespace */

export namespace Wallet {
	export class GetAll {
		static readonly type = '[Wallet API] GetAll';
	}

	export class GetOne {
		static readonly type = '[Wallet API] GetOne';

		constructor(public walletId: string) {}
	}
}
