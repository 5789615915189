<ng-container *ngIf="isSignedIn$ | async; else isNotSignedIn">
	<div class="header">
		<!-- Header with logo, app version and Logout button for MD, LG and XL -->
		<div class="header__heading">
			<div class="left-block">
				<div [routerLink]="'/dashboard'" class="left-block__heading">
					<h3>TagionPortal</h3>
				</div>

				<!-- <div class="left-block__app-version-wrapper">
					<div class="app-version">
						{{ currentApplicationVersion }}
					</div>
				</div> -->

				<div
					*ngIf="
						((userHasMasterAccess$ | async) || isMasterAccessActive) &&
						((isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async))
					"
					class="master-access"
				>
					<ng-container *ngIf="userHasMasterAccess$ | async as userHasMasterAccess">
						<button
							mat-button
							matTooltip="Enter another account"
							aria-label="Button with tooltip"
							class="button-padding-tiny button-narrow button-high button-bg-black-header"
							[ngClass]="{
								'user-with-master-access': userHasMasterAccess
							}"
							(click)="openMasterAccountForm()"
						>
							<!-- <mat-icon class="material-icons md-18">input</mat-icon> -->

							<div class="card-icon">
								<img
									src="../../../assets/icons/regular/re-login.svg"
									alt="login as another account"
									class="regular-icon-image"
								/>
							</div>
						</button>
					</ng-container>

					<ng-container *ngIf="isMasterAccessActive">
						<button
							mat-button
							matTooltip="Return to main account"
							aria-label="Button with tooltip"
							class="button-padding-tiny button-narrow button-high button-bg-black-header"
							[ngClass]="{ 'master-access-is-active': isMasterAccessActive }"
							(click)="returnToMainAccount()"
						>
							<!-- <mat-icon class="material-icons md-18">undo</mat-icon> -->

							<div class="card-icon">
								<img
									src="../../../assets/icons/regular/return-to-main-account.svg"
									alt="return to main account"
									class="regular-icon-image"
								/>
							</div>
						</button>
					</ng-container>
				</div>
			</div>

			<div class="right-block">
				<div
					*ngIf="(isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async)"
					class="right-block__select-wallet"
        >       
					<ng-container
						*ngIf="!(accountLoadingState$ | async) && !(selectedWalletLoadingState$ | async); else isSpinnerVisible"
					>
						<ng-container *ngIf="(account$ | async) && (selectedWallet$ | async)">
							<!-- <ng-container *ngIf="(account$ | async)!.wallets.length > 1; else isOnlyOneWallet"> -->
								<wallet-select
									[account]="account$ | async"
									[accountLoadingState]="accountLoadingState$ | async"
									[selectedWallet]="selectedWallet$ | async"
									[selectedWalletLoadingState]="selectedWalletLoadingState$ | async"
								></wallet-select>
							<!-- </ng-container> -->

							<!-- <ng-template #isOnlyOneWallet>
								<wallet-name
									[account]="account$ | async"
									[accountLoadingState]="accountLoadingState$ | async"
									[selectedWallet]="selectedWallet$ | async"
									[selectedWalletLoadingState]="selectedWalletLoadingState$ | async"
								></wallet-name>
							</ng-template> -->
						</ng-container>
					</ng-container>

					<ng-template #isSpinnerVisible>
						<mat-progress-spinner
							[diameter]="24"
							[mode]="'indeterminate'"
							class="button-spinner"
						></mat-progress-spinner>
					</ng-template>
				</div>

				<div class="right-block__navigation">
					<!-- <div class="right-block__navigation-support"> -->
						<!--
              ----- COMMENTED START ----- 
            <a
							[href]="getMailToConfig() | async"
							target="_blank"
							mat-button
							class="header-link button-high button-bg-black-header"
							[ngClass]="{
								'button-extra-narrow': (isBreakpointXS$ | async),
								'button-wide-medium':
									(isBreakpointSM$ | async) ||
									(isBreakpointMD$ | async) ||
									(isBreakpointLG$ | async) ||
									(isBreakpointXL$ | async)
							}"
						>
							<div class="card-icon">
								<img
									src="../../../assets/icons/regular/contact-support.svg"
									alt="contact support"
									class="regular-icon-image"
								/>
							</div>

							<ng-container *ngIf="!(isBreakpointXS$ | async)"><div class="card-text">Support</div></ng-container>
						</a> 
            ----- COMMENTED END -----   
            -->

						<!-- <button
							mat-button
							class="header-link button-high button-bg-black-header"
							[ngClass]="{
								'button-extra-narrow': (isBreakpointXS$ | async),
								'button-wide-medium':
									(isBreakpointSM$ | async) ||
									(isBreakpointMD$ | async) ||
									(isBreakpointLG$ | async) ||
									(isBreakpointXL$ | async)
							}"
							(click)="contactSupport()"
						>
							<div class="card-icon">
								<img
									src="../../../assets/icons/regular/contact-support.svg"
									alt="contact support"
									class="regular-icon-image"
								/>
							</div>

							<ng-container *ngIf="!(isBreakpointXS$ | async)"><div class="card-text">Support</div></ng-container>
						</button>
					</div> -->

					<div class="right-block__navigation-sign-out">
						<button
							mat-button
							class="logout-button-wrapper button-high button-bg-black-header"
							[ngClass]="{
								'button-extra-narrow': (isBreakpointXS$ | async),
								'button-wide-medium':
									(isBreakpointSM$ | async) ||
									(isBreakpointMD$ | async) ||
									(isBreakpointLG$ | async) ||
									(isBreakpointXL$ | async)
							}"
							(click)="signOut()"
						>
              <div class="card-icon-text-wrapper">
                <div class="card-icon">
                  <img src="../../../assets/icons/regular/logout.svg" alt="logout" class="regular-icon-image" />
                </div>

                <ng-container *ngIf="!(isBreakpointXS$ | async)"><div class="card-text">Logout</div></ng-container>
              </div>		
						</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Header Select / Header Name for XS and SM -->
		<div class="header__wallet-select-wrapper" *ngIf="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
			<div class="header__wallet-select">
				<ng-container
					*ngIf="!(accountLoadingState$ | async) && !(selectedWalletLoadingState$ | async); else isSpinnerVisible"
				>
					<ng-container *ngIf="(account$ | async) && (selectedWallet$ | async); else isDataNotLoaded">
						<!-- <ng-container *ngIf="(account$ | async)!.wallets.length > 1; else isOnlyOneWallet"> -->
							<wallet-select
								[account]="account$ | async"
								[accountLoadingState]="accountLoadingState$ | async"
								[selectedWallet]="selectedWallet$ | async"
								[selectedWalletLoadingState]="selectedWalletLoadingState$ | async"
							></wallet-select>
						<!-- </ng-container> -->

						<!-- <ng-template #isOnlyOneWallet>
							<wallet-name
								[account]="account$ | async"
								[accountLoadingState]="accountLoadingState$ | async"
								[selectedWallet]="selectedWallet$ | async"
								[selectedWalletLoadingState]="selectedWalletLoadingState$ | async"
							></wallet-name>
						</ng-template> -->
					</ng-container>

					<ng-template #isDataNotLoaded>
						<div class="isDataNotLoaded">
							<!-- TODO: this - [disabled]="isSpinnerVisible$ | async" - is doing nothing, because I don't setSpinner in component -->
							<button
								type="button"
								mat-button
								[routerLink]="'/dashboard'"
								[disabled]="isSpinnerVisible$ | async"
								class="button-low button-back border-gray"
							>
								<mat-icon aria-hidden="true" aria-label="Back" class="material-icons md-gray-darkest md-18">
									arrow_back
								</mat-icon>

								<div>Back</div>
							</button>

							<div>Please go back to main page to get wallet data</div>
						</div>
					</ng-template>
				</ng-container>

				<ng-template #isSpinnerVisible>
					<mat-progress-spinner [diameter]="24" [mode]="'indeterminate'" class="button-spinner"></mat-progress-spinner>
				</ng-template>
			</div>
		</div>

		<!-- Balance inside Header for XS, SM and MD -->
		<div
			*ngIf="(isBreakpointXS$ | async) || (isBreakpointSM$ | async) || (isBreakpointMD$ | async)"
			class="header__totals-xs-sm-md"
		>
			<balance
				[selectedWallet]="selectedWallet$ | async"
				[selectedWalletLoadingState]="selectedWalletLoadingState$ | async"
			></balance>
		</div>
	</div>
</ng-container>

<ng-template #isNotSignedIn>
	<div [routerLink]="'/sign-in'" class="header-no-auth">
		<div class="header-no-auth__heading">
			<h3>TagionPortal</h3>
		</div>

		<!-- <div *ngIf="!(isBreakpointXS$ | async) && !(isBreakpointSM$ | async)" class="no-auth-app-version-wrapper">
			<div class="app-version">{{ currentApplicationVersion }}</div>
		</div> -->
	</div>
</ng-template>
