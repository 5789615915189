import { NgModule } from '@angular/core';
import { BuyTagionsFormComponent } from './buy-tagions-form.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';

@NgModule({
	declarations: [BuyTagionsFormComponent],
	imports: [SharedModule, MaterialModule],
	exports: [BuyTagionsFormComponent],
})
export class BuyTagionsFormModule {}
