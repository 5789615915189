import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { AuthService } from '../../../core/auth/auth.service';
import { ResponseModel } from '../../../models/other.models';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { BreakpointService } from '../../../core/services/breakpoint.service';

@Component({
	selector: 'check-inbox',
	templateUrl: './check-inbox.component.html',
	styleUrls: ['./check-inbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckInboxComponent implements OnInit, OnDestroy {
	public email: string | null = null;
	public isSpinnerVisible$: Observable<boolean> | null = null;

  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;

	private _spinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		private _authService: AuthService,
		private _snackBarService: SnackBarService,
		private _changeDetectorRef: ChangeDetectorRef,
		private _breakpointService: BreakpointService,
	) {}

	ngOnInit(): void {
		this.isSpinnerVisible$ = this.getSpinnerStatus$();

		this.email = localStorage.getItem('saftEmailForSignIn');

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public setSpinnerStatus(spinnerStatus: boolean): void {
		this._spinner$.next(spinnerStatus);
	}
	public getSpinnerStatus$(): Observable<boolean> {
		return this._spinner$.asObservable();
	}

	public sendLinkAgain(): void {
		this.setSpinnerStatus(true);

		if (this.email) {
			this._authService
				.sendSignInLink(this.email)
				.pipe(
					filter((response: ResponseModel) => response.status === 1),
					tap(() => this.setSpinnerStatus(false)),
					tap(() => this._snackBarService.openSuccessSnackBar('Link has been successfully resent')),
					tap(() => this._changeDetectorRef.markForCheck()),
					catchError((err: any) => {
						this.setSpinnerStatus(false);

						return of(err);
					}),
				)
				.subscribe();
		}
	}

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
  }
}
