import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StepperOrientation } from '@angular/material/stepper';
import { BehaviorSubject, catchError, combineLatest, filter, map, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { CountryCodesService } from '../../../core/services/country-codes.service';
import { CustomValidators } from '../../../validators/validators';
import { ReferralService } from '../../../core/services/referral.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseModel } from '../../../models/other.models';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { AccountService } from '../../../core/services/account.service';
// import { EmailCheckComponent } from '../../dumb/email-check/email-check.component';

@Component({
	selector: 'anon-referral',
	templateUrl: './anon-referral.component.html',
	styleUrls: ['./anon-referral.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnonReferralComponent implements OnInit {
	// @ViewChild(EmailCheckComponent, { static: false })
	// public emailCheckComponent: EmailCheckComponent | null = null;

	public accountId = '';
	public anonReferralAmountForm!: FormGroup;
	public anonReferralCredsForm!: FormGroup;
	public stepperOrientation: Observable<StepperOrientation> | null = null;
	public tagionPrice: number | null = null;
	public totalTagions = 0;
	public isFirstStepCompleted = false;

	public isSpinnerVisible$: Observable<boolean> | null = null;
	public tagionPrice$: Observable<number | null> = of(null);

  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;
  public isBreakpointHeightSmall$: Observable<boolean> | null = null;

	private _spinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		public countryCodesService: CountryCodesService,
		private _formBuilder: FormBuilder,
		private _breakpointService: BreakpointService,
		private _referralService: ReferralService,
		private _accountService: AccountService,
		private _activatedRoute: ActivatedRoute,
		private _snackBarService: SnackBarService,
		private _router: Router,
	) {}

	ngOnInit(): void {
		this._checkIfAccountExist();

		this.isSpinnerVisible$ = this.getSpinnerStatus$();

		this._initAnonReferralAmountForm();
		this._initAnonReferralCredsForm();

		this._setCompletionOfFirstStep();

		// need to pass reactive variable to template, otherwise value may be lost
		this.tagionPrice$ = this.getTagionPrice$();
		this.getTagionPrice$().subscribe();

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();

		this._setStepperOrientation();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public setSpinnerStatus(spinnerStatus: boolean): void {
		this._spinner$.next(spinnerStatus);
	}
	public getSpinnerStatus$(): Observable<boolean> {
		return this._spinner$.asObservable();
	}

	public getTagionPrice$(): Observable<number | null> {
		return this._accountService.getTagionPrice().pipe(
			tap((tagionPrice: number | null) => (this.tagionPrice = tagionPrice)),
			takeUntil(this._onDestroy$),
		);
	}

	public onSearchChange(event: Event): void {
		const value = (event.target as HTMLInputElement).value;

		if (this.tagionPrice) {
			this.totalTagions = Number(value) / this.tagionPrice;
		}
	}

	public submitRequest() {
		this.setSpinnerStatus(true);

		this._referralService
			.submitAnonReferral(this.accountId, {
				referalName: (this.anonReferralCredsForm as FormGroup).controls['firstName'].value,
				referalLastName: (this.anonReferralCredsForm as FormGroup).controls['lastName'].value,
				referalEmail: (this.anonReferralCredsForm as FormGroup).controls['email'].value,
				referalInvitationLanguage: (this.anonReferralCredsForm as FormGroup).controls['country'].value.code,
				anonymousReferral: true,
				euroAmount: Number((this.anonReferralAmountForm as FormGroup).controls['amountInEuro'].value),
				phoneNumber: null,
			})
			.pipe(
				filter((response: ResponseModel) => response.status === 1),
				tap(() => this.setSpinnerStatus(false)),
				tap((response: ResponseModel) => {
					if (response.status === 1) {
						this._snackBarService.openSuccessSnackBar('Request has been submitted');
					}
				}),
				tap(() => this._router.navigate([`/`])),
				catchError((err: any) => {
					this.setSpinnerStatus(false);

					return of(err);
				}),
			)
			.subscribe();
	}

	private _checkIfAccountExist(): void {
		this.accountId = this._activatedRoute.snapshot.params['id'];

		if (this.accountId) {
			this._accountService.checkIfAccountIdExistInCrm(this.accountId).pipe(takeUntil(this._onDestroy$)).subscribe();
		}
	}

	private _initAnonReferralAmountForm(): void {
		this.anonReferralAmountForm = this._formBuilder.group({
			amountInEuro: [null, [Validators.required, CustomValidators.isNumberValidator.bind(this), Validators.min(1000)]],
		});
	}

	private _initAnonReferralCredsForm(): void {
		this.anonReferralCredsForm = this._formBuilder.group({
			email: ['', [Validators.required, Validators.maxLength(75), Validators.email]],
			country: ['', Validators.required],
			firstName: ['', [Validators.required, Validators.maxLength(50)]],
			lastName: ['', [Validators.required, Validators.maxLength(50)]],
		});
	}

	private _setStepperOrientation(): void {
		this.stepperOrientation = combineLatest([(this.isBreakpointXS$ as Observable<boolean>), (this.isBreakpointSM$ as Observable<boolean>)]).pipe(
			map((arr: boolean[]) => {
				let orientation: 'horizontal' | 'vertical';

				if (arr.every((el: boolean) => el === false)) {
					orientation = 'horizontal';
				} else {
					orientation = 'vertical';
				}

				return orientation;
			}),
		);
	}

	private _setCompletionOfFirstStep(): void {
		this.anonReferralAmountForm
			?.get('amountInEuro')
			?.valueChanges.pipe(
				tap(() => {
					(this.anonReferralAmountForm as FormGroup).controls['amountInEuro'].valid
						? (this.isFirstStepCompleted = true)
						: (this.isFirstStepCompleted = false);
				}),
				takeUntil(this._onDestroy$),
			)
			.subscribe();
	}

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
  }
}
