import { NgModule } from '@angular/core';
import { WalletSelectComponent } from './wallet-select.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';

@NgModule({
	declarations: [WalletSelectComponent],
	imports: [SharedModule, MaterialModule],
	exports: [WalletSelectComponent],
})
export class WalletSelectModule {}
