import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { takeUntil } from 'rxjs/operators';
import { DataService } from '../../../core/services/data.service';

@Component({
	selector: 'contact-request-submitted',
	templateUrl: './contact-request-submitted.component.html',
	styleUrls: ['./contact-request-submitted.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactRequestSubmittedComponent implements OnInit, OnDestroy {
  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;
  public isBreakpointHeightSmall$: Observable<boolean> | null = null;

	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		public dataService: DataService,
		private _dialogRef: MatDialogRef<ContactRequestSubmittedComponent>,
		private _breakpointService: BreakpointService,
	) {}

	ngOnInit(): void {
		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public closeModal(): void {
		this._dialogRef.close();
	}

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
    this.isBreakpointHeightSmall$ = this._breakpointService.getHeightSmallBreakpointStatus$();
  }
}
