import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { AccountService } from '../../../core/services/account.service';
import { catchError, map } from 'rxjs/operators';
import { filterOutNullishValues } from '../../../core/auth/auth.utils';
import { MatDialogRef } from '@angular/material/dialog';
import { EmailCheckComponent } from '../email-check/email-check.component';
import { CustomValidators } from '../../../validators/validators';
import { Select } from '@ngxs/store';
import { AccountState } from '../../../core/store/states/account.states';
import { AccountInfoData } from '../../../models/account.models';

@Component({
	selector: 'master-account-form',
	templateUrl: './master-account-form.component.html',
	styleUrls: ['./master-account-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterAccountFormComponent implements OnInit {
	// @Select(AccountState.getAccount) public account$: Observable<AccountInfoData> | null = null;

	// @ViewChild(EmailCheckComponent, { static: false })
	// public emailCheckComponent: EmailCheckComponent | null = null;

	public masterAccountForm!: FormGroup;
	public isSpinnerVisible$: Observable<boolean> | null = null;

	// public currentUserEmail: string | null = null;

  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;
  public isBreakpointHeightSmall$: Observable<boolean> | null = null;

	private _spinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		private _breakpointService: BreakpointService,
		private _formBuilder: FormBuilder,
		private _accountService: AccountService,
		private _dialogRef: MatDialogRef<MasterAccountFormComponent>,
	) {}

	ngOnInit(): void {
		this.isSpinnerVisible$ = this.getSpinnerStatus$();

		// this.getCurrentUserEmail();

		this._initMasterAccountForm();

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	// public getCurrentUserEmail(): void {
	// 	this.account$
	// 		.pipe(
	// 			map((account: AccountInfoData) => account.email),
	// 			tap((currentUserEmail: string) => (this.currentUserEmail = currentUserEmail)),
	// 			takeUntil(this._onDestroy$),
	// 		)
	// 		.subscribe();
	// }

	public setSpinnerStatus(spinnerStatus: boolean): void {
		this._spinner$.next(spinnerStatus);
	}
	public getSpinnerStatus$(): Observable<boolean> {
		return this._spinner$.asObservable();
	}

	public loginAsAnotherAccount() {
		this.setSpinnerStatus(true);

		const email = (this.masterAccountForm as FormGroup).controls['email'].value;

		this._accountService
			.getMasterToken(email)
			.pipe(
				filterOutNullishValues(),
				tap(() => this.setSpinnerStatus(false)),
				tap((token: string) => {
					localStorage.setItem('saftAuthToken-masterAccess', token);
					window.location.reload();
				}),
				tap(() => this._dialogRef.close()),
				// takeUntil(this._onDestroy$),
				catchError((err: any) => {
					this.setSpinnerStatus(false);

					return of(err);
				}),
			)
			.subscribe();
	}

	private _initMasterAccountForm(): void {
		this.masterAccountForm = this._formBuilder.group({
			email: [
				null,
				[
					Validators.required,
					Validators.maxLength(75),
					Validators.email,
					// CustomValidators.sameEmailValidator.bind(this, this.currentUserEmail),
				],
			],
		});
	}

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
    this.isBreakpointHeightSmall$ = this._breakpointService.getHeightSmallBreakpointStatus$();
  }
}
