import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ResponseModel } from '../../models/other.models';
import { environment } from '../../../environments/environment';
import { SnackBarService } from './snack-bar.service';

@Injectable({
	providedIn: 'root',
})
export class NetworkService {
	constructor(private _httpClient: HttpClient, private _snackBarService: SnackBarService) {}

	public get(path: string): Observable<any> {
		return this._httpClient.get(`${environment.apiUri}${path}`).pipe(
			map((serverResponse: ResponseModel | any) => {
				if (serverResponse.status === 5) {
					throw Error('InternalError');
				}
				if (serverResponse.status === 6) {
					throw Error('ValidationFailed');
				}
				if (serverResponse.status === 7) {
					throw Error('SaveToDbError');
				}
				if (serverResponse.status !== 1) {
					console.error('HTTP GET Response: ', serverResponse);

					throw Error(serverResponse.message);
				} else {
					return serverResponse;
				}
			}),
			catchError((error: Error) => {
				console.error('ERROR: ', error);
				// this._disableSpinner();

				if (error instanceof HttpErrorResponse) {
					// if /admintoken returns no token
					if (error.error === 'Not allowed') {
						return of(null);
					} else {
						this._snackBarService.openErrorSnackBar(`Http Error Response: ${error.error.message}`);
					}
				}

				// handling api Response Status Errors
				else if (error.message === 'InternalError') {
					this._snackBarService.openErrorSnackBar('Internal Error');
				} else if (error.message === 'ValidationFailed') {
					this._snackBarService.openErrorSnackBar('Validation Failed');
				} else if (error.message === 'SaveToDbError') {
					this._snackBarService.openErrorSnackBar('Could not save to database');
				} else {
					this._snackBarService.openErrorSnackBar('Oops! Something went wrong. Could not GET');
				}

				return of(null);
			}),
		);
	}

	public post(path: string, body: any, otherData?: any): Observable<any> {
		return this._httpClient.post(`${environment.apiUri}${path}`, body, otherData).pipe(
			map((serverResponse: ResponseModel | any) => {
				if (serverResponse.status === 5) {
					throw Error('InternalError');
				}
				if (serverResponse.status === 6) {
					throw Error('ValidationFailed');
				}
				if (serverResponse.status === 7) {
					throw Error('SaveToDbError');
				}
				if (serverResponse.status === 19) {
					throw Error('DailyLimitExceeded');
				}
				if (serverResponse.status === 18) {
					throw Error('JWTTokenNotValid');
				}
				if (serverResponse.status !== 1) {
					console.error('HTTP POST Response: ', serverResponse);

					throw Error(serverResponse.message);
				} else {
					return serverResponse;
				}
			}),
			catchError((error: Error) => {
				console.error('ERROR: ', error);
				// this._disableSpinner();

				// TODO: properly handle errors for GET and POST
				if (error instanceof HttpErrorResponse) {
					if (error.error.message === 'User not exists in CRM') {
						this._snackBarService.openErrorSnackBar('This email is not registered. Please contact ir@tagion.com');
					} else {
						this._snackBarService.openErrorSnackBar(`Http Error Response: ${error.error.message}`);
					}
				}

				// handling api Response Status Errors
				else if (error.message === 'InternalError') {
					this._snackBarService.openErrorSnackBar('Internal Error');
				} else if (error.message === 'ValidationFailed') {
					this._snackBarService.openErrorSnackBar('Validation Failed');
				} else if (error.message === 'SaveToDbError') {
					// TODO: this is a duck tape solution
					this._snackBarService.openErrorSnackBar('First Name or/and Last Name is too long');
					// this._snackBarService.openErrorSnackBar('Could not save to database');
					this._snackBarService.openErrorSnackBar('Could not save to database');
				} else if (error.message === 'DailyLimitExceeded') {
					this._snackBarService.openErrorSnackBar('You can send requests only once a day');
				} else if (error.message === 'JWTTokenNotValid') {
					this._snackBarService.openErrorSnackBar('JWT Token not valid');
				} else {
					this._snackBarService.openErrorSnackBar('Oops! Something went wrong. Could not POST');
				}

				return of(null);
			}),
		);
	}

	public postContactSupport(path: string, body: any, otherData?: any): Observable<any> {
		return this._httpClient.post(`${environment.apiUriContactSupport}${path}`, body, otherData).pipe(
			map((serverResponse: ResponseModel | any) => {
				if (typeof serverResponse !== 'string') {
					console.error('HTTP POST Contact Support Response: ', serverResponse);

					throw Error();
				} else {
					return serverResponse;
				}
			}),

			catchError((error: Error) => {
				console.error('ERROR: ', error);

				this._snackBarService.openErrorSnackBar(
					`Something went wrong. Please contact us directly to ${environment.supportEmail}`,
				);

				return of(null);
			}),
		);
	}
}
