import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, Observable, tap } from 'rxjs';
import { Wallet } from '../../../core/store/actions/wallet.actions';
import { AccountInfoData } from '../../../models/account.models';
import { WalletStateData, WalletDataResponse } from '../../../models/wallet.models';

@Component({
	selector: 'wallet-select',
	templateUrl: './wallet-select.component.html',
	styleUrls: ['./wallet-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WalletSelectComponent implements OnInit {
	@Input() account: AccountInfoData | null = null;
	@Input() accountLoadingState: boolean | null = null;
	@Input() selectedWallet: WalletStateData | null = null;
	@Input() selectedWalletLoadingState: boolean | null = null;

	constructor(private _store: Store, private _changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit(): void {}

	public changeActiveWallet(walletId: string): Observable<WalletDataResponse> {
		return this._store.dispatch(new Wallet.GetOne(walletId)).pipe(
			filter((response: WalletDataResponse) => response.status === 1),
			tap(() => this._changeDetectorRef.markForCheck()),
		);
	}
}
