import { NgModule } from '@angular/core';
import { MasterAccountFormComponent } from './master-account-form.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';
import { RouterModule } from '@angular/router';
import { EmailCheckModule } from '../email-check/email-check.module';
import { DirectivesModule } from '../../../directives/directives.module';

const componentModules = [EmailCheckModule];

@NgModule({
	declarations: [MasterAccountFormComponent],
	imports: [SharedModule, MaterialModule, RouterModule, DirectivesModule, ...componentModules],
	exports: [MasterAccountFormComponent],
})
export class MasterAccountFormModule {}
