import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'tagion',
})
export class TagionPipe implements PipeTransform {
	transform(amountCurrency: number | string): string {
		if (typeof amountCurrency === 'string') {
			amountCurrency = Number(amountCurrency);
		}
    
		const amount = formatNumber(amountCurrency, 'en', '1.0-0'); // {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}

		return `${amount} TGN`;
	}
}
