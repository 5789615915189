import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	CanLoad,
	Route,
	Router,
	RouterStateSnapshot,
	UrlSegment,
	UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
	providedIn: 'root',
})
export class NoAuthGuard implements CanActivate, CanActivateChild, CanLoad {
	constructor(private _authService: AuthService, private _router: Router) {}

	private _checkIfUserIsNotAuthenticated(): Observable<boolean> {
		// Check the authentication status
		return this._authService.isStatusSignedIn().pipe(
			switchMap((isAuthenticated: boolean) => {
				// If the user is authenticated...
				if (isAuthenticated) {
					// Redirect to the root
					this._router.navigate(['dashboard']);

					// Prevent the access
					return of(false);
				}

				// Allow the access
				return of(true);
			}),
		);
	}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean> | Promise<boolean> | boolean {
		return this._checkIfUserIsNotAuthenticated();
	}

	public canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this._checkIfUserIsNotAuthenticated();
	}

	public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
		return this._checkIfUserIsNotAuthenticated();
	}
}
