import { NgModule } from '@angular/core';
import { PayoutRequestFormComponent } from './payout-request-form.component';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../../pipes/pipes.module';
import { QrCodeModule } from 'ng-qrcode';

@NgModule({
	declarations: [PayoutRequestFormComponent],
	imports: [SharedModule, MaterialModule, RouterModule, PipesModule, QrCodeModule],
	exports: [PayoutRequestFormComponent],
})
export class PayoutRequestFormModule {}
