<ng-container *ngIf="!(accountLoadingState$ | async) && !(selectedWalletLoadingState$ | async); else isSpinnerVisible">
	<ng-container *ngIf="account$ | async as account">
		<ng-container *ngIf="selectedWallet$ | async as selectedWallet">
			<div class="referral">
				<div class="referral__heading">
					<div class="text">
						<h3>My Referrals</h3>
					</div>

					<div class="actions">
						<!-- <ng-container
							*ngIf="
								account.mainWallet === selectedWallet.walletSummary.id &&
								!(isBreakpointXS$ | async) &&
								!(isBreakpointSM$ | async)
							"
						>
							<button
								type="button"
								mat-button
								(click)="openReferralForm()"
								class="actions__invite button-main"
								[ngClass]="{
									'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
									'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async),
									'button-padding-extra-tiny': (isBreakpointXS$ | async),
									'button-padding-tiny': (isBreakpointSM$ | async)
								}"
							>
								<div>Invite a friend</div>
							</button>
						</ng-container> -->

						<button
							type="button"
							mat-button
							(click)="copyAnonReferralLinkToClipboard()"
							class="actions__generate button-secondary"
							[ngClass]="{
								'button-low': (isBreakpointXS$ | async) || (isBreakpointSM$ | async),
								'button-high': (isBreakpointMD$ | async) || (isBreakpointLG$ | async) || (isBreakpointXL$ | async),
								'button-padding-extra-tiny': (isBreakpointXS$ | async),
								'button-padding-tiny': (isBreakpointSM$ | async)
							}"
						>
							<div>Generate referral link</div>
						</button>
					</div>
				</div>

				<div class="referral__transactions">
					<ng-container *ngIf="selectedWallet?.userReferrals?.length; else noReferrals">
						<table
							mat-table
							[dataSource]="dataSource.data"
							matSort
							(matSortChange)="sortData($event)"
							matSortActive="date"
							matSortDirection="desc"
							matSortDisableClear
							class="transactions-table"
						>
							<ng-container matColumnDef="date">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>

								<ng-container *matCellDef="let userReferral">
									<td mat-cell>
										{{ dataService.mockStringCheck(userReferral.timeInvestmentMade | date: 'longDate') }}
									</td>
								</ng-container>

								<td mat-footer-cell *matFooterCellDef>Total</td>
							</ng-container>

							<ng-container matColumnDef="id">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>

								<ng-container *matCellDef="let userReferral">
									<td mat-cell>
										{{ dataService.mockStringCheck(userReferral.id | shortId) }}
									</td>
								</ng-container>

								<td mat-footer-cell *matFooterCellDef></td>
							</ng-container>

							<ng-container matColumnDef="contractAmount">
								<th
									mat-header-cell
									*matHeaderCellDef
									mat-sort-header
									[hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)"
								>
									Contract Amount
								</th>

								<ng-container *matCellDef="let userReferral">
									<td mat-cell [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
										{{ dataService.mockNumberCheck(userReferral.amountInOriginalCurrency) | euro2Decimals }}
									</td>
								</ng-container>

								<td mat-footer-cell *matFooterCellDef [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
									{{ getTotalContractAmount(selectedWallet?.userReferrals) | euro2Decimals }}
								</td>
							</ng-container>

							<ng-container matColumnDef="tagionPrice">
								<th
									mat-header-cell
									*matHeaderCellDef
									mat-sort-header
									[hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)"
								>
									Tagion Price
								</th>

								<ng-container *matCellDef="let userReferral">
									<td mat-cell [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
										{{ dataService.mockNumberCheck(userReferral.contractualTagionPrice) | euro4Decimals }}
									</td>
								</ng-container>

								<td
									mat-footer-cell
									*matFooterCellDef
									[hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)"
								></td>
							</ng-container>

							<ng-container matColumnDef="tagions">
								<th
									mat-header-cell
									*matHeaderCellDef
									mat-sort-header
									[hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)"
								>
									Tagion Amount
								</th>

								<ng-container *matCellDef="let userReferral">
									<td mat-cell [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
										{{ dataService.mockNumberCheck(userReferral.amountInTagions) | tagion }}
									</td>
								</ng-container>

								<td mat-footer-cell *matFooterCellDef [hidden]="(isBreakpointXS$ | async) || (isBreakpointSM$ | async)">
									{{ getTotalTagionsAmount(selectedWallet?.userReferrals) | tagion }}
								</td>
							</ng-container>

							<ng-container matColumnDef="referralFee">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>Fee</th>

								<ng-container *matCellDef="let userReferral">
									<td mat-cell>
										{{ dataService.mockNumberCheck(userReferral.feeReceived) | tagion }}
									</td>
								</ng-container>

								<td mat-footer-cell *matFooterCellDef>
									{{ getTotalReferralFeeAmount(selectedWallet?.userReferrals) | tagion }}
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>

							<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>

							<tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
						</table>
					</ng-container>

					<ng-template #noReferrals>
						<div class="referrals__noTransactions">
							<div class="card-icon">
								<img src="../../../../assets/icons/regular/referral.svg" alt="referral" class="regular-icon-image" />
							</div>

							<div class="text">
								<div>You have no referral income yet</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</ng-container>
	</ng-container>
</ng-container>

<ng-template #isSpinnerVisible>
	<div class="isSpinnerVisibleAtMenuItem">
		<mat-progress-spinner [diameter]="36" [mode]="'indeterminate'"></mat-progress-spinner>
	</div>
</ng-template>
