import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BuyTagionsService } from '../../../core/services/buy-tagions.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';
import { Store } from '@ngxs/store';
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { WalletState } from '../../../core/store/states/wallet.states';
import { WalletStateData } from '../../../models/wallet.models';
import { ResponseModel } from '../../../models/other.models';
import { CustomValidators } from '../../../validators/validators';

@Component({
	selector: 'buy-tagions-form',
	templateUrl: './buy-tagions-form.component.html',
	styleUrls: ['./buy-tagions-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyTagionsFormComponent implements OnInit {
	public selectedWallet$: Observable<WalletStateData> | null = null;

	public buyTagionsForm!: FormGroup;
	public isSpinnerVisible$: Observable<boolean> = of(false);

  public isBreakpointXS$: Observable<boolean> | null = null;
	public isBreakpointSM$: Observable<boolean> | null = null;
	public isBreakpointMD$: Observable<boolean> | null = null;
	public isBreakpointLG$: Observable<boolean> | null = null;
	public isBreakpointXL$: Observable<boolean> | null = null;
  public isBreakpointHeightSmall$: Observable<boolean> | null = null;

	private _spinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private _onDestroy$: Subject<void> = new Subject<void>();

	constructor(
		private _formBuilder: FormBuilder,
		private _dialogRef: MatDialogRef<BuyTagionsFormComponent>,
		private _buyTagionsService: BuyTagionsService,
		private _snackBarService: SnackBarService,
		private _store: Store,
		private _breakpointService: BreakpointService,
	) {}

	ngOnInit(): void {
    this._initializeDataFromStore();

		this.isSpinnerVisible$ = this.getSpinnerStatus$();
		this._initBuyTagionsForm();

		this._initializeBreakpointStatuses();
		this._breakpointService.listenToBreakpointObserver().pipe(takeUntil(this._onDestroy$)).subscribe();
	}

	ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	public setSpinnerStatus(spinnerStatus: boolean): void {
		this._spinner$.next(spinnerStatus);
	}
	public getSpinnerStatus$(): Observable<boolean> {
		return this._spinner$.asObservable();
	}

	public sendPurchaseRequest(): void {
		this.setSpinnerStatus(true);

		(this.selectedWallet$ as Observable<WalletStateData>)
			.pipe(
				map((walletData: WalletStateData) => walletData.walletSummary.id),
				switchMap((walletId: string) =>
					this._buyTagionsService.sendBuyTagionRequest(walletId, {
						// to parse when user enters number+dot: 12345.
						euroAmount: parseInt((this.buyTagionsForm as FormGroup).value.euroAmount),
					}),
				),
				filter((response: ResponseModel) => response.status === 1),
				tap((response: ResponseModel) => {
					if (response.status === 1) {
						this._snackBarService.openSuccessSnackBar('Purchase request has been successfully sent');
					}
				}),
				tap(() => this.setSpinnerStatus(false)),
				// will need this if we need to display list of buy requests
				// tap(() => this._store.dispatch(new Wallet.GetOne(this.referralBody.myWalletId as string))),
				tap(() => this._dialogRef.close()),
				takeUntil(this._onDestroy$),
				catchError((err: any) => {
					this.setSpinnerStatus(false);

					return of(err);
				}),
			)
			.subscribe();
	}

	private _initBuyTagionsForm(): void {
		this.buyTagionsForm = this._formBuilder.group({
			euroAmount: [null, [Validators.required, CustomValidators.isNumberValidator.bind(this), Validators.min(1000)]],
		});
	}

  private _initializeDataFromStore(): void {
    this.selectedWallet$ = this._store.select(WalletState.getSelectedWallet);
  }

  private _initializeBreakpointStatuses(): void {
    this.isBreakpointXS$ = this._breakpointService.getXSBreakpointStatus$();
		this.isBreakpointSM$ = this._breakpointService.getSMBreakpointStatus$();
		this.isBreakpointMD$ = this._breakpointService.getMDBreakpointStatus$();
		this.isBreakpointLG$ = this._breakpointService.getLGBreakpointStatus$();
		this.isBreakpointXL$ = this._breakpointService.getXLBreakpointStatus$();
    this.isBreakpointHeightSmall$ = this._breakpointService.getHeightSmallBreakpointStatus$();
  }
}
